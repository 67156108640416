import { useImperativeHandle, useRef } from "react";

import { createFocusableRef } from "../utils/refs";

import type { FocusableRef } from "@react-types/shared";
import type { RefObject } from "react";

export const useFocusableRef = <T extends HTMLElement = HTMLElement>(
  ref: FocusableRef<T>,
  focusableRef?: RefObject<HTMLElement>
): RefObject<T> => {
  const domRef = useRef<T>(null);

  useImperativeHandle(ref, () => createFocusableRef(domRef, focusableRef));

  return domRef;
};
