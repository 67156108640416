import { forwardRef, useEffect } from "react";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";
import { useModalContext } from "./context";

import type { ComponentPropsWithoutRef } from "react";

export const ModalBody = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div">>(
  ({ children, className, ...props }, ref) => {
    const { slots, classNames, bodyId, setBodyMounted } = useModalContext();

    const domRef = useDomRef(ref);

    useEffect(() => {
      setBodyMounted(true);

      return () => setBodyMounted(false);
    }, [setBodyMounted]);

    return (
      <div
        ref={domRef}
        className={slots.body({ className: cn(classNames?.body, className) })}
        id={bodyId}
        {...props}
      >
        {children}
      </div>
    );
  }
);
ModalBody.displayName = "Modal.Body";
