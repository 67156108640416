export const TAU = Math.PI * 2;
export const PI_OVER_2 = Math.PI / 2;
export const PI_OVER_4 = Math.PI / 4;
export const TWO_PI = 2 * Math.PI;

export const RADIANS_PER_DEGREE = Math.PI / 180;
export const DEGREES_PER_RADIAN = 1 / RADIANS_PER_DEGREE;
export const MILLISECONDS_PER_SECOND = 1000;
export const MICROSECONDS_PER_MILLISECOND = 1000;
export const MICROSECONDS_PER_SECOND = MICROSECONDS_PER_MILLISECOND * MILLISECONDS_PER_SECOND;
export const FEET_PER_METER = 3.2808;
export const MILLIMETERS_PER_INCH = 25.4;
export const METERS_PER_NAUTICAL_MILE = 1852;

export const MPH_TO_MPS = 2.23694;
export const KNOTS_TO_MPS = 0.51444;
export const FPM_TO_MPS = 0.00508;

export const ZERO_CELSIUS_IN_KELVIN = 273.15;
export const INHG_PER_HPA = 0.02953;

/**
 * Approximate radius of the earth in meters.
 * Uses the WGS-84 approximation. The radius at the equator is ~6378137 and at the poles is ~6356752. https://en.wikipedia.org/wiki/World_Geodetic_System#WGS84
 * 6371008.8 is one published "average radius" see https://en.wikipedia.org/wiki/Earth_radius#Mean_radius, or ftp://athena.fsv.cvut.cz/ZFG/grs80-Moritz.pdf p.4
 *
 * @remarks
 * Ported directly from MapboxGL's codebase.
 *
 * @see
 * https://github.com/mapbox/mapbox-gl-js/blob/af9c3d46afb3eb88af319cab77908c1ccd55899f/src/geo/lng_lat.js#L11
 *
 * @private
 * TODO: Import this into Typescript directly from MapboxGL using a `.d.ts` file.
 */
export const EARTH_RADIUS = 6371008.8;
