import { createContext as createReactContext, useContext as useReactContext } from "react";

import type { Context, Provider } from "react";

interface CreateContextOptionsBase {
  name?: string;
}

interface CreateContextOptionsStrict extends CreateContextOptionsBase {
  strict?: true;
}

interface CreateContextOptionsNonStrict extends CreateContextOptionsBase {
  strict: false;
}

type CreateContextOptions = CreateContextOptionsStrict | CreateContextOptionsNonStrict;

type CreateContextReturnStrict<T> = [Provider<T>, () => T, Context<T>];
type CreateContextReturnNonStrict<T> = [Provider<T>, () => T | undefined, Context<T>];

/**
 * Create a named context with a provider and hook.
 * If `options.strict` is true (default), the hook will throw an error if used outside of a provider.
 * If `options.strict` is false, the hook will return `undefined` outside of a provider.
 */
export function createContext<T>(options: CreateContextOptionsStrict): CreateContextReturnStrict<T>;
export function createContext<T>(
  options: CreateContextOptionsNonStrict
): CreateContextReturnNonStrict<T>;
export function createContext<T>({ strict = true, name }: CreateContextOptions = {}) {
  const Context = createReactContext<T | undefined>(undefined);
  Context.displayName = name;

  function useContext() {
    const context = useReactContext(Context);

    if (!context && strict) {
      const error = new Error("useContext must be used within a Provider.");
      error.name = "ContextError";
      Error.captureStackTrace(error, useContext);
      throw error;
    }

    return context;
  }
  return [Context.Provider, useContext, Context] as const;
}
