import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import React from "react";
import { tv } from "tailwind-variants";

import type { VariantProps } from "tailwind-variants";

const switchVariants = tv({
  slots: {
    base: [
      "peer",
      "inline-flex",
      "h-4",
      "w-7",
      "p-0",
      "shrink-0",
      "cursor-pointer",
      "items-center",
      "rounded-full",
      "border-2",
      "border-transparent",
      "transition-colors",
      "focus-visible:outline-none",
      "focus-visible:ring-2",
      "focus-visible:ring-ring",
      "focus-visible:ring-offset-2",
      "focus-visible:ring-offset-white",
      "disabled:cursor-not-allowed",
      "disabled:opacity-50",
      "data-[state=checked]:bg-blue",
      "data-[state=unchecked]:bg-gray-300",
    ],
    thumb: [
      "pointer-events-none",
      "block",
      "rounded-full",
      "bg-white",
      "shadow-lg",
      "ring-0",
      "transition-transform",
      "flex",
      "items-center",
      "justify-center",
      "text-[0.625rem]",
      "text-gray-300",
      "data-[state=checked]:text-blue",
      "data-[state=unchecked]:translate-x-0",
    ],
  },
  variants: {
    size: {
      default: {
        base: "h-4 w-7",
        thumb: "size-3 data-[state=checked]:translate-x-3",
      },
      large: {
        base: "h-6 w-11",
        thumb: "size-5 data-[state=checked]:translate-x-5",
      },
    },
  },
  defaultVariants: {
    size: "default",
  },
});

export interface SwitchProps
  extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>,
    VariantProps<typeof switchVariants> {
  loading?: boolean;
}

// TODO(sam): automatically put in a label as child to match the API of other input elements
export const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(
  ({ className, size, loading, ...props }, ref) => {
    const { base, thumb } = switchVariants({ size });

    return (
      <SwitchPrimitives.Root
        className={base({ className })}
        {...props}
        ref={ref}
        disabled={props.disabled || loading}
      >
        <SwitchPrimitives.Thumb className={thumb()}>
          {loading && <Icon icon={faSpinnerThird} spin />}
        </SwitchPrimitives.Thumb>
      </SwitchPrimitives.Root>
    );
  }
);
Switch.displayName = SwitchPrimitives.Root.displayName;
