import { forwardRef } from "react";

import { Overflow } from "../overflow/Overflow";
import { OverflowItem } from "../overflow/OverflowItem";
import { Tooltip } from "../tooltip/Tooltip";
import { Tag } from "./Tag";

import type { CollectionBase } from "@react-types/shared";
import type { ForwardedRef, ReactElement } from "react";
import type { MultiSelectListState } from "../../hooks/useMultiSelectListState";
import type { OverflowProps } from "../overflow/Overflow";

export interface MultiSelectTagsProps<T>
  extends Omit<OverflowProps<T>, keyof CollectionBase<T> | "renderRest"> {
  state: MultiSelectListState<T>;
}

const MultiSelectTagsImpl = <T extends object>(
  { state, ...props }: MultiSelectTagsProps<T>,
  ref: ForwardedRef<HTMLDivElement>
) => {
  return (
    <Overflow
      renderRest={omittedItems => (
        <Tooltip
          color="default"
          content={
            <div className="flex flex-col gap-1">
              {omittedItems.map(item => (
                <span key={item.index}>{item.textValue || item.rendered}</span>
              ))}
            </div>
          }
        >
          <Tag size="sm">+{omittedItems.length}</Tag>
        </Tooltip>
      )}
      {...props}
      ref={ref}
    >
      {(state.selectedItems ?? []).map(item => (
        <OverflowItem key={item.key} textValue={item.textValue}>
          <Tag
            size="sm"
            className="mr-1"
            onRemove={() => state.selectionManager.toggleSelection(item.key)}
          >
            {item.textValue}
          </Tag>
        </OverflowItem>
      ))}
    </Overflow>
  );
};

/**
 * @internal
 */
export const MultiSelectTags = forwardRef(MultiSelectTagsImpl) as <T extends object>(
  props: MultiSelectTagsProps<T> & { ref?: ForwardedRef<HTMLDivElement> }
) => ReactElement;
