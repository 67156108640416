import { forwardRef } from "react";
import {
  mergeProps,
  useFocusRing,
  useTableCell,
  useTableSelectionCheckbox,
  VisuallyHidden,
} from "react-aria";

import { useDomRef } from "../../../hooks/useDomRef";
import { cn } from "../../../utils/cn";
import { filterDOMProps } from "../../../utils/filterDomProps";
import { Checkbox } from "../../checkbox/Checkbox";

import type { GridNode } from "@react-types/grid";
import type { HTMLAttributes, Key } from "react";
import type { SelectionMode, TableState } from "react-stately";
import type { CheckboxProps } from "../../checkbox/Checkbox";
import type { TableCellProps } from "../TableCell";
import type { TableClassNames, TableSlots } from "../tableVariants";

interface TableCheckboxCellProps<T = object> extends HTMLAttributes<HTMLTableCellElement> {
  rowKey: Key;
  node: GridNode<T>;
  state: TableState<T>;
  checkboxProps?: CheckboxProps;
  selectionMode?: SelectionMode;
  slots: TableSlots;
  classNames?: TableClassNames;
}

export const TableCheckboxCell = forwardRef<HTMLTableCellElement, TableCheckboxCellProps>(
  (
    {
      rowKey,
      node,
      state,
      checkboxProps,
      selectionMode,
      className,
      slots,
      classNames,
      ...otherProps
    },
    ref
  ) => {
    const domRef = useDomRef(ref);

    const { gridCellProps } = useTableCell({ node }, state, domRef);
    const { isFocusVisible, focusProps } = useFocusRing();
    const {
      checkboxProps: { "aria-label": ariaLabel, onChange, ...otherCheckboxProps },
    } = useTableSelectionCheckbox({ key: node.parentKey || node.key }, state);

    const isRowSelected = state.selectionManager.isSelected(rowKey);

    const cellProps: TableCellProps = node.props;

    return (
      <td
        ref={domRef}
        data-focus-visible={isFocusVisible}
        data-selected={isRowSelected}
        {...mergeProps(gridCellProps, focusProps, filterDOMProps(cellProps), otherProps)}
        className={slots.td({ className: cn(classNames?.td, className, cellProps.className) })}
      >
        {selectionMode === "single" ? (
          <VisuallyHidden>{ariaLabel}</VisuallyHidden>
        ) : (
          <Checkbox onValueChange={onChange} {...mergeProps(checkboxProps, otherCheckboxProps)} />
        )}
      </td>
    );
  }
);
TableCheckboxCell.displayName = "Table.CheckboxCell";
