/**
 * TODO(sam): This should be consolidated with the Radix popover component that's also in this
 * folder (this will probably mean removing that implementation and building a complete Popover
 * using this hook). So far this is only used for StandalonePopover, which is used in the Select
 * and Combobox components so we want it to interoperate with react-aria hooks.
 */

import { useFocusRing } from "@react-aria/focus";
import { ariaHideOutside, useOverlayTrigger } from "@react-aria/overlays";
import { mergeProps, mergeRefs } from "@react-aria/utils";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { tv } from "tailwind-variants";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";
import { getArrowPlacement, getShouldUseAxisPlacement } from "../../utils/overlay";
import { useReactAriaPopover } from "./useAriaPopover";

import type { OverlayTriggerState } from "@react-stately/overlays";
import type { OverlayTriggerProps } from "@react-types/overlays";
import type { PressEvent } from "@react-types/shared";
import type { HTMLMotionProps } from "framer-motion";
import type { HTMLAttributes, MouseEvent, Ref, RefObject } from "react";
import type { VariantProps } from "tailwind-variants";
import type { ReactRef, SlotsToClasses } from "../../utils/types";
import type { ReactAriaPopoverProps } from "./useAriaPopover";

export const popoverVariants = tv({
  slots: {
    base: [
      "z-0",
      "relative",
      "bg-transparent",
      // arrow
      "before:content-['']",
      "before:hidden",
      "before:-z-1",
      "before:absolute",
      "before:rotate-45",
      "before:w-2.5",
      "before:h-2.5",
      "before:rounded-sm",
      "after:content-['']",
      "after:hidden",
      "after:z-1",
      "after:absolute",
      "after:rotate-45",
      "after:w-2",
      "after:h-2",
      "after:rounded-sm",
      // visibility
      "data-[arrow=true]:before:block",
      // top
      "data-[placement=top]:before:bottom-[-3.5px]",
      "data-[placement=top]:before:left-1/2",
      "data-[placement=top]:before:-translate-x-1/2",
      "data-[placement=top]:after:bottom-[-3px]",
      "data-[placement=top]:after:left-1/2",
      "data-[placement=top]:after:-translate-x-1/2",
      "data-[placement=top-start]:before:bottom-[-3.5px]",
      "data-[placement=top-start]:before:left-3",
      "data-[placement=top-start]:after:bottom-[-3px]",
      "data-[placement=top-start]:after:left-[13px]",
      "data-[placement=top-end]:before:bottom-[-3.5px]",
      "data-[placement=top-end]:before:right-3",
      "data-[placement=top-end]:after:bottom-[-3px]",
      "data-[placement=top-end]:after:right-[13px]",
      // bottom
      "data-[placement=bottom]:before:top-[-3.5px]",
      "data-[placement=bottom]:before:left-1/2",
      "data-[placement=bottom]:before:-translate-x-1/2",
      "data-[placement=bottom]:after:top-[-2.5px]",
      "data-[placement=bottom]:after:left-1/2",
      "data-[placement=bottom]:after:-translate-x-1/2",
      "data-[placement=bottom-start]:before:top-[-3.5px]",
      "data-[placement=bottom-start]:before:left-3",
      "data-[placement=bottom-start]:after:top-[-2.5px]",
      "data-[placement=bottom-start]:after:left-[13px]",
      "data-[placement=bottom-end]:before:top-[-3.5px]",
      "data-[placement=bottom-end]:before:right-3",
      "data-[placement=bottom-end]:after:top-[-2.5px]",
      "data-[placement=bottom-end]:after:right-[13px]",
      // left
      "data-[placement=left]:before:right-[-3px]",
      "data-[placement=left]:before:top-1/2",
      "data-[placement=left]:before:-translate-y-1/2",
      "data-[placement=left]:after:right-[-2px]",
      "data-[placement=left]:after:top-1/2",
      "data-[placement=left]:after:-translate-y-1/2",
      "data-[placement=left-start]:before:right-[-3px]",
      "data-[placement=left-start]:before:top-1/4",
      "data-[placement=left-start]:after:right-[-2px]",
      "data-[placement=left-start]:after:top-[calc(25%_+_1px)]",
      "data-[placement=left-end]:before:right-[-3px]",
      "data-[placement=left-end]:before:bottom-1/4",
      "data-[placement=left-end]:after:right-[-2px]",
      "data-[placement=left-end]:after:bottom-[calc(25%_+_1px)]",
      // right
      "data-[placement=right]:before:left-[-3px]",
      "data-[placement=right]:before:top-1/2",
      "data-[placement=right]:before:-translate-y-1/2",
      "data-[placement=right]:after:left-[-2px]",
      "data-[placement=right]:after:top-1/2",
      "data-[placement=right]:after:-translate-y-1/2",
      "data-[placement=right-start]:before:left-[-3px]",
      "data-[placement=right-start]:before:top-1/4",
      "data-[placement=right-start]:after:left-[-2px]",
      "data-[placement=right-start]:after:top-[calc(25%_+_1px)]",
      "data-[placement=right-end]:before:left-[-3px]",
      "data-[placement=right-end]:before:bottom-1/4",
      "data-[placement=right-end]:after:left-[-2px]",
      "data-[placement=right-end]:after:bottom-[calc(25%_+_1px)]",
      // focus ring
      "outline-none",
      "data-[focus-visible=true]:z-10",
      "data-[focus-visible=true]:outline-2",
      "data-[focus-visible=true]:outline-focus",
      "data-[focus-visible=true]:outline-offset-2",
    ],
    content: [
      "z-10",
      "px-2.5",
      "py-1",
      "w-full",
      "inline-flex",
      "flex-col",
      "items-center",
      "justify-center",
      "box-border",
      "subpixel-antialiased",
      "outline-none",
      "rounded",
      "shadow",
    ],
    trigger: [""],
    backdrop: ["hidden"],
    arrow: [],
  },
  variants: {
    size: {
      sm: { content: "text-tiny" },
      md: { content: "text-small" },
      lg: { content: "text-medium" },
    },
    color: {
      default: {
        base: [
          "before:border",
          "before:border-solid",
          "before:border-gray-50",
          "before:bg-white",
          "before:shadow",
          "after:bg-white",
          "dark:before:border-gray-800",
          "dark:before:bg-gray-800",
          "dark:after:bg-gray-900",
          "data-[arrow=true]:after:block",
        ],
        content:
          "text:gray-850 border border-solid border-gray-50 bg-white dark:border-gray-800 dark:bg-gray-900 dark:text-white",
      },
      inverted: {
        base: "before:z-1 before:bg-gray-900 after:bg-gray-900 dark:before:bg-white dark:after:bg-white",
        content: "bg-gray-900 text-white dark:bg-white dark:text-gray-850",
      },
    },
    backdrop: {
      transparent: {},
      opaque: {
        backdrop: "bg-black/50 backdrop-opacity-60",
      },
      blur: {
        backdrop: "bg-black/30 backdrop-blur-sm backdrop-saturate-150",
      },
    },
    triggerScaleOnOpen: {
      true: {
        trigger: ["aria-expanded:scale-[0.97]", "aria-expanded:opacity-70", "subpixel-antialiased"],
      },
      false: {},
    },
    disableAnimation: {
      true: {
        base: "animate-none",
      },
    },
  },
  defaultVariants: {
    color: "default",
    size: "md",
    backdrop: "transparent",
    disableAnimation: false,
    triggerScaleOnOpen: true,
  },
  compoundVariants: [
    // backdrop (opaque/blur)
    {
      backdrop: ["opaque", "blur"],
      class: {
        backdrop: "fixed inset-0 -z-30 block size-full",
      },
    },
  ],
});

export type PopoverVariantProps = VariantProps<typeof popoverVariants>;
type PopoverSlots = keyof ReturnType<typeof popoverVariants>;

export interface Props extends HTMLAttributes<HTMLDivElement> {
  /**
   * Ref to the DOM node.
   */
  ref?: ReactRef<HTMLDivElement | null>;
  /**
   * The controlled state of the popover.
   */
  state?: OverlayTriggerState;
  /**
   * The ref for the element which the overlay positions itself with respect to.
   */
  triggerRef?: RefObject<HTMLElement>;
  /**
   * Whether the scroll event should be blocked when the overlay is open.
   * @default true
   */
  shouldBlockScroll?: boolean;
  /**
   * Type of overlay that is opened by the trigger.
   */
  triggerType?: "dialog" | "menu" | "listbox" | "tree" | "grid";
  /**
   * The props to modify the framer motion animation. Use the `variants` API to create your own animation.
   */
  motionProps?: HTMLMotionProps<"div">;
  /**
   * The container element in which the overlay portal will be placed.
   * @default document.body
   */
  portalContainer?: Element;
  /**
   *  Callback fired when the popover is closed.
   */
  onClose?: () => void;

  classNames?: SlotsToClasses<PopoverSlots>;
}

export type UsePopoverProps = Props &
  Omit<ReactAriaPopoverProps, "triggerRef" | "popoverRef"> &
  OverlayTriggerProps &
  PopoverVariantProps;

export function usePopover(props: UsePopoverProps) {
  const {
    children,
    ref,
    state: stateProp,
    triggerRef: triggerRefProp,
    scrollRef,
    defaultOpen,
    onOpenChange,
    isOpen: isOpenProp,
    isNonModal = true,
    shouldFlip = true,
    containerPadding = 12,
    shouldBlockScroll = false,
    isDismissable = true,
    shouldCloseOnBlur,
    portalContainer,
    placement: placementProp = "top",
    triggerType = "dialog",
    showArrow = false,
    offset = 7,
    crossOffset = 0,
    boundaryElement,
    isKeyboardDismissDisabled,
    shouldCloseOnInteractOutside,
    motionProps,
    className,
    classNames,
    onClose,
    color,
    size,
    backdrop,
    triggerScaleOnOpen,
    ...otherProps
  } = props;

  const domRef = useDomRef(ref);

  const domTriggerRef = useRef<HTMLElement>(null);
  const wasTriggerPressedRef = useRef(false);

  const triggerRef = triggerRefProp || domTriggerRef;

  const disableAnimation = props.disableAnimation ?? false;

  const innerState = useOverlayTriggerState({
    isOpen: isOpenProp,
    defaultOpen,
    onOpenChange: isOpen => {
      onOpenChange?.(isOpen);
      if (!isOpen) {
        onClose?.();
      }
    },
  });

  const state = stateProp || innerState;

  const {
    popoverProps: ariaPopoverProps,
    underlayProps,
    placement: ariaPlacement,
  } = useReactAriaPopover(
    {
      triggerRef,
      isNonModal,
      popoverRef: domRef,
      placement: placementProp,
      offset,
      scrollRef,
      isDismissable,
      shouldCloseOnBlur,
      boundaryElement,
      crossOffset,
      shouldFlip,
      containerPadding,
      isKeyboardDismissDisabled,
      shouldCloseOnInteractOutside,
    },
    state
  );

  const { triggerProps } = useOverlayTrigger({ type: triggerType }, state, triggerRef);

  const { isFocusVisible, isFocused, focusProps } = useFocusRing();

  const slots = useMemo(
    () => popoverVariants({ color, size, backdrop, triggerScaleOnOpen, disableAnimation }),
    [color, size, backdrop, triggerScaleOnOpen, disableAnimation]
  );

  const popoverProps = {
    ref: domRef,
    ...mergeProps(ariaPopoverProps, otherProps),
    style: mergeProps(ariaPopoverProps.style, otherProps.style),
  };

  const dialogProps = {
    "data-slot": "base",
    "data-open": state.isOpen,
    "data-focus": isFocused,
    "data-arrow": showArrow,
    "data-focus-visible": isFocusVisible,
    "data-placement": getArrowPlacement(ariaPlacement, placementProp),
    ...focusProps,
    className: slots.base({ className: cn(className, classNames?.base) }),
    style: {
      // this prevent the dialog to have a default outline
      outline: "none",
    },
  };

  const contentProps = useMemo(
    () => ({
      "data-slot": "content",
      "data-open": state.isOpen,
      "data-arrow": showArrow,
      "data-placement": getArrowPlacement(ariaPlacement, placementProp),
      className: slots.content({ className: classNames?.content }),
    }),
    [slots, state.isOpen, showArrow, ariaPlacement, placementProp]
  );

  const placement = useMemo(
    () => (getShouldUseAxisPlacement(ariaPlacement, placementProp) ? ariaPlacement : placementProp),
    [ariaPlacement, placementProp]
  );

  const onPress = useCallback(
    (e: PressEvent) => {
      let pressTimer: ReturnType<typeof setTimeout>;

      // Artificial delay to prevent the underlay to be triggered immediately after the onPress
      // this only happens when the backdrop is blur or opaque & pointerType === "touch"
      // TODO: find a better way to handle this
      if (
        e.pointerType === "touch" &&
        (props?.backdrop === "blur" || props?.backdrop === "opaque")
      ) {
        pressTimer = setTimeout(() => {
          wasTriggerPressedRef.current = true;
        }, 100);
      } else {
        wasTriggerPressedRef.current = true;
      }

      triggerProps.onPress?.(e);

      return () => {
        clearTimeout(pressTimer);
      };
    },
    [triggerProps?.onPress]
  );

  const getTriggerProps = useCallback(
    (ref: Ref<any> | null | undefined = null) => {
      return {
        "data-slot": "trigger",
        "aria-haspopup": "dialog",
        ...triggerProps,
        onPress,
        className: slots.trigger({ className: classNames?.trigger }),
        ref: mergeRefs(ref, triggerRef),
      };
    },
    [state, triggerProps, onPress, slots.trigger, classNames?.trigger, triggerRef]
  );

  const backdropProps = useMemo(
    () => ({
      "data-slot": "backdrop",
      className: slots.backdrop({ className: classNames?.backdrop }),
      onClick: (e: MouseEvent<HTMLElement>) => {
        if (!wasTriggerPressedRef.current) {
          e.preventDefault();

          return;
        }

        state.close();
        wasTriggerPressedRef.current = false;
      },
      ...underlayProps,
    }),
    [slots, state.isOpen, underlayProps]
  );

  useEffect(() => {
    if (state.isOpen && domRef?.current) {
      return ariaHideOutside([domRef?.current]);
    }
  }, [state.isOpen, domRef]);

  return {
    state,
    children,
    triggerRef,
    placement,
    isNonModal,
    popoverRef: domRef,
    portalContainer,
    isOpen: state.isOpen,
    onClose: state.close,
    disableAnimation,
    shouldBlockScroll,
    backdrop: props.backdrop ?? "transparent",
    motionProps,
    backdropProps,
    popoverProps,
    getTriggerProps,
    dialogProps,
    contentProps,
  };
}

export type UsePopoverReturn = ReturnType<typeof usePopover>;
