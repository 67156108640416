/** Use this when a component needs a function prop but doesn't need to do anything */

import type { Key, SelectionMode } from "react-stately";

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const NOOP_CALLBACK = () => {};

// Use this for data- attributes so that they only appear when the value is true
type Booleanish = boolean | "true" | "false";
export const dataAttr = (condition: boolean | undefined): Booleanish =>
  (condition ? "true" : undefined) as Booleanish;

export const formValueFromSelectedKeys = (
  selectedKeys: Set<Key>,
  selectionMode: SelectionMode
): Key | Key[] | undefined => {
  const keysArray = Array.from(selectedKeys);
  if (selectionMode === "multiple") {
    return keysArray;
  } else {
    return keysArray[0];
  }
};

export const selectedKeysFromFormValue = (
  value: Key | Key[] | undefined,
  selectionMode: SelectionMode
): Set<Key> => {
  if (selectionMode === "multiple") {
    return new Set(value as Key[]);
  } else {
    return new Set(value ? [value as Key] : []);
  }
};
