import { tv } from "tailwind-variants";

import { dataFocusVisible } from "../../utils/tailwindClasses";

import type { VariantProps } from "tailwind-variants";
import type { SlotsToClasses } from "../../utils/types";

export const tableVariants = tv({
  slots: {
    base: [
      "w-full",
      "overflow-auto",
      "rounded",
      "border",
      "border-solid",
      "border-gray-100",
      "bg-white",
      "text-gray-850",
      "dark:text-white",
      "@container",
      "dark:border-gray-700",
      "dark:bg-gray-900",
    ],
    table: "w-full caption-bottom text-sm",
    thead: "bg-gray-20 dark:bg-gray-800",
    tbody: "",
    tr: [
      "group",
      "border-b",
      "border-t-0",
      "border-l-0",
      "border-r-0",
      "border-solid",
      "border-gray-100",
      "dark:border-gray-700",
      ...dataFocusVisible,
    ],
    th: [
      "group",
      "p-3",
      "text-left",
      "align-middle",
      "font-semibold",
      "leading-6",
      "[&:has([role=checkbox])]:pr-0",
      "relative",
      "after:content-['']",
      "after:absolute",
      "after:w-px",
      "after:right-0",
      "after:top-3",
      "after:bottom-3",
      "after:bg-gray-100",
      "dark:after:bg-gray-700",
      "last:after:content-none",
      "data-[sortable=true]:cursor-pointer",
      "data-[hover=true]:!text-gray-500",
      "dark:data-[hover=true]:!text-gray-300",
      ...dataFocusVisible,
    ],
    td: [
      "relative",
      "overflow-hidden",
      "text-ellipsis",
      "p-3",
      "align-middle",
      "text-sm",
      "leading-6",
      "[&:has([role=checkbox])]:pr-0",
      "[&>*]:z-1",
      "[&>*]:relative",
      ...dataFocusVisible,
      // before content for selection
      "before:content-['']",
      "before:absolute",
      "before:z-0",
      "before:inset-0",
      "before:opacity-0",
      "before:bg-blue-100/60",
      "dark:before:bg-blue-800/20",
      "data-[selected=true]:text-blue",
      "data-[selected=true]:before:opacity-100",
      // disabled
      "group-data-[disabled=true]:text-gray-300",
      "dark:group-data-[disabled=true]:text-gray-600",
      "group-data-[disabled=true]:cursor-not-allowed",
    ],
    sortIcon: [
      "ml-2",
      "opacity-0",
      "text-inherit",
      "inline-block",
      "transition",
      "data-[visible=true]:opacity-100",
      "group-data-[hover=true]:opacity-100",
      "data-[direction=ascending]:rotate-180",
    ],
  },
  variants: {
    fixedLayout: {
      false: {
        table: "table-auto",
      },
      true: {
        table: "table-fixed",
      },
    },
    align: {
      start: {
        th: "text-start",
        td: "text-start",
      },
      center: {
        th: "text-center",
        td: "text-center",
      },
      end: {
        th: "text-end",
        td: "text-end",
      },
    },
    isSelectable: {
      true: {
        tr: "cursor-default",
        td: [
          "group-aria-[selected=false]:group-data-[hover=true]:before:bg-blue-100/60",
          "group-aria-[selected=false]:group-data-[hover=true]:before:opacity-100",
          "dark:group-aria-[selected=false]:group-data-[hover=true]:before:bg-blue-800/20",
        ],
      },
    },
    isMultiSelectable: {
      true: {},
      false: {},
    },
    hasBottomContent: {
      true: "",
      false: {
        tbody: "[&_tr:last-child]:border-0",
      },
    },
  },
  defaultVariants: {
    fixedLayout: false,
  },
});

export type TableVariantProps = VariantProps<typeof tableVariants>;
export type TableSlots = ReturnType<typeof tableVariants>;
export type TableClassNames = SlotsToClasses<keyof TableSlots>;
