import { some } from "lodash";
import { forwardRef } from "react";
import { tv } from "tailwind-variants";

import { childrenToArray } from "../../utils/children";
import { cn } from "../../utils/cn";

import type { ComponentPropsWithoutRef } from "react";
import type { VariantProps } from "tailwind-variants";
import type { ForwardRefComponentWithSubcomponents } from "../../utils/types";

const layoutVariants = tv({
  base: "flex flex-auto",
  variants: {
    isRoot: {
      true: "supports-[height:100dvh]:h-dvh supports-[not(height:100dvh)]:h-screen",
      false: "h-full",
    },
    hasSidebar: {
      true: "flex-row",
      false: "flex-col",
    },
  },
});

interface LayoutProps
  extends ComponentPropsWithoutRef<"section">,
    VariantProps<typeof layoutVariants> {}

export const Layout = forwardRef<HTMLElement, LayoutProps>(
  ({ children, className, hasSidebar: hasSidebarProp = false, isRoot = false, ...props }, ref) => {
    const hasSidebar =
      hasSidebarProp || some(childrenToArray(children), child => child.type === LayoutSidebar);
    console.log({ hasSidebar });

    return (
      <section ref={ref} className={layoutVariants({ hasSidebar, isRoot, className })} {...props}>
        {children}
      </section>
    );
  }
) as ForwardRefComponentWithSubcomponents<
  HTMLElement,
  {
    Header: typeof LayoutHeader;
    Sidebar: typeof LayoutSidebar;
    Content: typeof LayoutContent;
  },
  LayoutProps
>;
Layout.displayName = "Layout";

const LayoutHeader = forwardRef<HTMLElement, ComponentPropsWithoutRef<"header">>(
  ({ children, className, ...props }, ref) => {
    return (
      <header ref={ref} className={cn("flex-fixed", className)} {...props}>
        {children}
      </header>
    );
  }
);
LayoutHeader.displayName = "Layout.Header";
Layout.Header = LayoutHeader;

const LayoutSidebar = forwardRef<HTMLElement, ComponentPropsWithoutRef<"aside">>(
  ({ children, className, ...props }, ref) => {
    return (
      <aside ref={ref} className={cn("flex-fixed", className)} {...props}>
        {children}
      </aside>
    );
  }
);
LayoutSidebar.displayName = "Layout.Sidebar";
Layout.Sidebar = LayoutSidebar;

const LayoutContent = forwardRef<HTMLElement, ComponentPropsWithoutRef<"main">>(
  ({ children, className, ...props }, ref) => {
    return (
      <main ref={ref} className={cn("flex-auto overflow-auto", className)} {...props}>
        {children}
      </main>
    );
  }
);
LayoutContent.displayName = "Layout.Content";
Layout.Content = LayoutContent;
