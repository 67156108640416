import type { Quaternion, Vector3 } from "three";
import type { Tuple3 } from "@skydio/core";
import type { Degrees } from "./types/semantic";

/**
 * Converts a NED vector3 to a RUB vector3.
 *
 * NED stands for North, East, Down, which is the standard coordinate system used in the WORLD frame.
 * RUB stands for Right, Up, Backward, which is the standard coordinate system used in ThreeJS.
 */
export const convertNEDVector3ToRUBVector3 = (ned: Vector3, target: Vector3): Vector3 => {
  return target.set(ned.y, -ned.z, -ned.x);
};

/**
 * Converts a NED quaternion to a RUB quaternion.
 *
 * NED stands for North, East, Down, which is the standard coordinate system used in the WORLD frame.
 * RUB stands for Right, Up, Backward, which is the standard coordinate system used in ThreeJS.
 */
export const convertNEDQuaternionToRUBQuaternion = (
  ned: Quaternion,
  target: Quaternion
): Quaternion => {
  return target.set(ned.y, -ned.z, -ned.x, ned.w);
};

/**
 * Converts a ENU `Vector3` representing a position to a EUS `Vector3` also representing a position.
 *
 * ENU stands for East, Nort, Up, which is the standard coordinate system used in the SITE frame.
 * EUS stands for East, Up, South, (a.k.a. RUB, Right Up Back) which is the standard coordinate system used in ThreeJS.
 */
export const convertENUVectorToEUSVector = (enu: Vector3, target: Vector3): Vector3 => {
  return target.set(enu.x, enu.z, -enu.y);
};

/**
 * Converts an EUS `Vector3` representing a position to a ENU `Vector3` also representing a position.
 *
 * EUS stands for East, Up, South, (a.k.a. RUB, Right Up Back) which is the standard coordinate system used in ThreeJS.
 * ENU stands for East, Nort, Up, which is the standard coordinate system used in the SITE frame.
 */
export const convertEUSVectorToENUVector = (eus: Vector3, target: Vector3): Vector3 => {
  return target.set(eus.x, -eus.z, eus.y);
};

/**
 * Converts a ENU array representing a position vector to a EUS array also representing a position vector.
 * This is the inverse of `convertEUSTupleToENUTuple`.
 *
 * ENU stands for East, Nort, Up, which is the standard coordinate system used in the SITE frame.
 * EUS stands for East, Up, South, (a.k.a. RUB, Right Up Back) which is the standard coordinate system used in ThreeJS.
 */
export const convertENUTupleToEUSTuple = (enu: Tuple3<number>): Tuple3<number> => {
  return [enu[0], enu[2], -enu[1]];
};

/**
 * Converts a EUS array representing a position vector to a ENU array also representing a position vector.
 * This is the inverse of `convertENUTupleToEUSTuple`.
 *
 * ENU stands for East, Nort, Up, which is the standard coordinate system used in the SITE frame.
 * EUS stands for East, Up, South, (a.k.a. RUB, Right Up Back) which is the standard coordinate system used in ThreeJS.
 */
export const convertEUSTupleToENUTuple = (eus: Tuple3<number>): Tuple3<number> => {
  return [eus[0], -eus[2], eus[1]];
};

/**
 * Converts a FLU quaternion to a EUS quaternion.
 *
 * FLU stands for Forward, Left, Up, which is the standard coordinate system used in the NAV frame.
 * EUS stands for East, Up, South, which is the standard coordinate system used in ThreeJS.
 */
export const convertFLUQuaternionToEUSQuaternion = (
  flu: Quaternion,
  target: Quaternion
): Quaternion => {
  return target.set(-flu.y, flu.z, -flu.x, flu.w);
};

/**
 * Autonomy frames are due east when the heading is 0 degrees, so we apply a 90 degree rotation to
 * offset the fact that in the editor we want to show a heading of 0 degrees as due north.
 */
const ENU_ROTATION_OFFSET: Degrees = 90;

/**
 * Converts a heading in degrees expressed from a Northward Clockwise bearing to the equivalent
 * heading in degrees expressed from an Eastward Counter-Clockwise, and vice versa (meaning that
 * if you pass an Eastward Counter-Clockwise bearing, you'll get a Northward Clockwise bearing).
 *
 * Our widgets on the UI move Clockwise however the rotations on the vehicle are expressed as
 * Counter-Clockwise, so this function inverts a rotation so that we can show a North bearing
 * in the UI, while saving it as a Counter-Clockwise rotation from East.
 */
export const invertHeadingRotation = (value: Degrees): Degrees => {
  return (-value + ENU_ROTATION_OFFSET + 720) % 360;
};

/**
 * Converts an ENU quaternion to an EUS quaternion.
 *
 * ENU stands for East, North, Up, which is the standard coordinate system used in the world frame (linearized GPS frame).
 * EUS stands for East, Up, South, (a.k.a. RUB, Right Up Back) which is the standard coordinate system used in ThreeJS.
 */
export const convertENUQuaternionToEUSQuaternion = (
  enu: Quaternion,
  target: Quaternion
): Quaternion => {
  return target.set(enu.x, enu.z, -enu.y, enu.w);
};
