import { forwardRef, useMemo } from "react";
import { chain, mergeProps, useButton, useFocusRing, useHover } from "react-aria";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";
import { filterDOMProps } from "../../utils/filterDomProps";
import { dataAttr } from "../../utils/misc";
import { Ripple } from "../ripple/Ripple";
import { useRipple } from "../ripple/useRipple";
import { CardBody } from "./CardBody";
import { CardFooter } from "./CardFooter";
import { CardHeader } from "./CardHeader";
import { cardVariants } from "./cardVariants";
import { CardProvider } from "./context";

import type { FocusableProps } from "@react-types/shared";
import type { ComponentPropsWithoutRef, MouseEvent, RefObject } from "react";
import type { AriaButtonProps, PressEvents } from "react-aria";
import type { ForwardRefComponentWithSubcomponents } from "../../utils/types";
import type { RippleProps } from "../ripple/Ripple";
import type { CardClassNames, CardVariantProps } from "./cardVariants";
import type { CardContext } from "./context";

export interface CardProps
  extends Omit<ComponentPropsWithoutRef<"div">, keyof PressEvents | keyof FocusableProps>,
    PressEvents,
    FocusableProps,
    CardVariantProps {
  disableRipple?: boolean;
  classNames?: CardClassNames;
  allowTextSelectionOnPress?: boolean;
}

export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      disableRipple,
      isDisabled,
      isPressable,
      isHoverable,
      shadow,
      size,
      fullWidth,
      autoFocus,
      onPress,
      onClick,
      className,
      classNames,
      ...props
    },
    ref
  ) => {
    const domRef = useDomRef(ref);

    const { onClick: onRippleClickHandler, onClear: onClearRipple, ripples } = useRipple();

    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
      if (!disableRipple && domRef.current) {
        onRippleClickHandler(e);
      }
    };

    const { buttonProps, isPressed } = useButton(
      {
        onPress,
        isDisabled: !isPressable,
        onClick: chain(onClick, handleClick),
        ...props,
      } as unknown as AriaButtonProps<"button">,
      domRef
    );

    const { hoverProps, isHovered } = useHover({ isDisabled: !isHoverable, ...props });

    const { isFocusVisible, isFocused, focusProps } = useFocusRing({ autoFocus });

    const slots = useMemo(
      () =>
        cardVariants({
          isDisabled,
          isHoverable,
          isPressable,
          fullWidth,
          shadow,
          size,
        }),
      [isDisabled, isHoverable, isPressable, fullWidth, shadow, size]
    );

    const context = useMemo<CardContext>(
      () => ({
        slots,
        classNames,
        isDisabled,
        fullWidth,
      }),
      [slots, classNames, isDisabled, fullWidth]
    );

    const cardProps = useMemo(
      () => ({
        ref: domRef as RefObject<any>,
        className: slots.base({ className: cn(classNames?.base, className) }),
        tabIndex: isPressable ? 0 : -1,
        "data-hover": dataAttr(isHovered),
        "data-pressed": dataAttr(isPressed),
        "data-focus": dataAttr(isFocused),
        "data-focus-visible": dataAttr(isFocusVisible),
        "data-disabled": dataAttr(isDisabled),
        ...mergeProps(
          isPressable ? { ...buttonProps, ...focusProps, role: "button" } : {},
          isHoverable ? hoverProps : {},
          filterDOMProps(props)
        ),
      }),
      [
        domRef,
        slots,
        className,
        classNames,
        isPressable,
        isHovered,
        isPressed,
        isFocused,
        isHoverable,
        isFocusVisible,
        isDisabled,
        buttonProps,
        focusProps,
        hoverProps,
        props,
      ]
    );

    const rippleProps = useMemo<RippleProps>(
      () => ({ ripples, onClear: onClearRipple }),
      [ripples, onClearRipple]
    );

    const Component = isPressable ? "button" : "div";

    return (
      <Component {...cardProps}>
        <CardProvider value={context}>{children}</CardProvider>
        {isPressable && !disableRipple && <Ripple {...rippleProps} />}
      </Component>
    );
  }
) as ForwardRefComponentWithSubcomponents<
  HTMLDivElement,
  {
    Header: typeof CardHeader;
    Body: typeof CardBody;
    Footer: typeof CardFooter;
  },
  CardProps
>;
Card.displayName = "Card";
Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;
