import { createContext, useContext } from "react";

import type { UsePopoverReturn } from "./usePopover";

export const popoverContext = createContext<UsePopoverReturn | undefined>(undefined);
popoverContext.displayName = "PopoverContext";

export const usePopoverContext = () => {
  const context = useContext(popoverContext);

  if (!context) {
    throw new Error("Popover compound components cannot be rendered outside the Popover component");
  }

  return context;
};
