import { forwardRef } from "react";

import { cn } from "../../utils/cn";
import { Paragraph } from "../typography/Paragraph";
import { useModalContext } from "./context";

import type { TextProps } from "../typography/Text";

export const ModalDescription = forwardRef<HTMLParagraphElement, TextProps>(
  ({ className, ...props }, ref) => {
    const { slots, classNames } = useModalContext();

    return (
      <Paragraph
        ref={ref}
        className={slots.description({ className: cn(classNames?.description, className) })}
        emphasis="secondary"
        {...props}
      />
    );
  }
);
ModalDescription.displayName = "Modal.Description";
