import { forwardRef } from "react";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";
import { useModalContext } from "./context";

import type { ComponentPropsWithoutRef } from "react";

export const ModalFooter = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"footer">>(
  ({ children, className, ...props }, ref) => {
    const { slots, classNames } = useModalContext();

    const domRef = useDomRef(ref);

    return (
      <footer
        ref={domRef}
        className={slots.footer({ className: cn(classNames?.footer, className) })}
        {...props}
      >
        {children}
      </footer>
    );
  }
);
ModalFooter.displayName = "Modal.Footer";
