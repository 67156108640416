import {
  DEGREES_PER_RADIAN,
  FEET_PER_METER,
  FPM_TO_MPS,
  INHG_PER_HPA,
  KNOTS_TO_MPS,
  METERS_PER_NAUTICAL_MILE,
  MICROSECONDS_PER_MILLISECOND,
  MICROSECONDS_PER_SECOND,
  MILLIMETERS_PER_INCH,
  MILLISECONDS_PER_SECOND,
  MPH_TO_MPS,
  RADIANS_PER_DEGREE,
  ZERO_CELSIUS_IN_KELVIN,
} from "./constants";

import type {
  Degrees,
  Feet,
  FeetPerMinute,
  Inches,
  Knots,
  Meters,
  MetersPerSecond,
  Microseconds,
  MilesPerHour,
  Millimeters,
  Milliseconds,
  NauticalMiles,
  Radians,
  Seconds,
} from "./types/semantic";

export const degToRad = (degrees: Degrees): Radians => degrees * RADIANS_PER_DEGREE;
export const radToDeg = (radians: Radians): Degrees => radians * DEGREES_PER_RADIAN;

export const millisecondsToSeconds = (ms: Milliseconds): Seconds => ms / MILLISECONDS_PER_SECOND;
export const secondsToMilliseconds = (s: Seconds): Milliseconds => s * MILLISECONDS_PER_SECOND;

export const millisecondsToMicroseconds = (ms: Milliseconds): Microseconds =>
  ms * MICROSECONDS_PER_MILLISECOND;
export const microsecondsToMilliseconds = (us: Microseconds): Milliseconds =>
  us / MICROSECONDS_PER_MILLISECOND;

export const secondsToMicroseconds = (s: Seconds): Microseconds => s * MICROSECONDS_PER_SECOND;
export const microsecondsToSeconds = (us: Microseconds): Seconds => us / MICROSECONDS_PER_SECOND;

export const feetToMeters = (feet: Feet): Meters => feet / FEET_PER_METER;
export const metersToFeet = (meters: Meters): Feet => meters * FEET_PER_METER;

export const inchesToMillimeters = (inches: Inches): Millimeters => inches * MILLIMETERS_PER_INCH;
export const millimetersToInches = (mm: Millimeters): Feet => mm / MILLIMETERS_PER_INCH;

export const mpsToMph = (mps: MetersPerSecond): MilesPerHour => mps * MPH_TO_MPS;
export const mphToMps = (mph: MilesPerHour): MetersPerSecond => mph / MPH_TO_MPS;

export const nauticalMilesToMeters = (nm: NauticalMiles): Meters => nm * METERS_PER_NAUTICAL_MILE;
export const metersToNauticalMiles = (meters: Meters): NauticalMiles =>
  meters / METERS_PER_NAUTICAL_MILE;

export const knotsToMps = (kt: Knots): Meters => kt * KNOTS_TO_MPS;
export const mpsToKnots = (mps: Meters): Knots => mps / KNOTS_TO_MPS;

export const fpmToMps = (fpm: FeetPerMinute): Meters => fpm * FPM_TO_MPS;
export const mpsToFpm = (mps: Meters): FeetPerMinute => mps / FPM_TO_MPS;

export const celsiusToKelvin = (celsius: number): number => celsius + ZERO_CELSIUS_IN_KELVIN;
export const kelvinToCelsius = (kelvin: number): number => kelvin - ZERO_CELSIUS_IN_KELVIN;

export const hectopascalsToInchesOfMercury = (hPa: number): number => hPa * INHG_PER_HPA;
export const inchesOfMercuryToHectopascals = (inHg: number): number => inHg / INHG_PER_HPA;
