import { useCallback, useEffect, useState } from "react";

import type { RefObject } from "react";

/**
 * A common hook to check whether an element is overflowing.
 * Inspiration: https://stackoverflow.com/questions/42012130/how-to-detect-overflow-of-react-component-without-reactdom
 * @param ref: a useRef object to the element in overflow question
 * @returns useState boolean value: whether the element is overflowing
 */
export const useElementOverflowing = <T extends HTMLElement = HTMLElement>(
  ref?: RefObject<T | null>
) => {
  const [overflowing, setOverflowing] = useState(false);

  // The actual comparison function to check if the element is overflowing
  const compareSize = useCallback(() => {
    if (!ref?.current) return;
    setOverflowing(ref.current.scrollWidth > ref.current.clientWidth);
  }, [ref]);

  useEffect(() => {
    const element = ref?.current;
    if (!element) return;

    // Call compare size on component mount
    compareSize();

    // Observe the underlying DOM element passed in thru the ref parameter.
    // Whenever it changes, we want to re-evaluate whether the element is
    // overflowing.
    const observer = new MutationObserver(compareSize);
    observer.observe(element, {
      childList: true,
      characterData: true,
      subtree: true,
      attributes: true,
    });

    // Stop observing this DOM element when it the component holding the ref unmounts.
    return () => observer.disconnect();
  }, [compareSize, ref]);

  return overflowing;
};
