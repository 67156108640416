import { forwardRef } from "react";
import {
  mergeProps,
  useFocusRing,
  useTableColumnHeader,
  useTableSelectAllCheckbox,
  VisuallyHidden,
} from "react-aria";

import { useDomRef } from "../../../hooks/useDomRef";
import { cn } from "../../../utils/cn";
import { filterDOMProps } from "../../../utils/filterDomProps";
import { Checkbox } from "../../checkbox/Checkbox";

import type { GridNode } from "@react-types/grid";
import type { HTMLAttributes } from "react";
import type { SelectionMode, TableState } from "react-stately";
import type { CheckboxProps } from "../../checkbox/Checkbox";
import type { TableColumnProps } from "../TableColumn";
import type { TableClassNames, TableSlots } from "../tableVariants";

interface TableSelectAllCellProps<T = object> extends HTMLAttributes<HTMLTableCellElement> {
  node: GridNode<T>;
  state: TableState<T>;
  selectionMode?: SelectionMode;
  checkboxProps?: CheckboxProps;
  slots: TableSlots;
  classNames?: TableClassNames;
}

export const TableSelectAllCell = forwardRef<HTMLTableCellElement, TableSelectAllCellProps>(
  (
    { node, state, selectionMode, checkboxProps, className, slots, classNames, ...otherProps },
    ref
  ) => {
    const domRef = useDomRef(ref);

    const { columnHeaderProps } = useTableColumnHeader({ node }, state, domRef);
    const { isFocusVisible, focusProps } = useFocusRing();
    const {
      checkboxProps: { "aria-label": ariaLabel, onChange, ...otherCheckboxProps },
    } = useTableSelectAllCheckbox(state);

    const columnProps: TableColumnProps<unknown> = node.props;

    return (
      <th
        ref={domRef}
        data-focus-visible={isFocusVisible}
        {...mergeProps(
          columnHeaderProps,
          focusProps,
          filterDOMProps(columnProps),
          filterDOMProps(otherProps)
        )}
        className={slots.th({ className: cn(classNames?.th, className, columnProps.className) })}
      >
        {selectionMode === "single" ? (
          <VisuallyHidden>{ariaLabel}</VisuallyHidden>
        ) : (
          <Checkbox onValueChange={onChange} {...mergeProps(checkboxProps, otherCheckboxProps)} />
        )}
      </th>
    );
  }
);
TableSelectAllCell.displayName = "Table.SelectAllCell";
