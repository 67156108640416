import { debounce } from "lodash";
import { useCallback, useLayoutEffect, useRef } from "react";

export interface UseInfiniteScrollProps {
  /**
   * Whether the infinite scroll is enabled.
   * @default true
   */
  isEnabled?: boolean;
  /**
   * Whether there are more items to load. The observer will disconnect when there are no more items to load.
   */
  hasMore?: boolean;
  /**
   * The distance in pixels before the end of the items that will trigger a call to load more.
   * @default 250
   */
  distance?: number;
  /**
   * Use loader element for the scroll detection.
   */
  shouldUseLoader?: boolean;
  /**
   * Callback to load more items.
   */
  onLoadMore?: () => void;
}

export function useInfiniteScroll<
  ScrollContainerElement extends HTMLElement = HTMLElement,
  LoaderElement extends HTMLElement = HTMLElement,
>(props: UseInfiniteScrollProps = {}) {
  const {
    hasMore = true,
    distance = 250,
    isEnabled = true,
    shouldUseLoader = true,
    onLoadMore,
  } = props;

  const scrollContainerRef = useRef<ScrollContainerElement>(null);
  const loaderRef = useRef<LoaderElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const isLoadingRef = useRef(false);

  const loadMore = useCallback(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (!isLoadingRef.current && hasMore && onLoadMore) {
      isLoadingRef.current = true;
      onLoadMore();
      timer = setTimeout(() => {
        isLoadingRef.current = false;
      }, 100); // Debounce time to prevent multiple calls
    }

    return () => clearTimeout(timer);
  }, [hasMore, onLoadMore]);

  useLayoutEffect(() => {
    const scrollContainerElement = scrollContainerRef.current;

    if (!isEnabled || !scrollContainerElement || !hasMore) return;

    // If using the loader element, load more content when it comes into view
    if (shouldUseLoader) {
      const loaderElement = loaderRef.current;

      if (!loaderElement) return;

      const observer = new IntersectionObserver(
        entries => {
          const [entry] = entries;

          if (entry.isIntersecting) {
            loadMore();
          }
        },
        {
          root: scrollContainerElement,
          rootMargin: `0px 0px ${distance}px 0px`,
          threshold: 0.1,
        }
      );

      observer.observe(loaderElement);
      observerRef.current = observer;

      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    }

    // Otherwise load more content when we're close to the bottom of the scroll container
    const debouncedCheckIfNearBottom = debounce(() => {
      if (
        scrollContainerElement.scrollHeight - scrollContainerElement.scrollTop <=
        scrollContainerElement.clientHeight + distance
      ) {
        loadMore();
      }
    }, 100);

    scrollContainerElement.addEventListener("scroll", debouncedCheckIfNearBottom);

    return () => {
      scrollContainerElement.removeEventListener("scroll", debouncedCheckIfNearBottom);
    };
  }, [hasMore, distance, isEnabled, shouldUseLoader, loadMore]);

  return [loaderRef, scrollContainerRef] as const;
}

export type UseInfiniteScrollReturn = ReturnType<typeof useInfiniteScroll>;
