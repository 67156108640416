import { forwardRef } from "react";

import { cn } from "../../utils/cn";

import type { ForwardRefComponentWithSubcomponents } from "../../utils/types";

// Top level container for filters and sort menu
export const FilterSortContainer = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn("flex w-full justify-between gap-4", className)} {...props} />
    );
  }
) as ForwardRefComponentWithSubcomponents<HTMLDivElement, { Filters: typeof Filters }>;

FilterSortContainer.displayName = "FilterSortContainer";

// Container for filters, should go inside FilterSortContainer
const Filters = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn("flex grow flex-wrap gap-2", className)} {...props} />;
  }
);
Filters.displayName = "Filters";
FilterSortContainer.Filters = Filters;
