import { forwardRef } from "react";

import { cn } from "../../utils/cn";
import { Heading } from "../typography/Heading";
import { useAlertContext } from "./context";

import type { ComponentPropsWithoutRef } from "react";

export const AlertTitle = forwardRef<HTMLHeadingElement, ComponentPropsWithoutRef<"h5">>(
  ({ className, ...props }, ref) => {
    const { slots, size, classNames } = useAlertContext();

    return (
      <Heading
        level={size === "sm" ? 6 : 5}
        className={slots.title({ className: cn(className, classNames?.title) })}
        ref={ref}
      >
        {props.children}
      </Heading>
    );
  }
);

AlertTitle.displayName = "Alert.Title";
