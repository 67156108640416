import { forwardRef } from "react";

import { cn } from "../../utils/cn";
import { Heading } from "../typography/Heading";
import { useModalContext } from "./context";

import type { HeadingProps } from "../typography/Heading";

export const ModalTitle = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, ...props }, ref) => {
    const { slots, classNames } = useModalContext();

    return (
      <Heading
        ref={ref}
        className={slots.title({ className: cn(className, classNames?.title) })}
        level={4}
        {...props}
      />
    );
  }
);
ModalTitle.displayName = "Modal.Title";
