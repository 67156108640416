import { forwardRef } from "react";

import { cn } from "../../utils/cn";
import { Pagination } from "../pagination/Pagination";
import { Skeleton } from "../skeleton/Skeleton";

import type {
  ComponentPropsWithoutRef,
  FC,
  HTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
} from "react";
import type {
  ComponentWithAttachedSkeletonComponent,
  ForwardRefComponentWithSubcomponents,
} from "../../utils/types";
import type { PaginationProps } from "../pagination/Pagination";

interface Props extends ComponentPropsWithoutRef<"table"> {
  containerClassName?: string;
}
export const RawTable = forwardRef<HTMLTableElement, Props>(
  ({ className, containerClassName, ...props }, ref) => (
    <div
      className={cn(
        "w-full overflow-auto rounded border border-solid border-gray-100 bg-white @container",
        containerClassName
      )}
    >
      <table ref={ref} className={cn("w-full caption-bottom text-sm", className)} {...props} />
    </div>
  )
) as ForwardRefComponentWithSubcomponents<
  HTMLTableElement,
  {
    Header: typeof TableHeader;
    Body: typeof TableBody;
    Footer: typeof TableFooter;
    Row: typeof TableRow;
    Head: typeof TableHead;
    Cell: typeof TableCell;
    Caption: typeof TableCaption;
    Pagination: typeof TablePagination;
  },
  Props
>;
RawTable.displayName = "RawTable";

const TableHeader = forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn(
      "bg-gray-20",
      "[&_tr]:border-b",
      "[&_tr]:border-solid",
      "[&_tr]:border-t-0",
      "[&_tr]:border-l-0",
      "[&_tr]:border-r-0",
      "[&_tr]:border-b-gray-100",
      className
    )}
    {...props}
  />
));
TableHeader.displayName = "RawTable.Header";
RawTable.Header = TableHeader;

const TableBody = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tbody ref={ref} className={cn("[&_tr:last-child]:border-0", className)} {...props} />
  )
);
TableBody.displayName = "RawTable.Body";
RawTable.Body = TableBody;

const TableFooter = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tfoot
      ref={ref}
      className={cn(
        "font-medium",
        "border-t",
        "border-b-0",
        "border-l-0",
        "border-r-0",
        "border-solid",
        "border-gray-100",
        "[&_tr]:border-0",
        className
      )}
      {...props}
    />
  )
);
TableFooter.displayName = "RawTable.Footer";
RawTable.Footer = TableFooter;

const TableRow = forwardRef<HTMLTableRowElement, HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => (
    <tr
      ref={ref}
      className={cn(
        "border-b",
        "border-t-0",
        "border-l-0",
        "border-r-0",
        "border-solid",
        "border-gray-100",
        "transition-colors",
        "data-[state=selected]:bg-muted",
        className
      )}
      {...props}
    />
  )
);
TableRow.displayName = "RawTable.Row";
RawTable.Row = TableRow;

const TableHead = forwardRef<HTMLTableCellElement, ThHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <th
      ref={ref}
      className={cn(
        "p-3",
        "text-left",
        "align-middle",
        "font-semibold",
        "leading-6",
        "[&:has([role=checkbox])]:pr-0",
        "relative",
        "after:content-['']",
        "after:absolute",
        "after:w-px",
        "after:right-0",
        "after:top-3",
        "after:bottom-3",
        "after:bg-gray-100",
        "last:after:content-none",
        className
      )}
      {...props}
    />
  )
);
TableHead.displayName = "RawTable.Head";
RawTable.Head = TableHead;

const TableCell = forwardRef<HTMLTableCellElement, TdHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <td
      ref={ref}
      className={cn(
        "overflow-hidden text-ellipsis p-3 align-middle text-sm leading-6 [&:has([role=checkbox])]:pr-0",
        className
      )}
      {...props}
    />
  )
) as ComponentWithAttachedSkeletonComponent<
  HTMLTableCellElement,
  TdHTMLAttributes<HTMLTableCellElement>
>;
TableCell.displayName = "RawTable.Cell";
RawTable.Cell = TableCell;

const TableCellSkeleton: FC<HTMLAttributes<HTMLTableCellElement>> = ({ className, ...props }) => (
  <td className={cn("px-2 py-3", className)} {...props}>
    <Skeleton className="h-8 w-full" />
  </td>
);
TableCellSkeleton.displayName = "RawTable.Cell.Skeleton";
TableCell.Skeleton = TableCellSkeleton;

const TableCaption = forwardRef<HTMLTableCaptionElement, HTMLAttributes<HTMLTableCaptionElement>>(
  ({ className, ...props }, ref) => (
    <caption ref={ref} className={cn("text-muted-foreground mt-4 text-sm", className)} {...props} />
  )
);
TableCaption.displayName = "RawTable.Caption";
RawTable.Caption = TableCaption;

const TablePagination: FC<PaginationProps> & { Skeleton: FC } = props => (
  <TableCaption
    className={cn(
      "py-1.5",
      "pl-3",
      "pr-1.5",
      "mt-0",
      "border-t",
      "border-b-0",
      "border-r-0",
      "border-l-0",
      "border-solid",
      "border-gray-100"
    )}
  >
    <Pagination {...props} />
  </TableCaption>
);
TablePagination.displayName = "RawTable.Pagination";
RawTable.Pagination = TablePagination;

const TablePaginationSkeleton: FC = () => (
  <TableCaption
    className={cn(
      "py-1.5",
      "px-2",
      "mt-0",
      "border-t",
      "border-b-0",
      "border-r-0",
      "border-l-0",
      "border-solid",
      "border-gray-100"
    )}
  >
    <Pagination.Skeleton />
  </TableCaption>
);
TablePaginationSkeleton.displayName = "RawTable.Pagination.Skeleton";
TablePagination.Skeleton = TablePaginationSkeleton;
