import { forwardRef } from "react";

import { cn } from "../../utils/cn";
import { useAlertContext } from "./context";

import type { ComponentPropsWithoutRef } from "react";

export const AlertActions = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    const { slots, classNames } = useAlertContext();

    return (
      <div className={slots.actions({ className: cn(className, classNames?.actions) })} ref={ref}>
        {props.children}
      </div>
    );
  }
);

AlertActions.displayName = "Alert.Actions";
