import useResizeObserver from "@react-hook/resize-observer";
import { Item } from "@react-stately/collections";
import { forwardRef, useEffect } from "react";
import { tv } from "tailwind-variants";

import { useDomRef } from "../../hooks/useDomRef";

import type { ListState } from "@react-stately/list";
import type { ItemProps, Node } from "@react-types/shared";
import type { ComponentPropsWithoutRef, ReactElement } from "react";
import type { EmptyObject } from "@skydio/core";

export const overflowItemVariants = tv({
  base: "flex items-center",
  variants: {
    hidden: {
      true: "pointer-events-none absolute h-0 overflow-y-hidden opacity-0",
      false: "opacity-100",
    },
  },
});

export type OverflowItemProps<T> = ItemProps<T>;

// This component should be used as a child of an Overflow component
export const OverflowItem = Item as <T extends object>(props: OverflowItemProps<T>) => ReactElement;

interface BaseInternalProps<T> extends OverflowItemProps<T> {
  item: Node<T>;
  state: ListState<T>;
  registerWidth: (width: number | null) => void;
  hidden: boolean;
  order: number;
}

export type OverflowItemInternalProps<T = EmptyObject> = BaseInternalProps<T> &
  ComponentPropsWithoutRef<"div">;

// This component gets rendered internally by the Overflow component
export const OverflowItemInternal = forwardRef<HTMLDivElement, OverflowItemInternalProps>(
  ({ item, state, registerWidth, hidden, order, className, ...props }, ref) => {
    const domRef = useDomRef(ref);

    useResizeObserver(domRef, ({ contentRect }) => {
      registerWidth(contentRect.width);
    });

    useEffect(
      () => () => {
        registerWidth(null);
      },
      []
    );

    return (
      <div
        {...props}
        className={overflowItemVariants({ hidden, className })}
        style={{ order }}
        aria-hidden={hidden}
        ref={domRef}
      >
        {item.rendered}
      </div>
    );
  }
);
OverflowItemInternal.displayName = "OverflowItemInternal";
