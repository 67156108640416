/**
 * StandalonePopover
 *
 * This is a popover that is not tied to a trigger, used for the select/combobox components.
 *
 * @internal
 */

import { DismissButton, Overlay } from "@react-aria/overlays";
import { mergeProps } from "@react-aria/utils";
import { motion } from "framer-motion";
import { forwardRef, useMemo } from "react";

import { getTransformOrigins } from "../../utils/overlay";
import { fade, scaleSpringOpacity } from "../../utils/transitionVariants";
import { usePopover } from "./usePopover";

import type { HTMLMotionProps } from "framer-motion";
import type { UsePopoverProps, UsePopoverReturn } from "./usePopover";

export type StandalonePopoverProps = UsePopoverProps;

type StandalonePopoverWrapperProps = {
  children: React.ReactNode;
  disableAnimation: boolean;
  placement: UsePopoverReturn["placement"];
  motionProps?: UsePopoverProps["motionProps"];
} & React.HTMLAttributes<HTMLDivElement>;

const StandalonePopoverWrapper = ({
  children,
  motionProps,
  placement,
  disableAnimation,
  style = {},
  ...otherProps
}: StandalonePopoverWrapperProps) => {
  return disableAnimation ? (
    <div {...otherProps}>{children}</div>
  ) : (
    <motion.div
      animate="enter"
      exit="exit"
      initial="initial"
      style={{
        ...style,
        ...getTransformOrigins(placement === "center" ? "top" : placement),
      }}
      variants={scaleSpringOpacity}
      {...mergeProps(otherProps, motionProps)}
    >
      {children}
    </motion.div>
  );
};

const StandalonePopover = forwardRef<HTMLDivElement, StandalonePopoverProps>((props, ref) => {
  const {
    state,
    children,
    placement,
    backdrop,
    portalContainer,
    disableAnimation,
    motionProps,
    isNonModal,
    popoverProps,
    backdropProps,
    dialogProps,
    contentProps,
  } = usePopover({
    ...props,
    // avoid closing the popover when navigating with the keyboard
    shouldCloseOnInteractOutside: undefined,
    ref,
  });

  const backdropContent = useMemo(() => {
    if (backdrop === "transparent") {
      return null;
    }

    if (disableAnimation) {
      return <div {...backdropProps} />;
    }

    return (
      <motion.div
        animate="enter"
        exit="exit"
        initial="exit"
        variants={fade}
        {...(backdropProps as HTMLMotionProps<"div">)}
      />
    );
  }, [backdrop, disableAnimation, backdropProps]);

  return (
    <Overlay portalContainer={portalContainer}>
      {!isNonModal && backdropContent}
      <div {...popoverProps}>
        <StandalonePopoverWrapper
          disableAnimation={disableAnimation}
          motionProps={motionProps}
          placement={placement}
          tabIndex={-1}
          {...dialogProps}
        >
          {!isNonModal && <DismissButton onDismiss={state.close} />}
          <div {...contentProps}>{children}</div>
          <DismissButton onDismiss={state.close} />
        </StandalonePopoverWrapper>
      </div>
    </Overlay>
  );
});

StandalonePopover.displayName = "StandalonePopover";

export default StandalonePopover;
