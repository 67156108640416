import { useListState } from "@react-stately/list";

import { firstKeyFromSet } from "../utils/collections";

import type { ListState } from "@react-stately/list";
import type {
  CollectionStateBase,
  Key,
  MultipleSelection,
  Node,
  Selection,
  SelectionMode,
} from "@react-types/shared";

export interface OptionalSingleSelectProps {
  /** The currently selected key; this will only used when selectionMode is "single" */
  selectedKey?: Key | null;
  /** Callback invoked when the selected key changes, only when selectionMode is "single" */
  onSingleSelectionChange?: (key: Key | null) => any;
}

export interface MultiSelectListProps<T>
  extends CollectionStateBase<T>,
    MultipleSelection,
    OptionalSingleSelectProps {
  /** Filter function to generate a filtered list of nodes. */
  filter?: (nodes: Iterable<Node<T>>) => Iterable<Node<T>>;
}

export interface MultiSelectListState<T> extends ListState<T> {
  /** The keys for the currently selected items. */
  readonly selectedKeys: Set<Key>;
  /** Sets the selected keys. */
  setSelectedKeys(keys: Iterable<Key>): void;
  /** The value of the currently selected items. */
  readonly selectedItems: Node<T>[] | null;
  /* The type of selection. */
  selectionMode: SelectionMode;
}

export function useMultiSelectListState<T extends object>(
  props: MultiSelectListProps<T>
): MultiSelectListState<T> {
  const {
    collection,
    disabledKeys,
    selectionManager,
    selectionManager: { setSelectedKeys, selectedKeys, selectionMode },
  } = useListState<T>({
    ...props,
    selectedKeys:
      props.selectedKey !== undefined && isSingleSelect(props)
        ? new Set(props.selectedKey !== null ? [props.selectedKey] : [])
        : props.selectedKeys,
    onSelectionChange: keys => {
      if (props.onSelectionChange) {
        props.onSelectionChange(keys);
      }

      if (props.onSingleSelectionChange && isSingleSelect(props)) {
        props.onSingleSelectionChange(firstKeyFromSelection(keys) ?? null);
      }
    },
  });

  const missingKeys: Key[] = [];

  const selectedItems = (
    selectedKeys.size !== 0
      ? Array.from(selectedKeys)
          .map(key => {
            const item = collection.getItem(key);

            if (!item) {
              missingKeys.push(key);
            }

            return item;
          })
          // Remove undefined values when some keys are not present in the collection
          .filter(Boolean)
      : null
  ) as Node<T>[] | null;

  if (missingKeys.length) {
    // eslint-disable-next-line no-console
    console.warn(
      `Select: Keys "${missingKeys.join(
        ", "
      )}" passed to "selectedKeys" are not present in the collection.`
    );
  }

  return {
    collection,
    disabledKeys,
    selectionManager,
    selectionMode,
    selectedKeys,
    setSelectedKeys: setSelectedKeys.bind(selectionManager),
    selectedItems,
  };
}

const isSingleSelect = (props: MultiSelectListProps<any>) =>
  !props.selectionMode || props.selectionMode === "single";

export const firstKeyFromSelection = (keys?: Selection) =>
  keys && (keys === "all" ? undefined : firstKeyFromSet(keys));
export const firstKeyFromIterable = (keys?: Iterable<Key>) =>
  keys && firstKeyFromSet(new Set(keys));
