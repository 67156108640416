/**
 * NOTE(sam): This is adapted from useComboboxState.ts in @react-stately/combobox, which only
 * supports single selection. Most of the action handlers here have been augmented to support
 * multiple selection as well.
 */

import { getChildNodes } from "@react-stately/collections";
import { useFormValidationState } from "@react-stately/form";
import { ListCollection } from "@react-stately/list";
import { useMenuTriggerState } from "@react-stately/menu";
import { useControlledState } from "@react-stately/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  firstKeyFromIterable,
  firstKeyFromSelection,
  useMultiSelectListState,
} from "../../hooks/useMultiSelectListState";
import { firstKeyFromSet } from "../../utils/collections";

import type { FormValidationState } from "@react-stately/form";
import type { MenuTriggerState } from "@react-stately/menu";
import type { MenuTriggerAction } from "@react-types/combobox";
import type {
  Collection,
  CollectionStateBase,
  FocusStrategy,
  Key,
  Node,
} from "@react-types/shared";
import type { MultiSelectListState } from "../../hooks/useMultiSelectListState";
import type { MultiSelectComboBoxProps } from "./types";

export interface MultiSelectState<T>
  extends MultiSelectListState<T>,
    MenuTriggerState,
    FormValidationState {
  /** Whether the select is currently focused. */
  readonly isFocused: boolean;
  /** Sets whether the select is focused. */
  setFocused(isFocused: boolean): void;
}

export interface MultiSelectComboBoxState<T> extends MultiSelectState<T>, FormValidationState {
  /** The current value of the combo box input. */
  inputValue: string;
  /** Sets the value of the combo box input. */
  setInputValue(value: string): void;
  /** Selects the currently focused item and updates the input value. */
  commit(): void;
  /** Opens the menu. */
  open(focusStrategy?: FocusStrategy | null, trigger?: MenuTriggerAction): void;
  /** Toggles the menu. */
  toggle(focusStrategy?: FocusStrategy | null, trigger?: MenuTriggerAction): void;
  /** Resets the input value to the previously selected item's text if any and closes the menu.  */
  revert(): void;
}

export type FilterFn = (textValue: string, inputValue: string, key: Key) => boolean;

export interface MultiSelectComboBoxStateOptions<T>
  extends Omit<MultiSelectComboBoxProps<T>, "children">,
    CollectionStateBase<T> {
  /** The filter function used to determine if a option should be included in the combo box list. */
  defaultFilter?: FilterFn;
  /** Whether the combo box allows the menu to be open when the collection is empty. */
  allowsEmptyCollection?: boolean;
  /** Whether the combo box menu should close on blur. */
  shouldCloseOnBlur?: boolean;
}

/**
 * Provides state management for a combobox component. Handles building a collection
 * of items from props and manages the option selection state of the combobox. In addition, it tracks the input value,
 * focus state, and other properties of the combo box.
 */
export function useMultiSelectComboBoxState<T extends object>(
  props: MultiSelectComboBoxStateOptions<T>
): MultiSelectComboBoxState<T> {
  const {
    defaultFilter,
    menuTrigger = "input",
    allowsEmptyCollection = false,
    allowsCustomValue, // TODO(sam): implement this
    shouldCloseOnBlur = true,
  } = props;

  const [showAllItems, setShowAllItems] = useState(false);
  const [isFocused, setFocusedState] = useState(false);

  const {
    collection,
    selectionManager,
    selectedKeys,
    setSelectedKeys,
    selectedItems,
    disabledKeys,
    selectionMode,
  } = useMultiSelectListState({
    ...props,
    onSelectionChange: keys => {
      if (props.onSelectionChange) {
        if (keys === "all") {
          props.onSelectionChange(new Set(collection.getKeys()));
        } else {
          props.onSelectionChange(keys);
        }
      }

      // If key is the same, reset the inputValue and close the menu
      // (scenario: user clicks on already selected option)
      if (
        props.selectionMode === "single" &&
        firstKeyFromSelection(keys) === firstKeyFromSet(selectedKeys)
      ) {
        resetInputValue();
        closeMenu();
      } else if (props.selectionMode === "multiple") {
        // Clear input when we select something in multiselect mode but don't close the menu
        resetInputValue();
      }
    },
    items: props.items ?? props.defaultItems,
  });

  const [inputValue, setInputValue] = useControlledState(
    props.inputValue,
    props.defaultInputValue ?? collection.getItem(firstKeyFromSet(selectedKeys)!)?.textValue ?? "",
    props.onInputChange
  );

  // Preserve original collection so we can show all items on demand
  const originalCollection = collection;
  const filteredCollection = useMemo(
    () =>
      // No default filter if items are controlled.
      props.items != null || !defaultFilter
        ? collection
        : filterCollection(collection, inputValue, defaultFilter),
    [collection, inputValue, defaultFilter, props.items]
  );
  const [lastCollection, setLastCollection] = useState(filteredCollection);

  // Track what action is attempting to open the menu
  const menuOpenTrigger = useRef("focus" as MenuTriggerAction);
  const onOpenChange = (open: boolean) => {
    if (props.onOpenChange) {
      props.onOpenChange(open, open ? menuOpenTrigger.current : undefined);
    }

    selectionManager.setFocused(open);
    if (!open) {
      selectionManager.setFocusedKey(null);
    }
  };

  const triggerState = useMenuTriggerState({
    ...props,
    onOpenChange,
    isOpen: undefined,
    defaultOpen: undefined,
  });
  const open = (focusStrategy?: FocusStrategy, trigger?: MenuTriggerAction) => {
    const displayAllItems =
      trigger === "manual" || (trigger === "focus" && menuTrigger === "focus");
    // Prevent open operations from triggering if there is nothing to display
    // Also prevent open operations from triggering if items are uncontrolled but defaultItems is empty, even if displayAllItems is true.
    // This is to prevent comboboxes with empty defaultItems from opening but allow controlled items comboboxes to open even if the inital list is empty (assumption is user will provide swap the empty list with a base list via onOpenChange returning `menuTrigger` manual)
    if (
      allowsEmptyCollection ||
      filteredCollection.size > 0 ||
      (displayAllItems && originalCollection.size > 0) ||
      props.items
    ) {
      if (displayAllItems && !triggerState.isOpen && props.items === undefined) {
        // Show all items if menu is manually opened. Only care about this if items are undefined
        setShowAllItems(true);
      }

      if (trigger) {
        menuOpenTrigger.current = trigger;
      }
      triggerState.open(focusStrategy);
    }
  };

  const toggle = (focusStrategy?: FocusStrategy, trigger?: MenuTriggerAction) => {
    const displayAllItems =
      trigger === "manual" || (trigger === "focus" && menuTrigger === "focus");
    // If the menu is closed and there is nothing to display, early return so toggle isn't called to prevent extraneous onOpenChange
    if (
      !(
        allowsEmptyCollection ||
        filteredCollection.size > 0 ||
        (displayAllItems && originalCollection.size > 0) ||
        props.items
      ) &&
      !triggerState.isOpen
    ) {
      return;
    }

    if (displayAllItems && !triggerState.isOpen && props.items === undefined) {
      // Show all items if menu is toggled open. Only care about this if items are undefined
      setShowAllItems(true);
    }

    // Only update the menuOpenTrigger if menu is currently closed
    if (trigger && !triggerState.isOpen) {
      menuOpenTrigger.current = trigger;
    }

    toggleMenu(focusStrategy);
  };

  const updateLastCollection = useCallback(() => {
    setLastCollection(showAllItems ? originalCollection : filteredCollection);
  }, [showAllItems, originalCollection, filteredCollection]);

  // If menu is going to close, save the current collection so we can freeze the displayed collection when the
  // user clicks outside the popover to close the menu. Prevents the menu contents from updating as the menu closes.
  const toggleMenu = useCallback(
    (focusStrategy?: FocusStrategy) => {
      if (triggerState.isOpen) {
        updateLastCollection();
      }

      triggerState.toggle(focusStrategy);
    },
    [triggerState, updateLastCollection]
  );

  const closeMenu = useCallback(() => {
    if (triggerState.isOpen) {
      updateLastCollection();
      triggerState.close();
    }
  }, [triggerState, updateLastCollection]);

  const [lastValue, setLastValue] = useState(inputValue);
  const resetInputValue = () => {
    setLastValue("");
    setInputValue("");
  };

  const lastSelectedKey = useRef<Key | undefined | null>(
    firstKeyFromIterable(props.selectedKeys ?? props.defaultSelectedKeys) ?? null
  );
  const lastSelectedKeyText = useRef(
    collection.getItem(firstKeyFromSet(selectedKeys)!)?.textValue ?? ""
  );
  // intentional omit dependency array, want this to happen on every render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Open and close menu automatically when the input value changes if the input is focused,
    // and there are items in the collection or allowEmptyCollection is true.
    if (
      isFocused &&
      (filteredCollection.size > 0 || allowsEmptyCollection) &&
      !triggerState.isOpen &&
      inputValue !== lastValue &&
      menuTrigger !== "manual"
    ) {
      open(undefined, "input");
    }

    // Close the menu if the collection is empty. Don't close menu if filtered collection size is 0
    // but we are currently showing all items via button press
    if (
      !showAllItems &&
      !allowsEmptyCollection &&
      triggerState.isOpen &&
      filteredCollection.size === 0
    ) {
      closeMenu();
    }

    // Clear focused key when input value changes and display filtered collection again.
    if (inputValue !== lastValue) {
      selectionManager.setFocusedKey(null);
      setShowAllItems(false);
      setLastValue(inputValue);
    }

    if (selectionMode === "single") {
      const selectedKey = firstKeyFromSet(selectedKeys);

      // Close when an item is selected.
      if (selectedKey != null && selectedKey !== lastSelectedKey.current) {
        closeMenu();
      }

      // If the selectedKey changed, update the input value.
      if (selectedKey !== lastSelectedKey.current) {
        resetInputValue();
      } else if (lastValue !== inputValue) {
        setLastValue(inputValue);
      }

      // Update the inputValue if the selected item's text changes from its last tracked value.
      // This is to handle cases where a selectedKey is specified but the items aren't available (async loading) or the selected item's text value updates.
      // Only reset if the user isn't currently within the field so we don't erroneously modify user input.
      // If inputValue is controlled, it is the user's responsibility to update the inputValue when items change.
      const selectedItemText = collection.getItem(selectedKey!)?.textValue ?? "";
      if (
        !isFocused &&
        selectedKey != null &&
        props.inputValue === undefined &&
        selectedKey === lastSelectedKey.current
      ) {
        if (lastSelectedKeyText.current !== selectedItemText) {
          setLastValue(selectedItemText);
          setInputValue(selectedItemText);
        }
      }

      lastSelectedKey.current = selectedKey;
      lastSelectedKeyText.current = selectedItemText;
    }
  });

  const validation = useFormValidationState({
    ...props,
    value: useMemo(() => ({ inputValue, selectedKeys }), [inputValue, selectedKeys]),
  });

  const revert = () => {
    resetInputValue();
    closeMenu();
  };

  const commit = () => {
    if (triggerState.isOpen && selectionManager.focusedKey != null) {
      // Reset inputValue and close menu here if the selected key is already the focused key. Otherwise
      // fire onSelectionChange to allow the application to control the closing.
      if (
        selectionMode === "single" &&
        firstKeyFromSet(selectedKeys) === selectionManager.focusedKey
      ) {
        revert();
      } else {
        selectionManager.select(selectionManager.focusedKey);
        // setSelectedKeys(selectionManager.focusedKey);
      }
    } else {
      revert();
    }
  };

  const valueOnFocus = useRef(inputValue);
  const setFocused = (isFocused: boolean) => {
    if (isFocused) {
      valueOnFocus.current = inputValue;
      if (menuTrigger === "focus") {
        open(undefined, "focus");
      }
    } else {
      if (shouldCloseOnBlur) {
        revert();
      }

      if (inputValue !== valueOnFocus.current) {
        validation.commitValidation();
      }
    }

    setFocusedState(isFocused);
  };

  const displayedCollection = useMemo(() => {
    if (triggerState.isOpen) {
      if (showAllItems) {
        return originalCollection;
      } else {
        return filteredCollection;
      }
    } else {
      return lastCollection;
    }
  }, [triggerState.isOpen, originalCollection, filteredCollection, showAllItems, lastCollection]);

  return {
    ...validation,
    ...triggerState,
    toggle,
    open,
    close: revert,
    selectionManager,
    selectedKeys,
    setSelectedKeys,
    disabledKeys,
    isFocused,
    setFocused,
    selectedItems,
    collection: displayedCollection,
    inputValue,
    setInputValue,
    commit,
    revert,
    selectionMode,
  };
}

function filterCollection<T extends object>(
  collection: Collection<Node<T>>,
  inputValue: string,
  filter: FilterFn
): Collection<Node<T>> {
  return new ListCollection(filterNodes(collection, collection, inputValue, filter));
}

function filterNodes<T>(
  collection: Collection<Node<T>>,
  nodes: Iterable<Node<T>>,
  inputValue: string,
  filter: FilterFn
): Iterable<Node<T>> {
  const filteredNode = [];
  for (const node of nodes) {
    if (node.type === "section" && node.hasChildNodes) {
      const filtered = filterNodes(collection, getChildNodes(node, collection), inputValue, filter);
      if ([...filtered].some(node => node.type === "item")) {
        filteredNode.push({ ...node, childNodes: filtered });
      }
    } else if (node.type === "item" && filter(node.textValue, inputValue, node.key)) {
      filteredNode.push({ ...node });
    } else if (node.type !== "item") {
      filteredNode.push({ ...node });
    }
  }
  return filteredNode;
}
