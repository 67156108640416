import { forwardRef } from "react";

import { cn } from "../../utils/cn";
import { Button } from "../button/Button";
import { Heading } from "../typography/Heading";
import { Text } from "../typography/Text";

import type { ComponentPropsWithoutRef } from "react";
import type { ForwardRefComponentWithSubcomponents } from "../../utils/types";
import type { ButtonProps } from "../button/Button";

export const EmptyPage = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn("flex flex-col items-center gap-y-2", className)} {...props} />
    );
  }
) as ForwardRefComponentWithSubcomponents<
  HTMLDivElement,
  {
    Icon: typeof EmptyPageIcon;
    Title: typeof EmptyPageTitle;
    Subtitle: typeof EmptyPageSubtitle;
    Button: typeof EmptyPageButton;
  }
>;
EmptyPage.displayName = "EmptyPage";

const EmptyPageIcon = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          "mb-2",
          "flex",
          "size-24",
          "items-center",
          "justify-center",
          "rounded-full",
          "bg-blue-50",
          "text-4xl",
          "text-blue-400",
          "dark:bg-gray-800",
          className
        )}
        {...props}
      />
    );
  }
);
EmptyPageIcon.displayName = "EmptyPage.Icon";
EmptyPage.Icon = EmptyPageIcon;

const EmptyPageTitle = forwardRef<HTMLHeadingElement, ComponentPropsWithoutRef<"h3">>(
  ({ className, ...props }, ref) => {
    return <Heading level={3} className={cn("text-center", className)} {...props} ref={ref} />;
  }
);
EmptyPageTitle.displayName = "EmptyPage.Title";
EmptyPage.Title = EmptyPageTitle;

const EmptyPageSubtitle = forwardRef<HTMLParagraphElement, ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => {
    return (
      <Text
        ref={ref}
        className={cn("max-w-1/2 text-center", className)}
        emphasis="secondary"
        {...props}
      />
    );
  }
);
EmptyPageSubtitle.displayName = "EmptyPage.Subtitle";
EmptyPage.Subtitle = EmptyPageSubtitle;

const EmptyPageButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => (
    <Button ref={ref} variant="primary" className={cn("mt-2", className)} {...props} />
  )
);
EmptyPageButton.displayName = "EmptyPage.Button";
EmptyPage.Button = EmptyPageButton;
