import { forwardRef } from "react";

import { Detail } from "./Detail";

import type { HTMLAttributes } from "react";

const KeyValueDetail = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { keyName: string }
>(({ keyName, children, ...props }, ref) => (
  <Detail ref={ref} {...props}>
    <span className="mr-1 text-gray">{keyName}:</span>
    {children}
  </Detail>
));
KeyValueDetail.displayName = "KeyValueDetail";

export { KeyValueDetail };
