import { forwardRef, useMemo, useRef } from "react";
import { mergeProps, useFocusRing, useHover, useOption, usePress } from "react-aria";
import { Item } from "react-stately";
import { tv } from "tailwind-variants";

import { baseCheckbox } from "../checkbox/Checkbox";
import { CheckboxIcon } from "../checkbox/CheckboxIcon";
import { ListBoxSelectedIcon } from "./ListBoxSelectedIcon";

import type { FocusableProps, PressEvents } from "@react-types/shared";
import type { HTMLAttributes, ReactNode } from "react";
import type { AriaOptionProps } from "react-aria";
import type { ItemProps, ListState, Node } from "react-stately";
import type { VariantProps } from "tailwind-variants";
import type { EmptyObject } from "@skydio/core";

const listboxItem = tv({
  slots: {
    wrapper: [
      "flex",
      "group",
      "gap-2",
      "items-center",
      "relative",
      "px-2",
      "py-1.5",
      "w-full",
      "h-full",
      "box-border",
      "rounded",
      "subpixel-antialiased",
      "outline-none",
      "cursor-pointer",
      "transition-colors duration-200",
      "data-[hover=true]:bg-gray-50",
      "dark:data-[hover=true]:bg-gray-800",
      "data-[selectable=true]:data-[focus=true]:bg-gray-50",
      "dark:data-[selectable=true]:data-[focus=true]:bg-gray-800",
      "data-[selected=true]:!bg-blue-50",
      "dark:data-[selected=true]:!bg-blue-900",
      "data-[focus=true]:data-[selected=true]:!bg-blue-100",
      "dark:data-[focus=true]:data-[selected=true]:!bg-blue-800",
    ],
    content: "flex w-full flex-col items-start justify-center",
    title: [
      "flex-1",
      "truncate",
      "text-sm",
      "font-normal",
      "text-gray-900",
      "dark:text-gray-100",
      "group-data-[selected=true]:text-blue",
    ],
    description: "text-tiny w-full text-gray-500",
    selectedIcon: "size-3 flex-shrink-0 text-blue",
  },
  variants: {
    isDisabled: {
      true: {
        wrapper: "pointer-events-none opacity-50",
      },
    },
  },
});

type ListBoxItemVariantProps = VariantProps<typeof listboxItem>;

export interface ListBoxItemProps<T extends object = EmptyObject>
  extends ItemProps<T>,
    ListBoxItemVariantProps,
    AriaOptionProps,
    FocusableProps,
    PressEvents {
  description?: ReactNode;
  startContent?: ReactNode;
  endContent?: ReactNode;
  disableAnimation?: boolean;
}

/**
 * This component should be used as a child of a ListBox component.
 * It doesn't render any real markup itself but defines data to be parsed into the ListBox's
 * internal collection of items.
 */
export const ListBoxItem = Item as <T extends object>(props: ListBoxItemProps<T>) => JSX.Element;

interface BaseInternalProps<T extends object> extends ListBoxItemProps<T> {
  item: Node<T>;
  state: ListState<T>;
}

export type ListBoxItemInternalProps<T extends object = EmptyObject> = BaseInternalProps<T> &
  HTMLAttributes<HTMLLIElement>;

/**
 * @internal
 *
 * This component gets rendered internally by the ListBox component itself based on the provided data.
 */
export const ListBoxItemInternal = forwardRef<HTMLLIElement, ListBoxItemInternalProps>(
  (
    {
      className,
      item,
      state,
      description: descriptionProp,
      startContent,
      endContent,
      autoFocus,
      isDisabled: isDisabledProp,
      disableAnimation = false,
      onClick,
      onPress,
      ...props
    },
    _
  ) => {
    const domRef = useRef<HTMLLIElement>(null);

    const { key, rendered } = item;

    const isDisabled = state.disabledKeys.has(key) || isDisabledProp;
    const isSelectable = state.selectionManager.selectionMode !== "none";

    const { pressProps, isPressed } = usePress({
      ref: domRef,
      isDisabled,
      onPress,
    });

    const { isHovered, hoverProps } = useHover({
      isDisabled,
    });

    const { isFocusVisible, focusProps } = useFocusRing({ autoFocus });

    const {
      isFocused,
      isSelected,
      optionProps,
      labelProps,
      descriptionProps: ariaDescriptionProps,
    } = useOption({ key, isDisabled, "aria-label": props["aria-label"] }, state, domRef);

    const slots = listboxItem({ isDisabled });
    const checkboxSlots = baseCheckbox({ disableAnimation, size: "sm" });

    const itemProps = useMemo(
      () => ({
        ref: domRef,
        ...mergeProps({ onClick }, optionProps, focusProps, pressProps, hoverProps, props),
        "data-selectable": isSelectable,
        "data-focus": isFocused,
        "data-hover": isHovered,
        "data-disabled": isDisabled,
        "data-selected": isSelected,
        "data-pressed": isPressed,
        "data-focus-visible": isFocusVisible,
        className: slots.wrapper({ className }),
      }),
      [
        domRef,
        onClick,
        optionProps,
        focusProps,
        pressProps,
        hoverProps,
        props,
        isSelectable,
        isFocused,
        isDisabled,
        isSelected,
        isPressed,
        isFocusVisible,
        className,
        slots.wrapper,
      ]
    );

    const titleProps = useMemo(
      () => ({
        ...labelProps,
        "data-label": true,
        className: slots.title(),
      }),
      [labelProps, slots.title]
    );

    const descriptionProps = useMemo(
      () => ({
        ...ariaDescriptionProps,
        className: slots.description(),
      }),
      [ariaDescriptionProps, slots.description]
    );

    const checkboxIconProps = useMemo(
      () => ({
        "aria-hidden": true,
        "data-disabled": isDisabled,
        className: checkboxSlots.iconWrapper({ className: "mr-1" }),
      }),
      [isDisabled, checkboxSlots.iconWrapper]
    );

    const checkboxIconInnerProps = useMemo(
      () => ({
        className: checkboxSlots.icon(),
        isSelected,
        isIndeterminate: false,
        disableAnimation,
        "data-checked": String(isSelected),
      }),
      [isSelected, disableAnimation, checkboxSlots.icon]
    );

    const selectedIconProps = useMemo(
      () => ({
        "aria-hidden": true,
        "data-disabled": isDisabled,
        className: slots.selectedIcon(),
      }),
      [isDisabled, slots.selectedIcon]
    );

    return (
      <li {...itemProps}>
        {startContent}
        {isSelectable && state.selectionManager.selectionMode === "multiple" && (
          <span {...checkboxIconProps}>
            <CheckboxIcon {...checkboxIconInnerProps} />
          </span>
        )}
        {descriptionProp ? (
          <div className={slots.content()}>
            <span {...titleProps}>{rendered}</span>
            <span {...descriptionProps}>{descriptionProp}</span>
          </div>
        ) : (
          <span {...titleProps}>{rendered}</span>
        )}
        {isSelectable && state.selectionManager.selectionMode === "single" && (
          <span {...selectedIconProps}>
            <ListBoxSelectedIcon isSelected={isSelected} disableAnimation={disableAnimation} />
          </span>
        )}
        {endContent}
      </li>
    );
  }
);
ListBoxItemInternal.displayName = "ListBoxItemInternal";
