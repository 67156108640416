import { tv } from "tailwind-variants";

import { dataFocusVisible } from "../../utils/tailwindClasses";

import type { VariantProps } from "tailwind-variants";
import type { SlotsToClasses } from "../../utils/types";

export const cardVariants = tv({
  slots: {
    base: [
      "p-0",
      "flex",
      "flex-col",
      "relative",
      "outline-none",
      "box-border",
      "border",
      "border-solid",
      "border-gray-50",
      "dark:border-gray-800",
      "rounded-md",
      "bg-white",
      "dark:bg-gray-850",
      "overflow-hidden",
      "text-gray-850",
      "dark:text-white",
      "transition",
      ...dataFocusVisible,
    ],
    header: [
      "overflow-inherit",
      "color-inherit",
      "z-10",
      "flex",
      "gap-4",
      "w-full",
      "shrink-0",
      "items-center",
      "subpixel-antialiased",
      "justify-between",
    ],
    headerMain: "mr-auto flex flex-col gap-1 text-left",
    headerExtra: "flex gap-2",
    title: "",
    subtitle: "",
    body: [
      "relative",
      "flex",
      "flex-col",
      "flex-auto",
      "w-full",
      "h-auto",
      "break-words",
      "text-left",
      "overflow-y-auto",
      "subpixel-antialiased",
    ],
    footer: "color-inherit flex w-full justify-around overflow-hidden subpixel-antialiased",
  },
  variants: {
    size: {
      sm: {
        header: "p-2",
        body: "p-2",
        footer: "p-2",
      },
      md: {
        header: "p-4",
        body: "p-4",
        footer: "p-4",
      },
      lg: {
        header: "p-6",
        body: "p-6",
        footer: "p-6",
      },
      xl: {
        header: "p-8",
        body: "p-8",
        footer: "p-8",
      },
    },
    fullWidth: {
      true: {
        base: "w-full",
      },
    },
    isDisabled: {
      true: {
        base: "cursor-not-allowed opacity-70",
      },
    },
    isPressable: {
      true: {
        base: "cursor-pointer",
      },
    },
    isHoverable: {
      true: {
        base: "data-[hover=true]:bg-gray-20 dark:data-[hover=true]:bg-gray-800",
      },
    },
    shadow: {
      true: {
        base: "shadow-md",
      },
    },
  },
  compoundVariants: [
    {
      isPressable: true,
      className: "tap-highlight-transparent data-[pressed=true]:scale-[0.98]",
    },
  ],
  defaultVariants: {
    size: "md",
    shadow: false,
    fullWidth: false,
    isDisabled: false,
    isPressable: false,
    isHoverable: false,
  },
});

export type CardVariantProps = VariantProps<typeof cardVariants>;
export type CardSlots = ReturnType<typeof cardVariants>;
export type CardClassNames = SlotsToClasses<keyof CardSlots>;
