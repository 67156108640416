import { Overlay } from "@react-aria/overlays";
import { AnimatePresence } from "framer-motion";
import { Children, forwardRef } from "react";

import { popoverContext } from "./context";
import { PopoverContent } from "./PopoverContent";
import { PopoverTrigger } from "./PopoverTrigger";
import { usePopover } from "./usePopover";

import type { ReactNode } from "react";
import type { ForwardRefComponentWithSubcomponents } from "../../utils/types";
import type { UsePopoverProps } from "./usePopover";

export interface PopoverProps extends UsePopoverProps {
  /**
   * The content of the popover. It should be `PopoverTrigger` and `PopoverContent`
   */
  children: ReactNode[];
}

export const Popover = forwardRef<HTMLDivElement, PopoverProps>((props, ref) => {
  const { children, ...otherProps } = props;
  const context = usePopover({ ...otherProps, ref });

  const [trigger, content] = Children.toArray(children);

  const overlay = <Overlay portalContainer={context.portalContainer}>{content}</Overlay>;

  return (
    <popoverContext.Provider value={context}>
      {trigger}
      {context.disableAnimation && context.isOpen ? (
        overlay
      ) : (
        <AnimatePresence>{context.isOpen ? overlay : null}</AnimatePresence>
      )}
    </popoverContext.Provider>
  );
}) as ForwardRefComponentWithSubcomponents<
  HTMLDivElement,
  { Trigger: typeof PopoverTrigger; Content: typeof PopoverContent },
  PopoverProps
>;

Popover.displayName = "Popover";
Popover.Trigger = PopoverTrigger;
Popover.Content = PopoverContent;
