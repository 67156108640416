import { forwardRef, useMemo } from "react";
import { tv } from "tailwind-variants";

import { useDataScrollOverflow } from "../../hooks/useDataScrollOverflow";
import { useDomRef } from "../../hooks/useDomRef";

import type { HTMLAttributes } from "react";
import type { VariantProps } from "tailwind-variants";
import type { UseDataScrollOverflowProps } from "../../hooks/useDataScrollOverflow";

const verticalShadow = [
  "data-[top-scroll=true]:[mask-image:linear-gradient(0deg,#000_calc(100%_-_var(--scroll-shadow-size)),transparent)]",
  "data-[bottom-scroll=true]:[mask-image:linear-gradient(180deg,#000_calc(100%_-_var(--scroll-shadow-size)),transparent)]",
  "data-[top-bottom-scroll=true]:[mask-image:linear-gradient(#000,#000,transparent_0,#000_var(--scroll-shadow-size),#000_calc(100%_-_var(--scroll-shadow-size)),transparent)]",
];

const horizontalShadow = [
  "data-[left-scroll=true]:[mask-image:linear-gradient(270deg,#000_calc(100%_-_var(--scroll-shadow-size)),transparent)]",
  "data-[right-scroll=true]:[mask-image:linear-gradient(90deg,#000_calc(100%_-_var(--scroll-shadow-size)),transparent)]",
  "data-[left-right-scroll=true]:[mask-image:linear-gradient(to_right,#000,#000,transparent_0,#000_var(--scroll-shadow-size),#000_calc(100%_-_var(--scroll-shadow-size)),transparent)]",
];

export const scrollShadow = tv({
  base: [],
  variants: {
    orientation: {
      vertical: ["overflow-y-auto", ...verticalShadow],
      horizontal: ["overflow-x-auto", ...horizontalShadow],
    },
    hideScrollBar: {
      true: "scrollbar-hide",
      false: "",
    },
  },
  defaultVariants: {
    orientation: "vertical",
    hideScrollBar: true,
  },
});

export type ScrollShadowVariantProps = VariantProps<typeof scrollShadow>;

export interface ScrollShadowProps
  extends HTMLAttributes<HTMLDivElement>,
    Omit<UseDataScrollOverflowProps, "domRef">,
    ScrollShadowVariantProps {
  /**
   * The shadow size in pixels.
   * @default 40
   */
  size?: number;
}

export const ScrollShadow = forwardRef<HTMLDivElement, ScrollShadowProps>((props, ref) => {
  const {
    children,
    className,
    style,
    size = 40,
    offset = 0,
    visibility = "auto",
    isEnabled = true,
    onVisibilityChange,
    orientation,
    hideScrollBar,
    ...otherProps
  } = props;

  const domRef = useDomRef(ref);

  useDataScrollOverflow({
    domRef,
    offset,
    visibility,
    isEnabled,
    onVisibilityChange,
    updateDeps: [children],
    overflowCheck: props.orientation ?? "vertical",
  });

  const styles = useMemo(
    () =>
      scrollShadow({
        orientation,
        hideScrollBar,
        className,
      }),
    [orientation, hideScrollBar, className]
  );

  const containerProps = {
    ref: domRef,
    className: styles,
    "data-orientation": props.orientation ?? "vertical",
    style: {
      "--scroll-shadow-size": `${size}px`,
      ...style,
      ...props.style,
    },
    ...otherProps,
  };

  return <div {...containerProps}>{children}</div>;
});

ScrollShadow.displayName = "ScrollShadow";
