import { TableBody as TableBodyBase } from "react-stately";

import type { HTMLAttributes, ReactNode } from "react";
import type { TableBodyProps as TableBodyBaseProps } from "react-stately";

export interface TableBodyProps<T>
  extends TableBodyBaseProps<T>,
    Omit<HTMLAttributes<HTMLTableSectionElement>, keyof TableBodyBaseProps<T>> {
  isLoading?: boolean;
  loadingContent?: ReactNode;
  emptyContent?: ReactNode;
}

export const TableBody = TableBodyBase as <T>(props: TableBodyProps<T>) => JSX.Element;
