import { forwardRef } from "react";
import { tv } from "tailwind-variants";

import type { HTMLAttributes } from "react";
import type { VariantProps } from "tailwind-variants";

export const textVariants = tv({
  base: "text-base",
  variants: {
    size: {
      sm: "text-xs",
      md: "text-sm",
      lg: "text-base",
    },
    bold: {
      true: "font-semibold",
      false: "font-normal",
    },
    emphasis: {
      default: "text-gray-850 dark:text-white",
      secondary: "text-gray-500 dark:text-gray-400",
      tertiary: "text-gray-300 dark:text-gray-500",
    },
  },
  defaultVariants: {
    bold: false,
    size: "md",
    emphasis: "default",
  },
});

export interface TextProps
  extends HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof textVariants> {}

export const Text = forwardRef<HTMLSpanElement, TextProps>(
  ({ className, size, bold, emphasis, ...props }, ref) => {
    return (
      <span ref={ref} className={textVariants({ className, size, bold, emphasis })} {...props} />
    );
  }
);
Text.displayName = "Text";
