import { size } from "lodash";
import { useEffect, useRef } from "react";

// Utility function to do shallow comparison of two objects
const shallowEqual = <T extends Record<string, unknown>>(
  obj1: T | undefined,
  obj2: T | undefined
): boolean => {
  if (obj1 === obj2) return true;
  if (!obj1 || !obj2) return false;
  if (size(obj1) !== size(obj2)) return false;

  for (const key of Object.keys(obj1)) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

/**
 * This hook will only run an effect callback if properties of the provided object change, rather
 * than the object reference itself.
 */
export function useShallowCompareEffect<T extends Record<string, unknown>>(
  callback: () => void,
  dependencies: T,
  // Only run the effect when the dependencies change, not on mount
  runOnlyOnChange = false
) {
  const prevDepsRef = useRef<T>(runOnlyOnChange ? dependencies : ({} as T));

  useEffect(() => {
    if (!shallowEqual(prevDepsRef.current, dependencies)) {
      prevDepsRef.current = dependencies;
      callback();
    }
  }, [callback, dependencies]);
}
