import { forwardRef, useId, useMemo, useRef, useState } from "react";
import {
  chain,
  mergeProps,
  useCheckbox,
  useFocusRing,
  useHover,
  usePress,
  VisuallyHidden,
} from "react-aria";
import { useToggleState } from "react-stately";
import { tv } from "tailwind-variants";

import { useFocusableRef } from "../../hooks/useFocusableRef";
import { CheckboxIcon } from "./CheckboxIcon";

import type { FocusableRef } from "@react-types/shared";
import type { HTMLAttributes } from "react";
import type { AriaCheckboxProps } from "react-aria";
import type { VariantProps } from "tailwind-variants";
import type { CheckboxIconProps } from "./CheckboxIcon";

export const baseCheckbox = tv({
  slots: {
    iconWrapper: [
      "relative",
      "inline-flex",
      "items-center",
      "justify-center",
      "flex-shrink-0",
      "overflow-hidden",
      "text-white",
      "rounded-sm",
      // before
      "before:content-['']",
      "before:absolute",
      "before:inset-0",
      "before:border-2",
      "before:border-solid",
      "before:box-border",
      "before:border-gray-100",
      "dark:before:border-gray-700",
      "before:bg-white",
      "dark:before:bg-gray-900",
      "before:transition-colors",
      "before:duration-200",
      "before:rounded-sm",
      // after
      "after:content-['']",
      "after:absolute",
      "after:inset-0",
      "after:scale-50",
      "after:opacity-0",
      "after:origin-center",
      "after:bg-blue",
      "after:text-white",
      "after:rounded-sm",
      "after:duration-200",
      "group-data-[selected=true]:after:scale-100",
      "group-data-[selected=true]:after:opacity-100",
      // hover
      "group-data-[hover=true]:before:bg-gray-20",
      "dark:group-data-[hover=true]:before:bg-gray-600",
      "group-data-[hover=true]:after:bg-blue-400",
      // focus ring
      "outline-none",
      "group-data-[focus-visible=true]:z-10",
      "group-data-[focus-visible=true]:ring-2",
      "group-data-[focus-visible=true]:ring-blue-100",
      "group-data-[focus-visible=true]:ring-offset-2",
      "group-data-[focus-visible=true]:ring-offset-white",
    ],
    icon: "z-10 h-3 w-4 opacity-0 group-data-[selected=true]:opacity-100",
  },
  variants: {
    size: {
      sm: {
        iconWrapper: "mr-2 size-4",
        icon: "h-2 w-3",
      },
      lg: {
        iconWrapper: "mr-2 size-5",
        icon: "h-3 w-4",
      },
    },
    disableAnimation: {
      true: {
        iconWrapper: "transition-none",
        icon: "transition-none",
      },
      false: {
        iconWrapper: [
          "before:transition-colors",
          "group-data-[pressed=true]:scale-95",
          "transition-transform",
          "after:transition-transform-opacity-colors",
          "after:!ease-linear",
          "after:!duration-200",
          "motion-reduce:transition-none",
        ],
        icon: "transition-opacity motion-reduce:transition-none",
      },
    },
  },
  defaultVariants: {
    size: "sm",
    disableAnimation: false,
  },
});

const checkbox = tv({
  extend: baseCheckbox,
  slots: {
    wrapper: [
      "group",
      "relative",
      "max-w-fit",
      "inline-flex",
      "items-center",
      "justify-start",
      "cursor-pointer",
      "p-2",
      "-m-2",
    ],
    label: "relative select-none text-gray-850 dark:text-white",
  },
  variants: {
    size: {
      sm: {
        label: "text-small",
      },
      lg: {
        label: "text-medium",
      },
    },
    isDisabled: {
      true: {
        wrapper: "pointer-events-none opacity-60",
      },
    },
    isInvalid: {
      true: {
        iconWrapper: "before:border-red",
        label: "text-red",
      },
    },
    disableAnimation: {
      true: {
        label: "transition-none",
      },
      false: {
        label: "transition-colors-opacity before:transition-width motion-reduce:transition-none",
      },
    },
  },
  defaultVariants: {
    isDisabled: false,
  },
});

type CheckboxVariantProps = VariantProps<typeof checkbox>;

type BaseProps = Omit<HTMLAttributes<HTMLInputElement>, "defaultChecked"> &
  Omit<AriaCheckboxProps, "onChange">;

export interface CheckboxProps extends BaseProps, CheckboxVariantProps {
  /**
   * Whether the checkbox is disabled.
   * @default false
   */
  isDisabled?: boolean;
  /**
   * React Aria onChange event.
   */
  onValueChange?: AriaCheckboxProps["onChange"];
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      value = "",
      children,
      name,
      isRequired = false,
      isReadOnly: isReadOnlyProp = false,
      autoFocus = false,
      isSelected: isSelectedProp,
      validationState,
      isDisabled: isDisabledProp = false,
      disableAnimation = false,
      isInvalid = validationState ? validationState === "invalid" : false,
      isIndeterminate = false,
      defaultSelected,
      onChange,
      className,
      size,
      onValueChange,
      ...otherProps
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const domRef = useFocusableRef(ref as FocusableRef<HTMLLabelElement>, inputRef);

    const labelId = useId();

    const ariaLabel = otherProps["aria-label"] ?? (typeof children === "string" ? children : " ");

    const ariaCheckboxProps = useMemo(() => {
      return {
        name,
        value,
        children,
        autoFocus,
        defaultSelected,
        isIndeterminate,
        isRequired,
        isInvalid,
        isSelected: isSelectedProp,
        isDisabled: isDisabledProp,
        isReadOnly: isReadOnlyProp,
        "aria-label": ariaLabel,
        "aria-labelledby": otherProps["aria-labelledby"] || labelId,
        onChange: onValueChange,
      };
    }, [
      value,
      name,
      labelId,
      children,
      autoFocus,
      isInvalid,
      isIndeterminate,
      isDisabledProp,
      isReadOnlyProp,
      isSelectedProp,
      defaultSelected,
      ariaLabel,
      otherProps["aria-labelledby"],
      onValueChange,
    ]);

    const toggleState = useToggleState(ariaCheckboxProps);
    const {
      inputProps: ariaInputProps,
      isSelected,
      isDisabled,
      isReadOnly,
      isPressed: isPressedKeyboard,
    } = useCheckbox(ariaCheckboxProps, toggleState, inputRef);

    if (isRequired) {
      ariaInputProps.required = true;
    }

    const isInteractionDisabled = isDisabled || isReadOnly;

    // Handle press state for full label. Keyboard press state is returned by useCheckbox
    // since it is handled on the <input> element itself.
    const [isPressed, setPressed] = useState(false);
    const { pressProps } = usePress({
      isDisabled: isInteractionDisabled,
      onPressStart(e) {
        if (e.pointerType !== "keyboard") {
          setPressed(true);
        }
      },
      onPressEnd(e) {
        if (e.pointerType !== "keyboard") {
          setPressed(false);
        }
      },
    });

    const pressed = isInteractionDisabled ? false : isPressed || isPressedKeyboard;

    const { hoverProps, isHovered } = useHover({
      isDisabled: ariaInputProps.disabled,
    });

    const { focusProps, isFocused, isFocusVisible } = useFocusRing({
      autoFocus: ariaInputProps.autoFocus,
    });

    const { wrapper, iconWrapper, icon, label } = useMemo(
      () =>
        checkbox({
          isInvalid,
          isDisabled,
          disableAnimation,
          size,
        }),
      [isInvalid, isDisabled, disableAnimation]
    );

    const wrapperProps = useMemo(
      () => ({
        ref: domRef,
        className: wrapper({ className }),
        "data-disabled": isDisabled,
        "data-selected": isSelected || isIndeterminate,
        "data-invalid": isInvalid,
        "data-hover": isHovered,
        "data-focus": isFocused,
        "data-pressed": pressed,
        "data-readonly": ariaInputProps.readOnly,
        "data-focus-visible": isFocusVisible,
        "data-indeterminate": isIndeterminate,
        ...mergeProps(hoverProps, pressProps, otherProps),
      }),
      [
        wrapper,
        className,
        isDisabled,
        isSelected,
        isIndeterminate,
        isInvalid,
        isHovered,
        isFocused,
        pressed,
        ariaInputProps.readOnly,
        isFocusVisible,
        hoverProps,
        pressProps,
        otherProps,
      ]
    );

    const iconWrapperProps = useMemo(
      () => ({
        "aria-hidden": true,
        className: iconWrapper(),
      }),
      [iconWrapper]
    );

    const inputProps = useMemo(
      () => ({
        ref: inputRef,
        ...mergeProps(ariaInputProps, focusProps),
        onChange: chain(ariaInputProps.onChange, onChange),
      }),
      [ariaInputProps, focusProps, onChange]
    );

    const labelProps = useMemo(
      () => ({
        id: labelId,
        className: label(),
      }),
      [label, isDisabled, isSelected, isInvalid]
    );

    const iconProps = useMemo(
      () =>
        ({
          isSelected: isSelected,
          isIndeterminate: !!isIndeterminate,
          disableAnimation: !!disableAnimation,
          className: icon(),
        }) as CheckboxIconProps,
      [icon, isSelected, isIndeterminate, disableAnimation]
    );

    return (
      <label {...wrapperProps}>
        <VisuallyHidden>
          <input {...inputProps} />
        </VisuallyHidden>
        <span {...iconWrapperProps}>
          <CheckboxIcon {...iconProps} />
        </span>
        {children && <span {...labelProps}>{children}</span>}
      </label>
    );
  }
);
Checkbox.displayName = "Checkbox";
