import { createContext, forwardRef, useMemo } from "react";
import { tv } from "tailwind-variants";

import { useDomRef } from "../../hooks/useDomRef";

import type { HTMLAttributes } from "react";
import type { VariantProps } from "tailwind-variants";
import type { ButtonProps } from "./Button";

const buttonGroup = tv({
  base: "inline-flex h-auto items-center justify-center",
  variants: {
    fullWidth: {
      true: "w-full",
    },
  },
  defaultVariants: {
    fullWidth: false,
  },
});

type ButtonGroupVariantProps = VariantProps<typeof buttonGroup>;

export interface ButtonGroupProps
  extends Partial<
      Pick<
        ButtonProps,
        | "size"
        | "radius"
        | "variant"
        | "ghost"
        | "isIconOnly"
        | "disableAnimation"
        | "disableRipple"
      >
    >,
    HTMLAttributes<HTMLDivElement>,
    ButtonGroupVariantProps {
  /**
   * Whether the buttons are disabled.
   * @default false
   */
  isDisabled?: ButtonProps["isDisabled"];
}

export type ButtonGroupContextType = {
  size?: ButtonProps["size"];
  variant?: ButtonProps["variant"];
  ghost?: ButtonProps["ghost"];
  radius?: ButtonProps["radius"];
  isDisabled?: ButtonProps["isDisabled"];
  disableAnimation?: ButtonProps["disableAnimation"];
  disableRipple?: ButtonProps["disableRipple"];
  isIconOnly?: ButtonProps["isIconOnly"];
  fullWidth?: boolean;
};

export const buttonGroupContext = createContext<ButtonGroupContextType | undefined>(undefined);
buttonGroupContext.displayName = "ButtonGroupContext";

export const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>((props, ref) => {
  const {
    children,
    fullWidth,
    size = "md",
    variant = "default",
    ghost,
    radius,
    isDisabled = false,
    disableAnimation = false,
    disableRipple = false,
    isIconOnly = false,
    className,
    ...otherProps
  } = props;

  const domRef = useDomRef(ref);

  const context = useMemo<ButtonGroupContextType>(
    () => ({
      size,
      variant,
      ghost,
      radius,
      isIconOnly,
      isDisabled,
      disableAnimation,
      disableRipple,
      fullWidth: !!fullWidth,
    }),
    [
      size,
      variant,
      ghost,
      radius,
      isDisabled,
      isIconOnly,
      disableAnimation,
      disableRipple,
      fullWidth,
    ]
  );

  const buttonGroupProps = useMemo(
    () => ({
      ref: domRef,
      className: buttonGroup({
        fullWidth,
        className,
      }),
      role: "group",
      ...otherProps,
    }),
    [fullWidth, className, otherProps]
  );

  return (
    <buttonGroupContext.Provider value={context}>
      <div {...buttonGroupProps}>{children}</div>
    </buttonGroupContext.Provider>
  );
});

ButtonGroup.displayName = "ButtonGroup";
