import type { CollectionBase } from "@react-types/shared";
import type { ReactElement, ReactNode } from "react";

export const firstKeyFromSet = <T>(keys: Set<T>) =>
  keys.size ? (keys.values().next().value as T) : undefined;

export interface CollectionProps<T> extends Omit<CollectionBase<T>, "children" | "items"> {
  items?: T[];
  children?: ReactNode | ((item: T) => ReactElement);
}
