import { AnimatePresence, motion } from "framer-motion";
import { clamp } from "lodash";

import type { HTMLMotionProps } from "framer-motion";
import type { FC, HTMLAttributes } from "react";
import type { RippleType } from "./useRipple";

export interface RippleProps extends HTMLAttributes<HTMLSpanElement> {
  ripples: RippleType[];
  color?: string;
  motionProps?: HTMLMotionProps<"span">;
  onClear: (key: React.Key) => void;
}

export const Ripple: FC<RippleProps> = ({
  ripples = [],
  motionProps,
  color = "currentColor",
  style,
  onClear,
}) => (
  <>
    {ripples.map(ripple => {
      const duration = clamp(0.01 * ripple.size, 0.2, ripple.size > 100 ? 0.75 : 0.5);

      return (
        <AnimatePresence key={ripple.key} mode="popLayout">
          <motion.span
            animate={{ transform: "scale(2)", opacity: 0 }}
            exit={{ opacity: 0 }}
            initial={{ transform: "scale(0)", opacity: 0.35 }}
            style={{
              position: "absolute",
              backgroundColor: color,
              borderRadius: "100%",
              transformOrigin: "center",
              pointerEvents: "none",
              zIndex: 10,
              top: ripple.y,
              left: ripple.x,
              width: `${ripple.size}px`,
              height: `${ripple.size}px`,
              ...style,
            }}
            transition={{ duration }}
            onAnimationComplete={() => {
              onClear(ripple.key);
            }}
            {...motionProps}
          />
        </AnimatePresence>
      );
    })}
  </>
);

Ripple.displayName = "Ripple";
