import * as LabelPrimitive from "@radix-ui/react-label";
import React from "react";
import { tv } from "tailwind-variants";

import { cn } from "../../utils/cn";

import type { VariantProps } from "tailwind-variants";

const labelVariants = tv({
  base: "text-sm font-medium leading-none text-gray-850 peer-disabled:cursor-not-allowed peer-disabled:opacity-70 dark:text-white",
});

export const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props} />
));
Label.displayName = LabelPrimitive.Root.displayName;
