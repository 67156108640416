import type { Placement, PlacementAxis } from "react-aria";

export type OverlayPlacement =
  | "top"
  | "bottom"
  | "right"
  | "left"
  | "top-start"
  | "top-end"
  | "bottom-start"
  | "bottom-end"
  | "left-start"
  | "left-end"
  | "right-start"
  | "right-end";

export interface OverlayOptions {
  /**
   * Whether the tooltip should flip its placement when there is not enough space.
   * @default true
   */
  shouldFlip?: boolean;
  /**
   * The minimum distance in pixels that the tooltip popover should be positioned to the trigger.
   * @default 12
   */
  offset?: number;
  /**
   * The minimum distance in pixels that the tooltip popover should be positioned to the trigger.
   * @default 0
   */
  crossOffset?: number;
  /**
   * The padding of the container in pixels.
   * @default 12
   */
  containerPadding?: number;
  /**
   * The placement of the tooltip.
   * @default "top"
   */
  placement?: OverlayPlacement;
  /**
   * Whether the tooltip should show an arrow.
   * @default false
   */
  showArrow?: boolean;
}

export const toReactAriaPlacement = (placement: OverlayPlacement) => {
  const mapPositions: Record<OverlayPlacement, Placement> = {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
    "top-start": "top start",
    "top-end": "top end",
    "bottom-start": "bottom start",
    "bottom-end": "bottom end",
    "left-start": "left top",
    "left-end": "left bottom",
    "right-start": "right top",
    "right-end": "right bottom",
  };

  return mapPositions[placement];
};

export const getTransformOrigins = (placement: OverlayPlacement) => {
  const origins: Record<
    OverlayPlacement,
    {
      originX?: number;
      originY?: number;
    }
  > = {
    top: {
      originY: 1,
    },
    bottom: {
      originY: 0,
    },
    left: {
      originX: 1,
    },
    right: {
      originX: 0,
    },
    "top-start": {
      originX: 0,
      originY: 1,
    },
    "top-end": {
      originX: 1,
      originY: 1,
    },
    "bottom-start": {
      originX: 0,
      originY: 0,
    },
    "bottom-end": {
      originX: 1,
      originY: 0,
    },
    "right-start": {
      originX: 0,
      originY: 0,
    },
    "right-end": {
      originX: 0,
      originY: 1,
    },
    "left-start": {
      originX: 1,
      originY: 0,
    },
    "left-end": {
      originX: 1,
      originY: 1,
    },
  };

  return origins?.[placement] || {};
};

export const getShouldUseAxisPlacement = (
  axisPlacement: PlacementAxis,
  overlayPlacement: OverlayPlacement
) => {
  if (overlayPlacement.includes("-")) {
    const [position] = overlayPlacement.split("-");

    if (position.includes(axisPlacement)) {
      return false;
    }
  }

  return true;
};

export const getArrowPlacement = (dynamicPlacement: PlacementAxis, placement: OverlayPlacement) => {
  if (placement.includes("-")) {
    const [, position] = placement.split("-");

    return `${dynamicPlacement}-${position}`;
  }

  return dynamicPlacement;
};
