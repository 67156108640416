import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";
import { tv } from "tailwind-variants";

import { Button } from "../button/Button";
import { Skeleton } from "../skeleton/Skeleton";
import { Text } from "../typography/Text";

import type { ComponentPropsWithoutRef, FC } from "react";
import type { VariantProps } from "tailwind-variants";
import type { ForwardRefComponentWithSubcomponents } from "../../utils/types";

export interface PaginationInterface {
  /** Total number of items in the query, irrespective of pagination */
  totalCount: number;
  /** Number of items per page */
  pageSize: number;
  /** Current page number */
  pageNumber: number;
  /** Whether a previous page is available */
  hasPreviousPage: boolean;
  /** Whether a next page is available */
  hasNextPage: boolean;
  /** Switch to the previous page, loading data if necessary */
  decrementPage: () => void;
  /** Switch to the next page, loading data if necessary */
  incrementPage: () => void;
  /** Reset to page zero */
  resetPageNumber: () => void;
  /** Whether the next page is currently loading */
  isLoadingNext?: boolean;
  /** Whether the previous page is currently loading */
  isLoadingPrevious?: boolean;
}

const paginationVariants = tv({
  slots: {
    base: "flex flex-row items-center justify-between text-gray-850 dark:text-white",
    count: "font-medium text-gray-600 dark:text-gray-500",
    pagination: "flex flex-row items-center gap-x-2",
    currentPage: "text-center",
  },
  variants: {
    variant: {
      table: {
        base: "py-1.5 pl-3 pr-1.5",
      },
      default: {},
    },
    largeCount: {
      true: {
        currentPage: "w-[60px]",
      },
      false: {
        currentPage: "w-10",
      },
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface PaginationProps
  extends PaginationInterface,
    ComponentPropsWithoutRef<"div">,
    Pick<VariantProps<typeof paginationVariants>, "variant"> {
  className?: string;
}

export const Pagination = forwardRef<HTMLDivElement, PaginationProps>(
  (
    {
      className,
      pageNumber,
      totalCount = 0,
      pageSize,
      hasPreviousPage,
      decrementPage,
      hasNextPage,
      incrementPage,
      isLoadingNext,
      isLoadingPrevious,
      variant,
      ...props
    },
    ref
  ) => {
    const low = pageNumber * pageSize + 1;
    const high = Math.min((pageNumber + 1) * pageSize, totalCount);

    const slots = paginationVariants({ variant, largeCount: totalCount >= 100 });

    return (
      <div className={slots.base({ className })} {...props} ref={ref}>
        <Text size="sm" emphasis="secondary" className={slots.count()}>
          Results: {totalCount}
        </Text>
        {hasPreviousPage || hasNextPage ? (
          <div className={slots.pagination()}>
            <Button
              isIconOnly
              ghost
              variant="light"
              isDisabled={!hasPreviousPage}
              onClick={decrementPage}
              isLoading={isLoadingPrevious}
            >
              <Icon icon={faChevronLeft} />
            </Button>
            <Text emphasis="secondary" className={slots.currentPage()}>
              {low}-{high}
            </Text>
            <Button
              isIconOnly
              ghost
              variant="light"
              isDisabled={!hasNextPage}
              onClick={incrementPage}
              isLoading={isLoadingNext}
            >
              <Icon icon={faChevronRight} />
            </Button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
) as ForwardRefComponentWithSubcomponents<
  HTMLDivElement,
  { Skeleton: typeof PaginationSkeleton },
  PaginationProps
>;
Pagination.displayName = "Pagination";

const PaginationSkeleton: FC<Pick<PaginationProps, "className" | "variant">> = ({
  className,
  variant,
}) => {
  const { base } = paginationVariants({ variant });

  return (
    <div className={base({ className })}>
      <Skeleton className="h-7 w-16" />
      <Skeleton className="h-7 w-28" />
    </div>
  );
};

Pagination.Skeleton = PaginationSkeleton;
