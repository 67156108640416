import { createContext } from "../../utils/createContext";

import type { AlertClassNames, AlertSlots, AlertVariantProps } from "./alertVariants";

export interface AlertContext {
  slots: AlertSlots;
  size: AlertVariantProps["size"];
  classNames?: AlertClassNames;
  handleDismiss: () => void;
}

export const [AlertProvider, useAlertContext] = createContext<AlertContext>({
  name: "AlertContext",
});
