import { forwardRef } from "react";
import { tv } from "tailwind-variants";

import { textVariants } from "./Text";

import type { HTMLAttributes } from "react";
import type { VariantProps } from "tailwind-variants";

const paragraphVariants = tv({
  extend: textVariants,
  base: "mb-0",
  variants: {
    withLine: {
      true: [
        "flex",
        "before:content-['']",
        "after:content-['']",
        "before:flex-1",
        "after:flex-1",
        "before:border-b",
        "after:border-b",
        "before:border-solid",
        "after:border-solid",
        "before:border-b-gray-150",
        "after:border-b-gray-150",
        "before:border-t-0",
        "after:border-t-0",
        "before:border-l-0",
        "after:border-l-0",
        "before:border-r-0",
        "after:border-r-0",
        "before:m-auto",
        "after:m-auto",
        "before:mr-2",
        "after:ml-2",
        "dark:before:border-b-gray-700",
        "dark:after:border-b-gray-700",
      ],
    },
  },
});

export interface ParagraphProps
  extends HTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof paragraphVariants> {}

export const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
  ({ className, size, bold, emphasis, withLine, ...props }, ref) => {
    return (
      <p
        ref={ref}
        className={paragraphVariants({ className, size, bold, emphasis, withLine })}
        {...props}
      />
    );
  }
);
Paragraph.displayName = "Paragraph";
