import type { FC } from "react";

interface BaseIconProps {
  "data-checked": string;
  className: string;
}

export interface CheckboxIconProps extends BaseIconProps {
  isSelected: boolean;
  isIndeterminate: boolean;
  disableAnimation: boolean;
}

const CheckIcon: FC<Omit<CheckboxIconProps, "isIndeterminate">> = ({
  isSelected,
  disableAnimation,
  ...props
}) => (
  <svg aria-hidden="true" role="presentation" viewBox="0 0 17 18" {...props}>
    <polyline
      fill="none"
      points="1 9 7 14 15 4"
      stroke="currentColor"
      strokeDasharray={22}
      strokeDashoffset={isSelected ? 44 : 66}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      style={
        !disableAnimation && isSelected
          ? {
              transition: "stroke-dashoffset 250ms linear 0.2s",
            }
          : {}
      }
    />
  </svg>
);

const IndeterminateIcon: FC<BaseIconProps> = props => (
  <svg stroke="currentColor" strokeWidth={3} viewBox="0 0 24 24" {...props}>
    <line x1="21" x2="3" y1="12" y2="12" />
  </svg>
);

export const CheckboxIcon: FC<CheckboxIconProps> = ({ isIndeterminate, ...props }) => {
  if (isIndeterminate) {
    return <IndeterminateIcon {...props} />;
  }

  return <CheckIcon {...props} />;
};
