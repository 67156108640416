import { uniqueId } from "lodash";
import { useCallback, useState } from "react";

import type { Key, MouseEvent as ReactMouseEvent } from "react";

export type RippleType = {
  key: Key;
  x: number;
  y: number;
  size: number;
};

export function useRipple() {
  const [ripples, setRipples] = useState<RippleType[]>([]);

  const onClick = useCallback((event: ReactMouseEvent<HTMLElement, MouseEvent>) => {
    const trigger = event.currentTarget;

    const size = Math.max(trigger.clientWidth, trigger.clientHeight);
    const rect = trigger.getBoundingClientRect();

    setRipples(prevRipples => [
      ...prevRipples,
      {
        key: uniqueId(prevRipples.length.toString()),
        size,
        x: event.clientX - rect.x - size / 2,
        y: event.clientY - rect.y - size / 2,
      },
    ]);
  }, []);

  const onClear = useCallback((key: Key) => {
    setRipples(prevState => prevState.filter(ripple => ripple.key !== key));
  }, []);

  return { ripples, onClick, onClear };
}

export type UseRippleReturn = ReturnType<typeof useRipple>;
