import React, { forwardRef } from "react";

import { cn } from "../../utils/cn";

import type { ComponentPropsWithoutRef } from "react";

interface Props extends ComponentPropsWithoutRef<"svg"> {
  compact?: boolean;
}

const WIDTH_SMALL = "43";
const WIDTH_LARGE = "141";

export const Logo = forwardRef<SVGSVGElement, Props>(({ className, compact, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn("h-[38px] fill-current text-blue", className)}
    viewBox={`0 0 ${compact ? WIDTH_SMALL : WIDTH_LARGE} 38`}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.10547 33.7251C1.10547 35.8098 2.47338 37.2348 4.47263 37.6834C4.73569 37.7361 5.47226 38 10.0232 38C14.1795 38 37.9338 38 37.9338 38V13.749C2.52599 24.3836 1.10547 31.614 1.10547 33.7251Z" />
    <path d="M0 18.9997C34.8028 12.2179 42.2737 8.07487 42.2737 5.62074C42.2737 3.53606 37.8806 2.92912 37.8806 2.92912V0H0V18.9997Z" />
    {!compact && (
      <>
        <path d="M119.98 26.1778V11.2155C119.98 10.9252 120.217 10.6877 120.507 10.6877H123.006C123.295 10.6877 123.532 10.9252 123.532 11.2155V26.1514C123.532 26.4417 123.295 26.6792 123.006 26.6792H120.507C120.217 26.7056 119.98 26.4681 119.98 26.1778Z" />
        <path d="M121.77 8.28582C122.903 8.28582 123.821 7.36445 123.821 6.22787C123.821 5.0913 122.903 4.16992 121.77 4.16992C120.636 4.16992 119.718 5.0913 119.718 6.22787C119.718 7.36445 120.636 8.28582 121.77 8.28582Z" />
        <path d="M57.0049 8.47064C57.0049 7.07205 58.0835 6.22762 59.9249 6.22762H67.1327C67.3695 6.22762 67.5536 6.0429 67.5536 5.8054V3.21933C67.5536 2.98184 67.3695 2.79712 67.1327 2.79712H59.9249C56.2157 2.79712 53.6115 5.11931 53.6115 8.47064C53.6115 14.9622 64.8441 15.965 64.8441 20.6885C64.8441 22.1926 63.6077 23.2746 61.8978 23.2746H59.5829C57.9782 23.2746 56.6629 21.9552 56.6629 20.3455V19.501C56.6629 19.2108 56.4262 18.9733 56.1368 18.9733H53.7693C53.4799 18.9733 53.2432 19.2108 53.2432 19.501V20.3455C53.2432 23.8551 56.0842 26.6787 59.5566 26.6787H61.8452C65.4491 26.6787 68.2113 24.0926 68.2113 20.6621C68.2113 13.4845 57.0049 12.0859 57.0049 8.47064Z" />
        <path d="M113.879 3.32464V10.687H108.434C105.067 10.687 102.305 13.405 102.305 16.8355V20.5563C102.305 23.934 105.067 26.7048 108.434 26.7048H116.747C117.036 26.7048 117.273 26.4673 117.273 26.1771V3.32464C117.273 3.03437 117.036 2.79688 116.747 2.79688H114.379C114.09 2.82326 113.879 3.03437 113.879 3.32464ZM113.879 23.2743H108.434C106.935 23.2743 105.724 22.0341 105.724 20.5563V16.8355C105.724 15.3314 106.961 14.1175 108.434 14.1175H113.879V23.2743Z" />
        <path d="M96.1999 11.2162V23.2758H92.1225C90.5967 23.2494 89.4129 22.1147 89.4129 20.5842V11.2162C89.4129 10.926 89.1762 10.6885 88.8868 10.6885H86.5193C86.2299 10.6885 85.9932 10.926 85.9932 11.2162V20.5842C85.9932 23.9619 88.729 26.6799 92.0699 26.6799H96.1736V29.0812C96.1736 30.6118 94.6741 31.7465 93.1484 31.7729H89.9128C89.6234 31.7729 89.3866 32.0104 89.3866 32.3006V34.6756C89.3866 34.9659 89.6234 35.2034 89.9128 35.2034H93.1484C96.6734 35.2034 99.5671 32.3006 99.5671 28.7646V11.2162C99.5671 10.926 99.3303 10.6885 99.0409 10.6885H96.6734C96.4366 10.6885 96.1999 10.926 96.1999 11.2162Z" />
        <path d="M134.871 10.6885H132.162C132.135 10.6885 132.135 10.6885 132.109 10.6885C128.742 10.7149 126.032 13.4065 126.032 16.7842V16.8106V20.5314V20.5578C126.032 23.9619 128.742 26.6535 132.162 26.6535H134.871C134.897 26.6535 134.897 26.6535 134.924 26.6535C138.291 26.6271 141 23.9355 141 20.5578V20.5314V16.837V16.8106C140.974 13.4065 138.264 10.6885 134.871 10.6885ZM137.581 20.5578C137.581 22.0883 136.397 23.2758 134.871 23.2758C134.871 23.2758 134.871 23.2758 134.845 23.2758H132.135C131.451 23.2494 130.846 23.0383 130.399 22.6688C129.794 22.1675 129.426 21.4286 129.426 20.5578V16.837C129.426 15.3065 130.609 14.119 132.135 14.119C132.135 14.119 132.135 14.119 132.162 14.119H134.871C135.555 14.1454 136.16 14.3565 136.607 14.7259C137.212 15.2273 137.581 15.9662 137.581 16.837V20.5578Z" />
        <path d="M73.131 2.82324H70.7634C70.4741 2.82324 70.2373 3.06074 70.2373 3.35101V26.177C70.2373 26.4673 70.4741 26.7048 70.7634 26.7048H73.131C73.4203 26.7048 73.6571 26.4673 73.6571 26.177V3.32462C73.6571 3.03435 73.4203 2.82324 73.131 2.82324Z" />
        <path d="M79.2856 18.6842L83.9155 11.3218C84.0996 11.0315 83.8892 10.6885 83.5735 10.6885H80.7061C80.4694 10.6885 80.2326 10.8204 80.1011 11.0315L75.8132 17.6286C75.7343 17.7606 75.708 17.8925 75.708 18.0245V19.3703C75.708 19.5022 75.7606 19.6606 75.8132 19.7661L80.1011 26.3632C80.2326 26.5743 80.4694 26.7063 80.7061 26.7063H83.5472C83.8892 26.7063 84.0996 26.3368 83.9155 26.0466L79.2856 18.6842Z" />
      </>
    )}
  </svg>
));
Logo.displayName = "Logo";
