import { useEffect, useRef } from "react";

// Use this for values that may change (so you want to track the latest value) but you don't want to
// trigger effects when they are used as dependencies. This is useful in particular for functions
// since we don't necessarily have control over whether they are memoized or passed as an inline
// function, which will change every re-render and may cause unnecessary effects to run.
export const useSyncedRef = <T>(value: T): React.MutableRefObject<T> => {
  const ref = useRef<T>(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};
