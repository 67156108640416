import { Euler, Matrix4, Quaternion, Spherical, Vector3 } from "three";

import { PI_OVER_2, TAU } from "./constants";
import { mapValue } from "./misc";

import type { HeadingPitchRadians } from "./types/geospatial";
import type { Degrees } from "./types/semantic";

/**
 * Convert from a quaternion to polar angles heading and pitch.
 *
 * Used, for example, to convert the heading and gimbalPitch information
 * from mission planning into a quaternion to be used by the `Waypoints` component.
 * @param quaternion The quaternion to convert
 * @param polarDirection The normalized vector representing the equator at latitude zero
 * @returns The equivalent of `quaternion` in heading and pitch representation (polar angles).
 */
export const convertQuaternionToHeadingPitch = (
  quaternion: Quaternion,
  polarDirection: Vector3 = new Vector3(0, 0, 1)
): HeadingPitchRadians => {
  const lengthSq = polarDirection.lengthSq();
  if (lengthSq > 1) {
    throw Error(`The polar direction isn't normalized (length squared: ${lengthSq})`);
  }

  // We use a positive Z vector here because in ThreeJS's spherical
  // coordinates, the equator is at positive Z.
  //
  // See https://threejs.org/docs/#api/en/math/Spherical
  const vDir = polarDirection.applyQuaternion(quaternion);
  const sphericalDirections = new Spherical().setFromVector3(vDir);

  // Map the value returned by Spherical since its latitude is mapped
  // differently to ours (i.e. our pitch)
  //
  // See https://threejs.org/docs/#api/en/math/Spherical
  const mappedPhi = mapValue(
    sphericalDirections.phi,
    { start: 0, end: Math.PI },
    // Use a start of -PI/2 instead of PI/2 because when the user is dragging
    // the mouse downwards, we want the pitch to increase, not decrease; this,
    // in turn, is caused by the rotation gizmo being positioned **behind** the
    // object to be controlled.
    { start: -PI_OVER_2, end: PI_OVER_2 }
  );

  return {
    heading: sphericalDirections.theta,
    pitch: mappedPhi,
  };
};

/**
 * Cache of an `Euler` instance to avoid recreating a new one
 * every time, just to call `Quaternion.setFromEuler`.
 */
const CACHE_EULER_ANGLE = new Euler();
/**
 * Convert from polar angles to quaternion.
 * @param headingPitch Heading and pitch to convert.
 * @returns The equivalent of `headingPitch` in quaternion representation.
 */
export const convertHeadingPitchToQuaternion = (headingPitch: HeadingPitchRadians): Quaternion => {
  return new Quaternion().setFromEuler(
    CACHE_EULER_ANGLE.set(headingPitch.pitch, headingPitch.heading, 0, "YXZ")
  );
};

const CACHE_LOOKAT_MATRIX = new Matrix4();
/**
 * Returns a `Quaternion` which is set with the results of `Matrix4.lookAt()`.
 */
export const lookAtQuaternion = (
  eye: Vector3,
  target: Vector3,
  up: Vector3,
  quaternion: Quaternion
): Quaternion => {
  return quaternion.setFromRotationMatrix(CACHE_LOOKAT_MATRIX.lookAt(eye, target, up));
};

/**
 * Returns a `Euler` which is set with the results of `Matrix4.lookAt()`.
 */
export const lookAtEuler = (eye: Vector3, target: Vector3, up: Vector3, euler: Euler): Euler => {
  return euler.setFromRotationMatrix(CACHE_LOOKAT_MATRIX.lookAt(eye, target, up));
};

/**
 * Extracts the heading from a `Matrix4`.
 *
 * The calculations are done in an EUS coordinate system, which is the standard coordinate system
 * of ThreeJS.
 * The heading is considered to be the angle between the backwards (Z) vector and the X axis.
 */
export const headingFromMatrix4 = (m: Matrix4): Degrees => {
  // Get the third column of the matrix, i.e. the Z axis, i.e. the forward vector
  const forward = m.elements.slice(8, 11);

  // Get the angle between the backwards vector and the X axis
  const x = Math.atan2(forward[0], forward[2]);

  // Convert to heading, from 0 < 360 degrees
  const heading = (((x > 0 ? x : TAU + x) * 360) / TAU) % 360;

  return heading;
};
