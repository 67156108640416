import { zodResolver } from "@hookform/resolvers/zod";
import { forwardRef, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { useSyncedRef } from "../../hooks/useSyncedRef";

import type { ComponentPropsWithoutRef, ForwardedRef, ReactElement } from "react";
import type { DefaultValues, UseFormProps } from "react-hook-form";
import type { z } from "zod";

export interface FormProps<T extends z.ZodTypeAny>
  extends ComponentPropsWithoutRef<"form">,
    UseFormProps<z.TypeOf<T>> {
  /** Zod schema to use for validating the form. */
  schema: T;
  onSubmit: (values: z.TypeOf<T>) => void;
  /**
   * Whether the form state will automatically reset after a completed submission. Use this if
   * you want the user to stay on the page and potentially submit the form again.
   */
  resetAfterSubmit?: boolean;
  /** Values to set in the form fields after resetting the form. */
  resetValues?: DefaultValues<z.TypeOf<T>>;
}

function FormImpl<T extends z.ZodTypeAny>(
  {
    children,
    schema,
    onSubmit,
    resetAfterSubmit = false,
    resetValues: resetValuesProp,
    resolver,
    mode = "onTouched",
    disabled,
    reValidateMode,
    defaultValues,
    values,
    errors,
    resetOptions,
    context,
    shouldFocusError,
    shouldUnregister,
    shouldUseNativeValidation,
    progressive,
    criteriaMode,
    delayError,
    ...props
  }: FormProps<T>,
  ref: ForwardedRef<HTMLFormElement>
) {
  const form = useForm<z.infer<T>>({
    resolver: resolver ?? zodResolver(schema),
    mode,
    disabled,
    reValidateMode,
    defaultValues,
    values,
    errors,
    resetOptions,
    context,
    shouldFocusError,
    shouldUnregister,
    shouldUseNativeValidation,
    progressive,
    criteriaMode,
    delayError,
  });

  const { formState, reset } = form;

  // Wrapping this in a ref to avoid re-invoking the effect below
  const resetValues = useSyncedRef(resetValuesProp);

  useEffect(() => {
    if (formState.isSubmitSuccessful && resetAfterSubmit) {
      reset(resetValues.current);
    }
  }, [formState, reset, resetAfterSubmit, resetValues]);

  return (
    <form {...props} onSubmit={form.handleSubmit(onSubmit)} ref={ref}>
      <FormProvider {...form}>{children}</FormProvider>
    </form>
  );
}

const WrappedForm = forwardRef(FormImpl) as <T extends z.ZodTypeAny>(
  props: FormProps<T> & { ref?: ForwardedRef<HTMLFormElement> }
) => ReactElement;

export const Form = WrappedForm as typeof WrappedForm & {
  displayName: string;
};
Form.displayName = "Form";
