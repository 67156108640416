import { useCallback, useEffect, useRef, useState } from "react";

export function useIsMounted({ rerender = false }: { rerender?: boolean }) {
  const isMountedRef = useRef(false);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;

    if (rerender) {
      setIsMounted(true);
    }

    return () => {
      isMountedRef.current = false;
      if (rerender) {
        setIsMounted(false);
      }
    };
  }, [rerender]);

  const getRef = useCallback(() => isMountedRef.current, []);

  return [isMounted, getRef] as const;
}
