import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useMemo } from "react";
import { mergeProps, useFocusRing, useHover, useTableColumnHeader } from "react-aria";

import { useDomRef } from "../../../hooks/useDomRef";
import { cn } from "../../../utils/cn";
import { filterDOMProps } from "../../../utils/filterDomProps";

import type { GridNode } from "@react-types/grid";
import type { HTMLAttributes } from "react";
import type { TableState } from "react-stately";
import type { TableColumnProps } from "../TableColumn";
import type { TableClassNames, TableSlots } from "../tableVariants";

interface TableColumnHeaderProps<T = object> extends HTMLAttributes<HTMLTableCellElement> {
  node: GridNode<T>;
  state: TableState<T>;
  slots: TableSlots;
  classNames?: TableClassNames;
}

export const TableColumnHeader = forwardRef<HTMLTableCellElement, TableColumnHeaderProps>(
  ({ className, node, state, slots, classNames, ...otherProps }, ref) => {
    const domRef = useDomRef(ref);

    const { columnHeaderProps } = useTableColumnHeader({ node }, state, domRef);
    const { isFocusVisible, focusProps } = useFocusRing();
    const { isHovered, hoverProps } = useHover({});

    const { align, ...columnProps }: TableColumnProps<unknown> = node.props;

    const cell = useMemo(() => {
      const cellType = typeof node.rendered;

      return cellType !== "object" && cellType !== "function" ? (
        <span>{node.rendered}</span>
      ) : (
        node.rendered
      );
    }, [node.rendered]);

    return (
      <th
        ref={domRef}
        colSpan={node.colspan}
        data-focus-visible={isFocusVisible}
        data-hover={isHovered}
        data-sortable={columnProps.allowsSorting}
        {...mergeProps(
          columnHeaderProps,
          focusProps,
          filterDOMProps(columnProps),
          columnProps.allowsSorting ? hoverProps : {},
          otherProps
        )}
        className={slots.th({
          align,
          className: cn(classNames?.th, className, columnProps.className),
        })}
      >
        {cell}
        {columnProps.allowsSorting && (
          <FontAwesomeIcon
            icon={faChevronDown}
            size="xs"
            aria-hidden
            data-direction={state.sortDescriptor?.direction ?? "ascending"}
            data-visible={state.sortDescriptor?.column === node.key}
            className={slots.sortIcon({ className: classNames?.sortIcon })}
          />
        )}
      </th>
    );
  }
);
TableColumnHeader.displayName = "Table.ColumnHeader";
