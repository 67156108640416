import { forwardRef } from "react";
import { tv } from "tailwind-variants";

import type { ComponentPropsWithoutRef } from "react";
import type { VariantProps } from "tailwind-variants";

const dividerVariants = tv({
  base: "m-0 shrink-0 border-none bg-gray-50 dark:bg-gray-800",
  variants: {
    orientation: {
      horizontal: "h-px w-full",
      vertical: "h-full w-px",
    },
  },
  defaultVariants: {
    orientation: "horizontal",
  },
});

interface DividerProps
  extends ComponentPropsWithoutRef<"hr">,
    VariantProps<typeof dividerVariants> {}

export const Divider = forwardRef<HTMLHRElement, DividerProps>(
  ({ className, orientation, ...props }, ref) => {
    const base = dividerVariants({ className, orientation });

    return <hr ref={ref} className={base} {...props} />;
  }
);
Divider.displayName = "Divider";
