import type { Range } from "./types/generic";

/**
 * Maps a value from an input range to a target range.
 *
 * Adapted from https://stackoverflow.com/a/5732390.
 * @param args.value The value to map, in the input range.
 * @param args.fromRange The input range.
 * @param args.toRange The target range.
 * @returns A value mapped to the target range.
 */
export const mapValue = (value: number, fromRange: Range, toRange: Range): number => {
  if (value < fromRange.start || value > fromRange.end) {
    throw Error(`Value ${value} is out of range from fromRange ${fromRange}`);
  }

  const slope = (1.0 * (toRange.end - toRange.start)) / (fromRange.end - fromRange.start);
  return toRange.start + Math.fround(slope * (value - fromRange.start));
};
