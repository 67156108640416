import { forwardRef } from "react";

import { cn } from "../../utils/cn";
import { Heading } from "../typography/Heading";
import { Paragraph } from "../typography/Paragraph";
import { useCardContext } from "./context";

import type { ComponentPropsWithoutRef } from "react";
import type { ForwardRefComponentWithSubcomponents } from "../../utils/types";
import type { HeadingProps } from "../typography/Heading";

export const CardHeader = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    const { slots, classNames } = useCardContext();

    return (
      <div
        ref={ref}
        className={slots.header({ className: cn(classNames?.header, className) })}
        {...props}
      />
    );
  }
) as ForwardRefComponentWithSubcomponents<
  HTMLDivElement,
  {
    Title: typeof CardTitle;
    Subtitle: typeof CardSubtitle;
    Main: typeof CardHeaderMain;
    Extra: typeof CardHeaderExtra;
  }
>;
CardHeader.displayName = "Card.Header";

const CardHeaderMain = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    const { slots, classNames } = useCardContext();

    return (
      <div
        ref={ref}
        className={slots.headerMain({ className: cn(classNames?.headerMain, className) })}
        {...props}
      />
    );
  }
);
CardHeaderMain.displayName = "Card.Header.Main";
CardHeader.Main = CardHeaderMain;

const CardHeaderExtra = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    const { slots, classNames } = useCardContext();

    return (
      <div
        ref={ref}
        className={slots.headerExtra({ className: cn(classNames?.headerExtra, className) })}
        {...props}
      />
    );
  }
);
CardHeaderExtra.displayName = "Card.Header.Extra";
CardHeader.Extra = CardHeaderExtra;

const CardTitle = forwardRef<HTMLHeadingElement, HeadingProps>(({ className, ...props }, ref) => {
  const { slots, classNames } = useCardContext();

  return (
    <Heading
      ref={ref}
      className={slots.title({ className: cn(classNames?.title, className) })}
      level={4}
      {...props}
    />
  );
});
CardTitle.displayName = "Card.Header.Title";
CardHeader.Title = CardTitle;

const CardSubtitle = forwardRef<HTMLParagraphElement, ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => {
    const { slots, classNames } = useCardContext();

    return (
      <Paragraph
        ref={ref}
        emphasis="tertiary"
        className={slots.subtitle({ className: cn(classNames?.subtitle, className) })}
        {...props}
      />
    );
  }
);
CardSubtitle.displayName = "Card.Header.Subtitle";
CardHeader.Subtitle = CardSubtitle;
