import { forwardRef } from "react";

import { cn } from "../../utils/cn";
import { Skeleton } from "../skeleton/Skeleton";

import type { FC, HTMLAttributes } from "react";
import type { ComponentWithAttachedSkeletonComponent } from "../../utils/types";

/**
 * A small and compact piece of information. Typically you will use multiple of these in a compound
 * view like a list item.
 */
const Detail = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        "flex flex-row whitespace-nowrap text-sm font-medium leading-none text-gray-850 dark:text-white",
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
) as ComponentWithAttachedSkeletonComponent<HTMLDivElement>;
Detail.displayName = "Detail";

const DetailSkeleton: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => (
  <div className={cn("h-3.5", className)} {...props}>
    <Skeleton className="h-full w-24" />
  </div>
);

Detail.Skeleton = DetailSkeleton;

export { Detail };
