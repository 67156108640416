import type { GPSPoint, LngLat, LngLatAltTuple } from "./types/geospatial";

type AltitudeBounds = { minAltitude?: number; maxAltitude?: number };
type Options = AltitudeBounds;

/**
 * Check if the altitude property of a GPS point is valid.
 *
 * It is considered to be valid if all bounds are null, or if it is a number and is between the defined bounds
 * @param altitude
 * @param bounds
 * @returns a boolean saying if the altitude is valid
 */
const isAltitudeValid = (
  altitude: number | undefined,
  { maxAltitude, minAltitude }: AltitudeBounds
) => {
  if (altitude !== undefined) {
    if (minAltitude && altitude < minAltitude) return false;
    if (maxAltitude && altitude > maxAltitude) return false;
    return true;
  }
  return maxAltitude === undefined && minAltitude === undefined;
};

/**
 * Function that checks if a GPS point is valid or not
 * A point is valid if it has at least latitude and longitude and they are
 * in the right interval:
 * latitude [-90;90]
 * longitude [-180;180]
 * See above function for altitude validation
 * @param point
 * @returns a boolean that is true if the point is valid
 */
export const isGpsPointValid = (point: GPSPoint, { maxAltitude, minAltitude }: Options = {}) => {
  return (
    point.lat >= -90 &&
    point.lat <= 90 &&
    point.lng >= -180 &&
    point.lng <= 180 &&
    isAltitudeValid(point.alt, { maxAltitude, minAltitude })
  );
};

/**
 * Tells you whether a `LngLat` is null island or not.
 * @param location The location to check.
 * @returns `true` if the location is at 0,0.
 */
export function isNullIsland(location: LngLat): boolean {
  return location.lat === 0 && location.lng === 0;
}

/**
 * Returns `gpsPoint` flattened in an array with [lng, lat, alt?]
 */
export const gpsPointToArray = (gpsPoint: GPSPoint): LngLatAltTuple => [
  gpsPoint.lng,
  gpsPoint.lat,
  gpsPoint.alt,
];

/**
 * Returns `[lng, lat, alt?]` as a gpsPoint type
 */
export const arrayToGpsPoint = (array: LngLatAltTuple): GPSPoint => ({
  lng: array[0],
  lat: array[1],
  alt: array?.[2],
});
