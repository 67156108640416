import { forwardRef } from "react";
import { mergeProps, useFocusRing, useTabPanel } from "react-aria";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";

import type { ComponentPropsWithoutRef } from "react";
import type { AriaTabPanelProps, Key } from "react-aria";
import type { TabListState } from "react-stately";
import type { TabsClassNames, TabsSlots } from "./Tabs";

export interface TabPanelProps extends AriaTabPanelProps, ComponentPropsWithoutRef<"div"> {
  destroyInactiveTabPanel: boolean;
  tabKey: Key;
  state: TabListState<unknown>;
  slots: TabsSlots;
  classNames?: TabsClassNames;
}

export const TabPanel = forwardRef<HTMLDivElement, TabPanelProps>(
  ({ destroyInactiveTabPanel, tabKey, state, className, slots, classNames, ...props }, ref) => {
    const domRef = useDomRef(ref);

    const { tabPanelProps } = useTabPanel({ ...props, id: String(tabKey) }, state, domRef);

    const { focusProps, isFocused, isFocusVisible } = useFocusRing();

    const selectedItem = state.selectedItem;
    const isSelected = tabKey === selectedItem?.key;

    const content = state.collection.getItem(tabKey)?.props.children;

    if (!content || (!isSelected && destroyInactiveTabPanel)) {
      return null;
    }

    return (
      <div
        ref={domRef}
        data-slot="panel"
        data-focus={isFocused}
        data-focus-visible={isFocusVisible}
        data-inert={!isSelected ? "true" : undefined}
        {...(isSelected && mergeProps(tabPanelProps, focusProps, props))}
        className={slots.panel({
          className: cn(classNames?.panel, className, selectedItem?.props.className),
        })}
        {...props}
      >
        {content}
      </div>
    );
  }
);
TabPanel.displayName = "Tabs.TabPanel";
