import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneElement, forwardRef, isValidElement, useMemo } from "react";
import { mergeProps, useFocusRing, usePress } from "react-aria";
import { tv } from "tailwind-variants";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";

import type { HTMLAttributes, ReactNode } from "react";
import type { VariantProps } from "tailwind-variants";
import type { SlotsToClasses } from "../../utils/types";

const tag = tv({
  slots: {
    base: [
      "text-xs",
      "inline-flex",
      "items-center",
      "gap-x-1",
      "whitespace-nowrap",
      "transition-colors",
      "focus-visible:outline-none",
      "focus-visible:ring-offset-0",
      "focus-visible:ring-1",
      "focus-visible:ring-blue-300",
    ],
    content: "text-inherit flex px-1",
    removeButton: [
      "text-xs",
      "text-current",
      "cursor-pointer",
      "appearance-none",
      "outline-none",
      "select-none",
      "opacity-60",
      "transition-opacity",
      "hover:bg-transparent",
      "hover:opacity-90",
    ],
  },
  variants: {
    variant: {
      solid: {},
      bordered: {
        base: "box-border border border-solid",
      },
    },
    color: {
      default: { base: "dark:text-white" },
      primary: {},
      red: {},
      yellow: {},
      green: {},
      magenta: {},
      cyan: {},
    },
    size: {
      sm: { base: "h-6 rounded px-1" },
      md: { base: "h-7 rounded-md px-2", content: "font-semibold" },
      lg: {
        base: "h-8 rounded-md px-2 text-sm",
        content: "font-semibold",
        removeButton: "text-sm",
      },
    },
    hasStartContent: {
      true: {},
      false: {},
    },
    hasEndContent: {
      true: {},
      false: {},
    },
    isDisabled: {
      true: {
        base: "pointer-events-none opacity-60",
      },
    },
  },
  compoundVariants: [
    {
      variant: "solid",
      color: "default",
      className: {
        base: "bg-gray-50 text-gray-800 dark:bg-gray-700",
      },
    },
    {
      variant: "bordered",
      color: "default",
      className: {
        base: "border-gray-150 bg-gray-50 dark:border-gray-700 dark:bg-gray-800",
      },
    },
    {
      variant: "solid",
      color: "primary",
      className: {
        base: "bg-blue text-white",
      },
    },
    {
      variant: "bordered",
      color: "primary",
      className: {
        base: "border-blue-400 bg-blue/10 text-blue dark:border-blue dark:bg-blue/20 dark:text-blue-400",
      },
    },
    {
      variant: "solid",
      color: "red",
      className: {
        base: "bg-red text-white",
      },
    },
    {
      variant: "bordered",
      color: "red",
      className: {
        base: "border-red-400 bg-red/10 text-red dark:border-red dark:bg-red/20 dark:text-red-400",
      },
    },
    {
      variant: "solid",
      color: "yellow",
      className: {
        base: "bg-yellow text-white",
      },
    },
    {
      variant: "bordered",
      color: "yellow",
      className: {
        base: "border-yellow-400 bg-yellow/10 text-yellow dark:border-yellow dark:bg-yellow/20 dark:text-yellow-400",
      },
    },
    {
      variant: "solid",
      color: "green",
      className: {
        base: "bg-green text-white",
      },
    },
    {
      variant: "bordered",
      color: "green",
      className: {
        base: "border-green-400 bg-green/10 text-green dark:border-green dark:bg-green/20 dark:text-green-400",
      },
    },
    {
      variant: "solid",
      color: "magenta",
      className: {
        base: "bg-magenta text-white",
      },
    },
    {
      variant: "bordered",
      color: "magenta",
      className: {
        base: "border-magenta-400 bg-magenta/10 text-magenta dark:border-magenta dark:bg-magenta/20 dark:text-magenta-400",
      },
    },
    {
      variant: "solid",
      color: "cyan",
      className: {
        base: "bg-cyan text-white",
      },
    },
    {
      variant: "bordered",
      color: "cyan",
      className: {
        base: "border-cyan-400 bg-cyan/10 text-cyan dark:border-cyan dark:bg-cyan/20 dark:text-cyan-400",
      },
    },
    {
      size: "sm",
      hasEndContent: true,
      className: {
        content: "pr-0",
      },
    },
    {
      size: ["md", "lg"],
      hasEndContent: true,
      className: {
        content: "pr-0.5",
      },
    },
    {
      size: "sm",
      hasStartContent: true,
      className: {
        content: "pl-0",
      },
    },
    {
      size: ["md", "lg"],
      hasStartContent: true,
      className: {
        content: "pl-0.5",
      },
    },
  ],
  defaultVariants: {
    variant: "solid",
    color: "default",
    size: "md",
    isDisabled: false,
  },
});

const cloneContent = (content: ReactNode) =>
  isValidElement(content)
    ? cloneElement(content, { className: cn("max-h-[80%]", content.props.className) })
    : null;

type TagClassNames = SlotsToClasses<keyof ReturnType<typeof tag>>;

export interface TagProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "color">,
    Omit<VariantProps<typeof tag>, "hasEndContent"> {
  startContent?: ReactNode;
  endContent?: ReactNode;
  onRemove?: () => void;
  classNames?: TagClassNames;
}

export const Tag = forwardRef<HTMLDivElement, TagProps>(
  (
    {
      className,
      variant,
      color,
      size,
      isDisabled,
      children,
      startContent: startContentProp,
      endContent: endContentProp,
      onRemove,
      classNames,
      ...props
    },
    ref
  ) => {
    const domRef = useDomRef(ref);

    const isRemovable = !!onRemove;
    const hasStartContent = !!startContentProp;
    const hasEndContent = !!endContentProp || !!onRemove;

    const slots = useMemo(
      () =>
        tag({
          variant,
          color,
          size,
          hasStartContent,
          hasEndContent,
        }),
      [variant, color, size, hasStartContent, hasEndContent]
    );

    const { focusProps } = useFocusRing();
    const { pressProps } = usePress({ isDisabled, onPress: onRemove });

    const startContent = cloneContent(startContentProp);

    const endContent = useMemo(() => {
      const content = cloneContent(endContentProp);
      if (isRemovable) {
        return (
          <span
            role="button"
            aria-label="close tag"
            tabIndex={0}
            className={slots.removeButton({ className: classNames?.removeButton })}
            {...mergeProps(focusProps, pressProps)}
          >
            {content || <FontAwesomeIcon fixedWidth icon={faXmark} />}
          </span>
        );
      }
      return content;
    }, [endContentProp, isRemovable, slots, focusProps, pressProps, classNames?.removeButton]);

    return (
      <div ref={domRef} className={slots.base({ className })} {...props}>
        {startContent}
        <span className={slots.content({ className: classNames?.content })}>{children}</span>
        {endContent}
      </div>
    );
  }
);
Tag.displayName = "Tag";
