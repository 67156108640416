import { forwardRef } from "react";

import { cn } from "../../utils/cn";
import { useCardContext } from "./context";

import type { ComponentPropsWithoutRef } from "react";

export const CardBody = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    const { slots, classNames } = useCardContext();

    return (
      <div
        ref={ref}
        className={slots.body({ className: cn(classNames?.body, className) })}
        {...props}
      />
    );
  }
);
CardBody.displayName = "CardBody";
