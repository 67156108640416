import { forwardRef } from "react";

import { cn } from "../../utils/cn";
import { Paragraph } from "../typography/Paragraph";
import { useAlertContext } from "./context";

import type { ComponentPropsWithoutRef } from "react";

export const AlertDescription = forwardRef<HTMLParagraphElement, ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => {
    const { slots, classNames } = useAlertContext();

    return (
      <Paragraph
        className={slots.description({ className: cn(className, classNames?.description) })}
        ref={ref}
      >
        {props.children}
      </Paragraph>
    );
  }
);

AlertDescription.displayName = "Alert.Description";
