// TODO(Alberto): See if this file is still needed or not at all. We could
// just remove it, or decide to keep it so that we can use that method if needed.
import { getDistanceScales, lngLatToWorld } from "@math.gl/web-mercator";
import { MercatorCoordinate } from "mapbox-gl";

import { EARTH_RADIUS } from "./constants";

import type { Tuple3 } from "@skydio/core";
import type { LngLatAltTuple, ThreeLocation, WebMercatorLocation } from "./types/geospatial";
import type { Meters } from "./types/semantic";

/**
 * Compute a tranform from webmercator format to ThreeJS transformations format, using Math.gl.
 */
export const computeWebMercatorThreeTransformMathgl = ({
  originLngLatAlt,
  rotate,
  scale,
}: WebMercatorLocation): ThreeLocation => {
  const modelOriginWorld = lngLatToWorld([originLngLatAlt[0], originLngLatAlt[1]]) as [
    number,
    number,
  ];
  const distanceScales = getDistanceScales({
    longitude: originLngLatAlt[0],
    latitude: originLngLatAlt[1],
  });
  const unitsPerMeter = distanceScales.unitsPerMeter as [number, number, number];
  return {
    translate: [modelOriginWorld[0], modelOriginWorld[1], unitsPerMeter[2] * originLngLatAlt[2]],
    rotate: rotate,
    scale: [unitsPerMeter[0] * scale[0], unitsPerMeter[1] * scale[1], unitsPerMeter[2] * scale[2]],
  };
};

export const compute3DCartesianPositionFromGPSLocation = (args: {
  gpsLocation: LngLatAltTuple;
  sceneOrigin: LngLatAltTuple;
  /**
   * Altitude (i.e. Y Position) to be used in case either `gpsLocation` or `sceneOrigin` don't have an altitude set.
   *
   * Default is 0.
   */
  fallbackAltitude?: number;
}): Tuple3<Meters> => {
  const { gpsLocation, sceneOrigin, fallbackAltitude } = args;
  const locationMercator = MercatorCoordinate.fromLngLat(
    [gpsLocation[0], gpsLocation[1]],
    gpsLocation[2]
  );

  const sceneOriginMercator = MercatorCoordinate.fromLngLat(
    [sceneOrigin[0], sceneOrigin[1]],
    sceneOrigin[2]
  );

  // Calculate the scaling factor for the scene - this is the number of Mercator units per meter at the scene origin
  const sceneOriginMercatorUnitsPerMeter = sceneOriginMercator.meterInMercatorCoordinateUnits();

  const mercatorX = (locationMercator.x - sceneOriginMercator.x) / sceneOriginMercatorUnitsPerMeter;
  const mercatorZ = (locationMercator.y - sceneOriginMercator.y) / sceneOriginMercatorUnitsPerMeter;

  // If the scene defines an altitude and the location we're converting does too
  //
  // Sadly the Mapbox Typescript API doesn't match the behavior of its actual JS implementation,
  // and passing `undefined` to `fromLngLat` doesn't result in the Mercator object return to have an
  // `undefined` `z` property, but rather, to have a value of 0.
  // Hence, to distinguish this case, we need to also check the original values.
  if (
    gpsLocation[2] !== undefined &&
    sceneOrigin[2] !== undefined &&
    sceneOriginMercator.z !== undefined &&
    locationMercator.z !== undefined
  ) {
    return [
      mercatorX,
      (locationMercator.z - sceneOriginMercator.z) / sceneOriginMercatorUnitsPerMeter,
      mercatorZ,
    ];
  } else {
    return [mercatorX, fallbackAltitude ?? 0, mercatorZ];
  }
};

export const computeMSLAltitudeFromCartesianYPosition = (args: {
  cartesianY: Meters;
  sceneOrigin: LngLatAltTuple;
}): Meters | undefined => {
  const { cartesianY, sceneOrigin } = args;
  return sceneOrigin[2] !== undefined ? cartesianY + sceneOrigin[2] : undefined;
};

/**
 * Returns a Web Mercator coordinate calculated by adding `cartesian3DPosition`
 * to `sceneOrigin`, with the appropriate scaling applied to `cartesian3DPosition`.
 */
export const computeMercatorLocationFrom3DCartesianPosition = (args: {
  cartesian3DPosition: Tuple3<Meters>;
  sceneOrigin: MercatorCoordinate;
}): MercatorCoordinate => {
  const { cartesian3DPosition, sceneOrigin } = args;
  const unitsPerMeter = sceneOrigin.meterInMercatorCoordinateUnits();

  const mercatorX = cartesian3DPosition[0] * unitsPerMeter + sceneOrigin.x;
  const mercatorY = cartesian3DPosition[2] * unitsPerMeter + sceneOrigin.y;
  const mercatorZ = cartesian3DPosition[1] * unitsPerMeter + (sceneOrigin.z ?? 0);

  return new MercatorCoordinate(mercatorX, mercatorY, mercatorZ);
};

export const computeGPSLocationFrom3DCartesianPosition = (args: {
  threeJSPosition: Tuple3<Meters>;
  sceneOrigin: LngLatAltTuple;
}): LngLatAltTuple => {
  const { threeJSPosition, sceneOrigin } = args;
  const sceneOriginMercator = MercatorCoordinate.fromLngLat(
    [sceneOrigin[0], sceneOrigin[1]],
    sceneOrigin[2]
  );

  const mercator = computeMercatorLocationFrom3DCartesianPosition({
    cartesian3DPosition: threeJSPosition,
    sceneOrigin: sceneOriginMercator,
  });

  const locationGPS = mercator.toLngLat();
  return [locationGPS.lng, locationGPS.lat, mercator.toAltitude()];
};

/**
 * @remarks
 * Ported directly from MapboxGL's codebase.
 *
 * @see
 * https://github.com/mapbox/mapbox-gl-js/blob/af9c3d46afb3eb88af319cab77908c1ccd55899f/src/geo/mercator_coordinate.js#L34
 *
 * @private
 * TODO: Import this into Typescript directly from MapboxGL using a `.d.ts` file.
 */
export function latFromMercatorY(y: number): number {
  const y2 = 180 - y * 360;
  return (360 / Math.PI) * Math.atan(Math.exp((y2 * Math.PI) / 180)) - 90;
}

/**
 * The circumference at a line of latitude in meters.
 *
 * @remarks
 * Ported directly from MapboxGL's codebase.
 *
 * @see
 * https://github.com/mapbox/mapbox-gl-js/blob/af9c3d46afb3eb88af319cab77908c1ccd55899f/src/geo/mercator_coordinate.js#L14
 *
 * @private
 * TODO: Import this into Typescript directly from MapboxGL using a `.d.ts` file.
 */
export function circumferenceAtLatitude(latitude: number): number {
  // The average circumference of the earth in meters
  const earthCircumference = 2 * Math.PI * EARTH_RADIUS;
  return earthCircumference * Math.cos((latitude * Math.PI) / 180);
}

/**
 * @remarks
 * Ported directly from MapboxGL's codebase.
 *
 * @see
 * https://github.com/mapbox/mapbox-gl-js/blob/af9c3d46afb3eb88af319cab77908c1ccd55899f/src/geo/mercator_coordinate.js#L26
 *
 * @private
 * TODO: Import this into Typescript directly from MapboxGL using a `.d.ts` file.
 */
export function mercatorZfromAltitude(altitude: number, lat: number): number {
  return altitude / circumferenceAtLatitude(lat);
}
