import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { forwardRef } from "react";
import { DismissButton, mergeProps, useDialog } from "react-aria";

import { fade, modalScaleInOut } from "../../utils/transitionVariants";
import { useModalContext } from "./context";

import type { HTMLMotionProps } from "framer-motion";
import type { HTMLAttributes, ReactNode } from "react";
import type { AriaDialogProps } from "react-aria";

export interface ModalContentProps
  extends AriaDialogProps,
    Omit<HTMLAttributes<HTMLDivElement>, "children" | "role"> {
  children: ReactNode | ((onClose: () => void) => ReactNode);
}

export const ModalContent = forwardRef<HTMLDivElement, ModalContentProps>(
  ({ children, ...props }, _) => {
    const {
      domRef,
      backdropProps,
      closeButtonProps,
      getDialogProps,
      hideCloseButton,
      slots,
      classNames,
      onClose,
    } = useModalContext();

    const { dialogProps } = useDialog({ role: "dialog" }, domRef);

    return (
      <div tabIndex={-1}>
        <motion.div
          animate="enter"
          exit="exit"
          initial="exit"
          variants={fade}
          {...(backdropProps as HTMLMotionProps<"div">)}
        />
        <motion.div
          animate="enter"
          className={slots.wrapper({ className: classNames?.wrapper })}
          data-slot="wrapper"
          exit="exit"
          initial="exit"
          variants={modalScaleInOut}
        >
          <div {...getDialogProps(mergeProps(dialogProps, props))}>
            <DismissButton onDismiss={onClose} />
            {!hideCloseButton && (
              <button {...closeButtonProps}>
                <FontAwesomeIcon icon={faXmark} />
              </button>
            )}
            {typeof children === "function" ? children(onClose) : children}
          </div>
        </motion.div>
      </div>
    );
  }
);
ModalContent.displayName = "Modal.Content";
