import { AnimatePresence } from "framer-motion";
import { forwardRef } from "react";
import { Overlay } from "react-aria";

import { ModalProvider } from "./context";
import { ModalBody } from "./ModalBody";
import { ModalContent } from "./ModalContent";
import { ModalDescription } from "./ModalDescription";
import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";
import { ModalTitle } from "./ModalTitle";
import { useModal } from "./useModal";

import type { ForwardRefComponentWithSubcomponents } from "../../utils/types";
import type { UseModalProps } from "./useModal";

export type ModalProps = UseModalProps;

export const Modal = forwardRef<HTMLDivElement, UseModalProps>(({ children, ...props }, ref) => {
  const context = useModal(props, ref);

  const overlay = <Overlay portalContainer={context.portalContainer}>{children}</Overlay>;

  return (
    <ModalProvider value={context}>
      <AnimatePresence>{context.isOpen ? overlay : null}</AnimatePresence>
    </ModalProvider>
  );
}) as ForwardRefComponentWithSubcomponents<
  HTMLDivElement,
  {
    Content: typeof ModalContent;
    Body: typeof ModalBody;
    Header: typeof ModalHeader;
    Footer: typeof ModalFooter;
    Title: typeof ModalTitle;
    Description: typeof ModalDescription;
  },
  ModalProps
>;
Modal.displayName = "Modal";
Modal.Content = ModalContent;
Modal.Body = ModalBody;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
Modal.Title = ModalTitle;
Modal.Description = ModalDescription;
