import { forwardRef } from "react";
import { mergeProps, useTableRowGroup } from "react-aria";

import { cn } from "../../../utils/cn";

import type { HTMLAttributes } from "react";
import type { TableClassNames, TableSlots } from "../tableVariants";

interface TableHeaderInternalProps extends HTMLAttributes<HTMLTableSectionElement> {
  slots: TableSlots;
  classNames?: TableClassNames;
}

export const TableHeader = forwardRef<HTMLTableSectionElement, TableHeaderInternalProps>(
  ({ children, className, classNames, slots, ...otherProps }, ref) => {
    const { rowGroupProps } = useTableRowGroup();

    return (
      <thead
        ref={ref}
        className={slots.thead({ className: cn(classNames?.thead, className) })}
        {...mergeProps(rowGroupProps, otherProps)}
      >
        {children}
      </thead>
    );
  }
);
TableHeader.displayName = "Table.Header";
