import { forwardRef } from "react";

import { cn } from "../../utils/cn";
import { useAlertContext } from "./context";

import type { ComponentPropsWithoutRef } from "react";

export const AlertContent = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    const { slots, classNames } = useAlertContext();

    return (
      <div className={slots.content({ className: cn(className, classNames?.content) })} ref={ref}>
        {props.children}
      </div>
    );
  }
);

AlertContent.displayName = "Alert.Content";
