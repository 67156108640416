import { forwardRef, useEffect } from "react";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";
import { useModalContext } from "./context";

import type { ComponentPropsWithoutRef } from "react";

export const ModalHeader = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"header">>(
  ({ children, className, ...props }, ref) => {
    const { slots, classNames, headerId, setHeaderMounted } = useModalContext();

    const domRef = useDomRef(ref);

    useEffect(() => {
      setHeaderMounted(true);

      return () => setHeaderMounted(false);
    }, [setHeaderMounted]);

    return (
      <header
        ref={domRef}
        className={slots.header({ className: cn(classNames?.header, className) })}
        id={headerId}
        {...props}
      >
        {children}
      </header>
    );
  }
);
ModalHeader.displayName = "Modal.Header";
