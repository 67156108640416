import { createContext } from "../../utils/createContext";

import type { CardClassNames, CardSlots } from "./cardVariants";

export interface CardContext {
  slots: CardSlots;
  classNames?: CardClassNames;
  isDisabled?: boolean;
  fullWidth?: boolean;
}

export const [CardProvider, useCardContext] = createContext<CardContext>({ name: "CardContext" });
