import { faSearch, faSpinnerThird, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mergeProps } from "@react-aria/utils";
import { createContext, forwardRef, useContext, useMemo } from "react";

import { mergeRefs } from "../../utils/refs";
import { useControllerSafe } from "../form/useControllerSafe";
import { useInput } from "./useInput";

import type { FC, HTMLAttributes } from "react";
import type { DOMAttributes, ForwardRefComponentWithSubcomponents } from "../../utils/types";
import type { UseInputProps } from "./useInput";

export type InputProps = Omit<UseInputProps, "isMultiline">;

const inputContext = createContext<DOMAttributes<HTMLInputElement>>({});

const InputInner: FC<HTMLAttributes<HTMLInputElement>> = props => {
  const propsFromContext = useContext(inputContext);

  const allProps = mergeProps(props, propsFromContext);

  return <input {...allProps} />;
};

/**
 * This component renders a text input.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(({ children, ...props }, ref) => {
  const formController = useControllerSafe({ name: props.name ?? "" });

  if (formController && !props.name) {
    throw new Error("Input used inside a Form component must have a name prop");
  }

  const { error: formError, invalid: formInvalid } = formController?.fieldState ?? {};

  const formInputProps = useMemo(
    () =>
      formController?.field
        ? {
            onValueChange: (value: string) => formController.field.onChange(value),
            value: formController.field.value,
            name: formController.field.name,
            onBlur: formController.field.onBlur,
            isDisabled: formController.field.disabled,
            isInvalid: formInvalid,
            errorMessage: formError?.message,
          }
        : undefined,
    [formController?.field, formInvalid, formError]
  );

  const {
    label,
    description,
    isClearable,
    isSearch,
    isLoading,
    startContent,
    endContent,
    labelPlacement,
    hasHelper,
    errorMessage,
    baseProps,
    labelProps,
    inputProps,
    innerWrapperProps,
    inputWrapperProps,
    mainWrapperProps,
    helperWrapperProps,
    descriptionProps,
    errorMessageProps,
    clearButtonProps,
    startContentProps,
  } = useInput({
    ...(formInputProps ? mergeProps(props, formInputProps) : props),
    ref: formController?.field ? mergeRefs(ref, formController.field.ref) : ref,
  });

  const labelContent = useMemo(
    () => (label ? <label {...labelProps}>{label}</label> : null),
    [label, labelProps]
  );

  const start = useMemo(() => {
    if (isLoading) {
      return <FontAwesomeIcon icon={faSpinnerThird} spin />;
    }

    if (isSearch) {
      return <FontAwesomeIcon icon={faSearch} />;
    }

    return startContent;
  }, [startContent, isSearch, isLoading]);

  const end = useMemo(() => {
    if (isClearable) {
      return <span {...clearButtonProps}>{endContent || <FontAwesomeIcon icon={faXmark} />}</span>;
    }

    return endContent;
  }, [isClearable, endContent, clearButtonProps]);

  const helperWrapper = useMemo(() => {
    if (!hasHelper) return null;

    return (
      <div {...helperWrapperProps}>
        {errorMessage ? (
          <div {...errorMessageProps}>{errorMessage}</div>
        ) : description ? (
          <div {...descriptionProps}>{description}</div>
        ) : null}
      </div>
    );
  }, [
    hasHelper,
    errorMessage,
    description,
    helperWrapperProps,
    errorMessageProps,
    descriptionProps,
  ]);

  const innerWrapper = useMemo(() => {
    const inputElement = (
      <inputContext.Provider value={inputProps}>{children || <InputInner />}</inputContext.Provider>
    );

    if (start || end) {
      return (
        <div {...innerWrapperProps}>
          {start && <span {...startContentProps}>{start}</span>}
          {inputElement}
          {end}
        </div>
      );
    }

    return <div {...innerWrapperProps}>{inputElement}</div>;
  }, [children, start, end, inputProps, innerWrapperProps, startContentProps]);

  const mainWrapper = useMemo(() => {
    return (
      <div {...mainWrapperProps}>
        <div {...inputWrapperProps}>
          {labelPlacement !== "left" ? labelContent : null}
          {innerWrapper}
        </div>
        {helperWrapper}
      </div>
    );
  }, [
    labelPlacement,
    helperWrapper,
    labelContent,
    innerWrapper,
    mainWrapperProps,
    inputWrapperProps,
  ]);

  return (
    <div {...baseProps}>
      {labelPlacement === "left" ? labelContent : null}
      {mainWrapper}
    </div>
  );
}) as ForwardRefComponentWithSubcomponents<
  HTMLInputElement,
  {
    Input: typeof InputInner;
    Search: typeof SearchInput;
  },
  InputProps
>;
Input.displayName = "Input";

const SearchInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return (
    <Input
      placeholder="Search"
      startContent={<FontAwesomeIcon icon={faSearch} />}
      {...props}
      ref={ref}
    />
  );
});
SearchInput.displayName = "Input.Search";

Input.Input = InputInner;
Input.Search = SearchInput;
