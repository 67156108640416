import { forwardRef } from "react";
import { mergeProps, useTableHeaderRow } from "react-aria";

import { useDomRef } from "../../../hooks/useDomRef";
import { cn } from "../../../utils/cn";
import { filterDOMProps } from "../../../utils/filterDomProps";

import type { GridNode } from "@react-types/grid";
import type { HTMLAttributes } from "react";
import type { TableState } from "react-stately";
import type { TableClassNames, TableSlots } from "../tableVariants";

interface TableHeaderRowProps<T = object> extends HTMLAttributes<HTMLTableRowElement> {
  node: GridNode<T>;
  state: TableState<T>;
  slots: TableSlots;
  classNames?: TableClassNames;
}

export const TableHeaderRow = forwardRef<HTMLTableRowElement, TableHeaderRowProps>(
  ({ node, state, children, className, classNames, slots, ...otherProps }, ref) => {
    const domRef = useDomRef(ref);

    const { rowProps } = useTableHeaderRow({ node }, state, domRef);

    return (
      <tr
        ref={ref}
        {...mergeProps(rowProps, filterDOMProps(node.props), otherProps)}
        className={slots.tr({ className: cn(classNames?.tr, className, node.props?.className) })}
      >
        {children}
      </tr>
    );
  }
);
TableHeaderRow.displayName = "Table.HeaderRow";
