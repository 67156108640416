export const formLabel = [
  "block",
  "absolute",
  "z-10",
  "origin-top-left",
  "subpixel-antialiased",
  "font-semibold",
  "text-sm",
  "text-gray-500",
  "dark:text-gray-100",
  "pointer-events-none",
  "whitespace-nowrap",
];

export const dataFocusVisible = [
  "outline-none",
  "data-[focus-visible=true]:z-10",
  "data-[focus-visible=true]:outline-2",
  "data-[focus-visible=true]:outline-focus",
  "data-[focus-visible=true]:outline-offset-2",
];
