import { forwardRef, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from "react";

import {
  compute3DCartesianPositionFromGPSLocation,
  computeGPSLocationFrom3DCartesianPosition,
  convertENUTupleToEUSTuple,
  convertEUSTupleToENUTuple,
} from "@skydio/math";

import { cn } from "../../utils/cn";

import type { FC, HTMLAttributes } from "react";

const MAPBOX_STATIC_API_BASE_URL = (theme: "light" | "dark") =>
  `https://api.mapbox.com/styles/v1/mapbox/${theme}-v11/static/`;
// Mapbox always puts a massive watermark at the bottom which is pretty obstructive for these small
// images. We need to do some math to crop off the bottom but still calculate the viewport properly
const MAPBOX_IMAGE_BRANDING_HEIGHT = 16; // pixels
// If we only have one point show an area this large on the map
const DEFAULT_AREA_WIDTH = 500; // meters
// Add this much padding around the path
const PATH_PADDING_RATIO = 0.125;

interface BackgroundProps {
  x: number;
  y: number;
}

/**
 * This is a placeholder background for when we aren't able to localize points in GPS frame.
 * Looks like a generic floor plan
 */
const IndoorBackground: FC<BackgroundProps> = props => (
  <svg
    width="100%"
    height="100%"
    {...props}
    viewBox="0 0 1080 1080" // This scale is from the image in the design spec
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="1080" height="1080" fill="#F1F1F1" />
    <path d="M390 100H218H304L304 617L248 617H359.5" stroke="#D9D9D9" strokeWidth="20" />
    <path d="M607 100H435H521L521 617L465 617H576.5" stroke="#D9D9D9" strokeWidth="20" />
    <path d="M739 199V479.5V385H950.5" stroke="#D9D9D9" strokeWidth="20" />
    <path d="M952 801H739V616.5" stroke="#D9D9D9" strokeWidth="20" />
    <path d="M574.5 801H251.5H413V974.5" stroke="#D9D9D9" strokeWidth="20" />
    <path d="M129 393.5L129 100H951.5V820.5" stroke="#D9D9D9" strokeWidth="20" />
    <path d="M130.5 699L130.5 980H951.5V806.5" stroke="#D9D9D9" strokeWidth="20" />
  </svg>
);

/**
 * This is a placeholder background for when we don't have any points to show.
 * Looks like a crossed out location pin
 */
const EmptyBackground: FC<BackgroundProps> = props => (
  <svg
    width="100%"
    height="100%"
    {...props}
    viewBox="0 0 100 100" // Same as above, from the design spec
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1098_19575"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="100"
      height="100"
    >
      <path d="M0 3C0 1.34314 1.34315 0 3 0H100V100H0V3Z" fill="#B3B3B3" />
    </mask>
    <g mask="url(#mask0_1098_19575)">
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H100V100H4C1.79086 100 0 98.2091 0 96V4Z"
        fill="#F1F1F1"
      />
      <path
        d="M33.4766 36.1016L40.2734 41.375C42.207 38.0352 45.8398 35.75 50 35.75C56.2109 35.75 61.25 40.7891 61.25 47C61.25 49.2852 59.9023 52.332 58.1445 55.3789L68.1641 63.2891C68.8086 63.7578 68.9258 64.6367 68.3984 65.2227C67.9297 65.8672 67.0508 65.9844 66.4648 65.457L31.7773 38.2695C31.1328 37.8008 31.0156 36.9219 31.543 36.3359C32.0117 35.6914 32.8906 35.5742 33.4766 36.1016ZM42.5 43.1328L55.8594 53.6211C56.5039 52.5078 57.0898 51.3945 57.5 50.3984C58.1445 48.875 58.4375 47.7617 58.4375 47C58.4375 42.3711 54.6289 38.5625 50 38.5625C46.7188 38.5625 43.9062 40.4375 42.5 43.1328ZM42.3242 50.1641C42.3828 50.2227 42.3828 50.2812 42.4414 50.3984C43.0273 51.8047 43.9062 53.4453 44.9023 55.0273C46.6016 57.7812 48.5352 60.3594 49.9414 62.2344C50.8203 61.1797 51.8164 59.832 52.8125 58.4258L55.0391 60.125C53.6328 62.1172 52.2852 63.8164 51.3477 65.0469C50.6445 65.9258 49.2969 65.9258 48.5938 65.0469C45.6055 61.3555 38.9844 52.5078 38.75 47.293L42.3242 50.1641Z"
        fill="#999999"
      />
    </g>
  </svg>
);

interface CartesianPoint {
  x: number;
  y: number;
}

interface GPSPoint {
  latitude: number;
  longitude: number;
}

const arePointsGPS = (points: CartesianPoint[] | GPSPoint[]): points is GPSPoint[] => {
  return points.length > 0 && "latitude" in points[0];
};

/**
 * We want cartesian coordinates aligned with SITE frame so they need to be in ENU
 */
const convertGPSPointToCartesian = (point: GPSPoint, gpsOrigin: GPSPoint): CartesianPoint => {
  const threeJSPosition = compute3DCartesianPositionFromGPSLocation({
    gpsLocation: [point.longitude, point.latitude],
    sceneOrigin: [gpsOrigin.longitude, gpsOrigin.latitude],
  });
  const [x, y] = convertEUSTupleToENUTuple(threeJSPosition);
  return { x, y };
};

const convertCartesianPointToGPS = (point: CartesianPoint, gpsOrigin: GPSPoint): GPSPoint => {
  const [longitude, latitude] = computeGPSLocationFrom3DCartesianPosition({
    threeJSPosition: convertENUTupleToEUSTuple([point.x, point.y, 0]),
    sceneOrigin: [gpsOrigin.longitude, gpsOrigin.latitude],
  });
  return { latitude, longitude };
};

/**
 * The SVG coordinate system has the y-axis flipped (positive Y is down) so we need to transform
 * our cartesian coordinates accordingly. For simplicity we are leaving the viewbox the same and
 * then horizontally flipping the points within that area. This involves reflecting the y-coordinate
 * over the center line of the viewbox
 */
const transformCartesianYToSVG = (y: number, yMin: number, height: number) => {
  const yLineOfReflection = yMin + height / 2;
  // To reflect over a line y = a, we use the formula 2a - y
  // If you expand the formula out it's basically adding twice the distance between y and a to y
  return 2 * yLineOfReflection - y;
};

export interface FlightPathPreviewProps extends HTMLAttributes<SVGSVGElement> {
  points: CartesianPoint[] | GPSPoint[];
  primaryColor?: boolean;
  asBoundingBox?: boolean;
  gpsOrigin?: { latitude: number; longitude: number };
  mapboxToken?: string;
  theme?: "light" | "dark";
}

/**
 * This component renders a preview of a flight path as an SVG. It can either render a path as a
 * polyline or a bounding box as a polygon. If the points are GPS coordinates or can be localized
 * in GPS frame, it will also render an aligned mapbox tile in the background.
 */
export const FlightPathPreview = forwardRef<SVGSVGElement, FlightPathPreviewProps>(
  (
    {
      points,
      className,
      primaryColor,
      asBoundingBox,
      gpsOrigin,
      mapboxToken,
      theme = "light",
      ...props
    },
    ref
  ) => {
    // Keep track of view dimensions so we can calculate the correct mapbox image size
    const [dimensions, setDimensions] = useState<{ width: number; height: number }>();

    const innerRef = useRef<SVGSVGElement>(null);

    // Expose our ref to the parent component since we need to also use it inside here
    useImperativeHandle(ref, () => innerRef.current!, []);

    // This thumbnail shouldn't really change size so this should only happen on initial render
    useLayoutEffect(() => {
      if (!innerRef.current) return;

      setDimensions({
        width: innerRef.current.clientWidth,
        height: innerRef.current.clientHeight,
      });
    }, [setDimensions]);

    const { sideLength, xMin, yMin, cartesianPoints, gpsBoundingBox } = useMemo(() => {
      if (points.length === 0) {
        return {
          showMap: false,
          sideLength: 1,
          xMin: -0.5,
          yMin: -0.5,
          cartesianPoints: [] as CartesianPoint[],
        };
      }

      const showMap = !!dimensions && !!mapboxToken && (!!gpsOrigin || arePointsGPS(points));

      const cartesianPoints = arePointsGPS(points)
        ? points.map(point => convertGPSPointToCartesian(point, gpsOrigin ?? points[0]))
        : points;

      // Width and height of map view, in cartesian coordinates
      let sideLength: number;
      // Minimum x and y values of map view, in cartesian coordinates
      let xMin: number;
      let yMin: number;

      if (cartesianPoints.length === 1) {
        sideLength = DEFAULT_AREA_WIDTH;
        xMin = DEFAULT_AREA_WIDTH / -2;
        yMin = DEFAULT_AREA_WIDTH / -2;
      } else {
        const xCoords = cartesianPoints.map(point => point.x);
        const yCoords = cartesianPoints.map(point => point.y);

        const pathXMin = Math.min(...xCoords);
        const pathXMax = Math.max(...xCoords);
        const pathYMin = Math.min(...yCoords);
        const pathYMax = Math.max(...yCoords);
        const pathWidth = pathXMax - pathXMin;
        const pathHeight = pathYMax - pathYMin;

        // We add 1/8 padding around the sides of the path to make sure it's not right up against
        // the edges; the padding is based on the max dimension between height and width
        const maxDimension = Math.max(pathWidth, pathHeight);
        const viewPadding = maxDimension * PATH_PADDING_RATIO;
        sideLength = maxDimension + 2 * viewPadding;
        const xPadding = (sideLength - pathWidth) / 2;
        const yPadding = (sideLength - pathHeight) / 2;

        xMin = pathXMin - xPadding;
        yMin = pathYMin - yPadding;
      }

      // If the points are all in the same place for some reason this is probably bad data but we
      // can still try to show a map centered in the right place
      if (sideLength === 0) {
        sideLength = DEFAULT_AREA_WIDTH;
        // center the viewport on the single point, which is at (xMin, yMin) in this case
        xMin -= DEFAULT_AREA_WIDTH / 2;
        yMin -= DEFAULT_AREA_WIDTH / 2;
      }

      // This will be the bounding box we pass to the Mapbox API to get a map tile that aligns with
      // our path visualization
      let gpsBoundingBox: number[] | undefined;

      if (showMap) {
        const xMax = xMin + sideLength;
        const yMax = yMin + sideLength;

        // We increase the height of the bounding box for the requested map tile in order to clip
        // away the mapbox watermark. We are still in ENU coordinates (positive Y is up/north) at
        // this point so we decrease minimum Y to move the bottom down
        const mapYMin = yMin - sideLength * (MAPBOX_IMAGE_BRANDING_HEIGHT / dimensions.height);

        // Get northwest corner
        const { latitude: minLat, longitude: minLng } = convertCartesianPointToGPS(
          { x: xMin, y: mapYMin },
          gpsOrigin ?? (points[0] as GPSPoint)
        );
        // Get southeast corner
        const { latitude: maxLat, longitude: maxLng } = convertCartesianPointToGPS(
          { x: xMax, y: yMax },
          gpsOrigin ?? (points[0] as GPSPoint)
        );
        gpsBoundingBox = [minLng, minLat, maxLng, maxLat];
      }

      return { sideLength, xMin, yMin, gpsBoundingBox, cartesianPoints };
    }, [points, gpsOrigin, dimensions]);

    const mapboxBackgroundUrl = useMemo(() => {
      if (!dimensions || !dimensions.height || !dimensions.width || !gpsBoundingBox) return;

      const { width, height } = dimensions;
      const mapHeight = height + MAPBOX_IMAGE_BRANDING_HEIGHT;
      const bounds = gpsBoundingBox.map(coord => coord.toFixed(5)).join(",");

      return `${MAPBOX_STATIC_API_BASE_URL(theme)}[${bounds}]/${width}x${mapHeight}@2x?access_token=${mapboxToken}`;
    }, [theme, dimensions, gpsBoundingBox, mapboxToken]);

    const svgPoints = useMemo(
      () =>
        cartesianPoints.map(({ x, y }) => ({
          x,
          y: transformCartesianYToSVG(y, yMin, sideLength),
        })),
      [cartesianPoints, yMin, sideLength]
    );

    const svgPath = useMemo(() => svgPoints.map(({ x, y }) => `${x},${y}`).join(" "), [svgPoints]);

    const strokeColor = asBoundingBox
      ? "stroke-green-400"
      : primaryColor
        ? "stroke-blue-400" // "active" state means we've flown the mission show we show a primary color
        : "stroke-gray";

    // If we have more than 2 points, we draw a path back to the first waypoint to close the polygon
    const PathComponent = cartesianPoints.length > 2 ? "polygon" : "polyline";
    const BackgroundComponent = cartesianPoints.length === 0 ? EmptyBackground : IndoorBackground;

    return (
      <svg
        ref={innerRef}
        viewBox={`${xMin} ${yMin} ${sideLength} ${sideLength}`}
        className={cn("bg-gray-50", className)}
        {...props}
      >
        {mapboxBackgroundUrl ? (
          <image xlinkHref={mapboxBackgroundUrl} width="100%" x={xMin} y={yMin} />
        ) : (
          <BackgroundComponent x={xMin} y={yMin} />
        )}
        {svgPoints.length > 0 && (
          <>
            {/* Outline stroke */}
            {!asBoundingBox && (
              <PathComponent
                points={svgPath}
                fill="none"
                vectorEffect="non-scaling-stroke"
                className={cn("stroke-[5]", !asBoundingBox && "stroke-white") + " stroke-round"} // stroke-round is a custom class and doesn't play nice with tailwind-merge
              />
            )}
            {/* Main stroke */}
            <PathComponent
              points={svgPath}
              fill="none"
              fillOpacity="0.5"
              strokeDasharray={asBoundingBox ? "4 6" : undefined}
              vectorEffect="non-scaling-stroke"
              className={
                cn("stroke-[3]", strokeColor, asBoundingBox && "fill-green-300") + " stroke-round"
              }
            />
          </>
        )}
      </svg>
    );
  }
);
FlightPathPreview.displayName = "FlightPathPreview";
