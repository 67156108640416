import { forwardRef, useMemo } from "react";
import { mergeProps, useTableRowGroup } from "react-aria";

import { useDomRef } from "../../../hooks/useDomRef";
import { cn } from "../../../utils/cn";
import { filterDOMProps } from "../../../utils/filterDomProps";
import { TableCell } from "./TableCell";
import { TableCheckboxCell } from "./TableCheckboxCell";
import { TableRow } from "./TableRow";

import type { TableState } from "@react-stately/table";
import type { HTMLAttributes } from "react";
import type { SelectionMode } from "react-stately";
import type { CheckboxProps } from "../../checkbox/Checkbox";
import type { TableBodyProps } from "../TableBody";
import type { TableClassNames, TableSlots } from "../tableVariants";

interface TableBodyInternalProps<T = object> extends HTMLAttributes<HTMLTableSectionElement> {
  state: TableState<T>;
  isSelectable: boolean;
  selectionMode?: SelectionMode;
  checkboxProps?: CheckboxProps;
  slots: TableSlots;
  classNames?: TableClassNames;
}

export const TableBody = forwardRef<HTMLTableSectionElement, TableBodyInternalProps>(
  (
    {
      state,
      isSelectable,
      selectionMode,
      checkboxProps,
      className,
      slots,
      classNames,
      ...otherProps
    },
    ref
  ) => {
    const domRef = useDomRef(ref);

    const { rowGroupProps } = useTableRowGroup();

    const bodyProps: TableBodyProps<unknown> = state.collection.body.props;

    const isLoading =
      bodyProps?.isLoading ||
      bodyProps?.loadingState === "loading" ||
      bodyProps?.loadingState === "loadingMore";

    const rows = useMemo(
      () =>
        [...state.collection.body.childNodes].map(row => (
          <TableRow
            key={row.key}
            isSelectable={isSelectable}
            node={row}
            state={state}
            slots={slots}
            classNames={classNames}
          >
            {[...row.childNodes].map(cell =>
              cell.props.isSelectionCell ? (
                <TableCheckboxCell
                  key={cell.key}
                  checkboxProps={checkboxProps}
                  node={cell}
                  rowKey={row.key}
                  selectionMode={selectionMode}
                  state={state}
                  slots={slots}
                  classNames={classNames}
                />
              ) : (
                <TableCell
                  key={cell.key}
                  node={cell}
                  rowKey={row.key}
                  state={state}
                  slots={slots}
                  classNames={classNames}
                />
              )
            )}
          </TableRow>
        )),
      [checkboxProps, isSelectable, selectionMode, state, slots, classNames]
    );

    // TODO: empty content

    return (
      <tbody
        ref={domRef}
        {...mergeProps(rowGroupProps, filterDOMProps(bodyProps), otherProps)}
        className={slots.tbody({ className: cn(classNames?.tbody, className) })}
        data-empty={state.collection.size === 0}
        data-loading={isLoading}
      >
        {rows}
      </tbody>
    );
  }
);
TableBody.displayName = "Table.Body";
