import { forwardRef, useMemo } from "react";
import { mergeProps, useFocusRing, useHover, useTableRow } from "react-aria";

import { useDomRef } from "../../../hooks/useDomRef";
import { cn } from "../../../utils/cn";
import { filterDOMProps } from "../../../utils/filterDomProps";

import type { GridNode } from "@react-types/grid";
import type { PropsWithChildren } from "react";
import type { TableState } from "react-stately";
import type { TableRowProps } from "../TableRow";
import type { TableClassNames, TableSlots } from "../tableVariants";

interface TableRowInternalProps<T = object>
  extends Omit<TableRowProps, "children">,
    PropsWithChildren {
  node: GridNode<T>;
  state: TableState<T>;
  isSelectable?: boolean;
  slots: TableSlots;
  classNames?: TableClassNames;
}

export const TableRow = forwardRef<HTMLTableRowElement, TableRowInternalProps>(
  ({ node, state, isSelectable, children, className, slots, classNames, ...otherProps }, ref) => {
    const domRef = useDomRef(ref);

    const { rowProps: ariaRowProps } = useTableRow({ node }, state, domRef);
    const { isFocusVisible, focusProps } = useFocusRing();

    const isDisabled = state.disabledKeys.has(node.key);
    const isSelected = state.selectionManager.isSelected(node.key);
    const { isFirst, isLast, isMiddle, isOdd } = useMemo(() => {
      const isFirst = node.key === state.collection.getFirstKey();
      const isLast = node.key === state.collection.getLastKey();
      const isMiddle = !isFirst && !isLast;
      const isOdd = node?.index ? (node.index + 1) % 2 === 0 : false;

      return {
        isFirst,
        isLast,
        isMiddle,
        isOdd,
      };
    }, [node, state.collection]);

    const { isHovered, hoverProps } = useHover({ isDisabled });

    const rowProps: TableRowProps<unknown> = node.props;

    return (
      <tr
        ref={domRef}
        data-disabled={isDisabled}
        data-first={isFirst}
        data-focus-visible={isFocusVisible}
        data-hover={isHovered}
        data-last={isLast}
        data-middle={isMiddle}
        data-odd={isOdd}
        data-selected={isSelected}
        {...mergeProps(
          ariaRowProps,
          focusProps,
          isSelectable ? hoverProps : {},
          filterDOMProps(rowProps),
          otherProps
        )}
        className={slots.tr({ className: cn(classNames?.tr, className, rowProps?.className) })}
      >
        {children}
      </tr>
    );
  }
);
TableRow.displayName = "Table.Row";
