import { useCallback, useEffect, useMemo, useRef } from "react";

import { useSyncedRef } from "./useSyncedRef";

import type { Key } from "react-aria";
import type { FilterFn } from "../components/combobox/useMultiSelectComboBoxState";

interface Props<T> {
  currentItems: T[];
  selectedKeys: Set<Key>;
  getKey: (item: T) => Key;
}

/**
 * A hook that maintains a list of items and remembers previously loaded items if they are selected.
 * This is useful for comboboxes with a controlled list of items filtered on the server, where we
 * want to keep previously selected items selected even if they are no longer in the list of items
 * returned by the current query.
 *
 * Without this, you could search for an item, select it, then search for a different item, and the
 * combobox would be unable to display the previously selected item because it is no longer in the
 * list of search results.
 */
export const useFilteredItemsWithMemory = <T>({ currentItems, selectedKeys, getKey }: Props<T>) => {
  const getKeyRef = useSyncedRef(getKey);

  // Store a mapping of previously loaded items by key
  const previouslyLoadedItems = useRef<Record<Key, T>>({});

  // Whenever we get new items, add them to the map
  useEffect(() => {
    const newItems = currentItems.reduce(
      (acc, item) => ({
        ...acc,
        [getKeyRef.current(item)]: item,
      }),
      {} as Record<Key, T>
    );
    Object.assign(previouslyLoadedItems.current, newItems);
  }, [currentItems, getKeyRef]);

  const currentItemKeys = useMemo(
    () => new Set(currentItems.map(getKeyRef.current)),
    [currentItems, getKeyRef]
  );

  // Combine the current items with any previously selected items that are no longer in the list
  const defaultItems = useMemo(() => {
    const previouslySelectedItems = [...selectedKeys]
      .filter(key => !currentItemKeys.has(key))
      .map(key => previouslyLoadedItems.current[key]);

    return [...currentItems, ...previouslySelectedItems];
  }, [currentItemKeys, currentItems, selectedKeys]);

  // Selected items are always included in the list because of the logic above, but we hide them
  // if we are actively filtering and they aren't included in the results
  const defaultFilter: FilterFn = useCallback(
    (_, inputValue, key) => {
      // If we're not filtering, show all items
      if (!inputValue) {
        return true;
      }
      // Otherwise only show items that actually match the current filter
      return currentItemKeys.has(key);
    },
    [currentItemKeys]
  );

  return {
    defaultFilter,
    defaultItems,
  };
};
