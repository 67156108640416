import { isFunction } from "lodash";

import type { DOMRefValue, FocusableRefValue } from "@react-types/shared";
import type { RefObject } from "react";
import type { ReactRef } from "./types";

export const createDOMRef = <T extends HTMLElement = HTMLElement>(ref: RefObject<T>) =>
  ({
    UNSAFE_getDOMNode() {
      return ref.current;
    },
  }) as DOMRefValue<T>;

export const createFocusableRef = <T extends HTMLElement = HTMLElement>(
  domRef: RefObject<T>,
  focusableRef: RefObject<HTMLElement> = domRef
): FocusableRefValue<T> => ({
  ...createDOMRef(domRef),
  focus() {
    if (focusableRef.current) {
      focusableRef.current.focus();
    }
  },
});

/**
 * Assigns a value to a ref function or object
 *
 * @param ref the ref to assign to
 * @param value the value
 */
export function assignRef<T = any>(ref: ReactRef<T> | undefined, value: T) {
  if (ref == null) return;

  if (isFunction(ref)) {
    ref(value);

    return;
  }

  try {
    // @ts-expect-error: should be fixed in React 19
    ref.current = value;
  } catch (error) {
    throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
  }
}

/**
 * Combine multiple React refs into a single ref function.
 * This is used mostly when you need to allow consumers forward refs to
 * internal components
 *
 * @param refs refs to assign to value to
 */
export function mergeRefs<T>(...refs: (ReactRef<T> | undefined)[]) {
  return (node: T | null) => {
    refs.forEach(ref => assignRef(ref, node));
  };
}
