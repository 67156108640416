import { times } from "lodash";
import { forwardRef, useMemo } from "react";
import { mergeProps, useTable } from "react-aria";
import { useTableState } from "react-stately";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";
import { filterDOMProps } from "../../utils/filterDomProps";
import { Pagination } from "../pagination/Pagination";
import { Skeleton } from "../skeleton/Skeleton";
import { TableBody as TableBodyInternal } from "./internal/TableBody";
import { TableColumnHeader } from "./internal/TableColumnHeader";
import { TableHeader as TableHeaderInternal } from "./internal/TableHeader";
import { TableHeaderRow } from "./internal/TableHeaderRow";
import { TableSelectAllCell } from "./internal/TableSelectAllCell";
import { TableBody } from "./TableBody";
import { TableCell } from "./TableCell";
import { TableColumn } from "./TableColumn";
import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";
import { tableVariants } from "./tableVariants";

import type { ComponentProps, ForwardedRef, HTMLAttributes, ReactElement, ReactNode } from "react";
import type { AriaTableProps } from "react-aria";
import type { TableStateProps } from "react-stately";
import type { ReactRef } from "../../utils/types";
import type { CheckboxProps } from "../checkbox/Checkbox";
import type { TableClassNames, TableVariantProps } from "./tableVariants";

export interface TableProps<T>
  extends Omit<HTMLAttributes<HTMLTableElement>, "children">,
    TableStateProps<T>,
    AriaTableProps<T>,
    TableVariantProps {
  baseRef?: ReactRef<HTMLDivElement>;
  bottomContent?: ReactNode;
  checkboxProps?: CheckboxProps;
  classNames?: TableClassNames;
}

const TableImpl = <T extends object>(props: TableProps<T>, ref: ForwardedRef<HTMLTableElement>) => {
  const {
    className,
    classNames,
    baseRef,
    selectionMode,
    selectionBehavior,
    checkboxProps,
    bottomContent,
    align,
    fixedLayout,
    ...otherProps
  } = props;

  const domRef = useDomRef(ref);
  const domBaseRef = useDomRef(baseRef);

  const state = useTableState({
    ...props,
    showSelectionCheckboxes: selectionMode === "multiple" && selectionBehavior !== "replace",
  });

  const { gridProps } = useTable<T>(props, state, domRef);

  const isSelectable = selectionMode !== "none";
  const isMultiSelectable = selectionMode === "multiple";
  const hasBottomContent = !!bottomContent;

  const slots = useMemo(
    () => tableVariants({ align, fixedLayout, isSelectable, isMultiSelectable, hasBottomContent }),
    [align, fixedLayout, isSelectable, isMultiSelectable, hasBottomContent]
  );

  return (
    <div ref={domBaseRef} className={slots.base({ className: cn(classNames?.base, className) })}>
      <table
        {...mergeProps(gridProps, filterDOMProps(otherProps))}
        onKeyDownCapture={undefined}
        className={slots.table({ className: classNames?.table })}
        ref={domRef}
      >
        <TableHeaderInternal slots={slots} classNames={classNames}>
          {state.collection.headerRows.map(headerRow => (
            <TableHeaderRow
              key={headerRow.key}
              node={headerRow}
              state={state}
              slots={slots}
              classNames={classNames}
            >
              {[...headerRow.childNodes].map(column =>
                column.props.isSelectionCell ? (
                  <TableSelectAllCell
                    key={column.key}
                    checkboxProps={checkboxProps}
                    node={column}
                    selectionMode={selectionMode}
                    state={state}
                    slots={slots}
                    classNames={classNames}
                  />
                ) : (
                  <TableColumnHeader
                    key={column.key}
                    node={column}
                    state={state}
                    slots={slots}
                    classNames={classNames}
                  />
                )
              )}
            </TableHeaderRow>
          ))}
        </TableHeaderInternal>
        <TableBodyInternal
          checkboxProps={checkboxProps}
          isSelectable={selectionMode !== "none"}
          selectionMode={selectionMode}
          state={state}
          slots={slots}
          classNames={classNames}
        />
      </table>
      {bottomContent}
    </div>
  );
};

const WrappedTable = forwardRef(TableImpl) as <T = object>(
  props: TableProps<T> & { ref?: ForwardedRef<HTMLTableElement> }
) => ReactElement;

interface TableSkeletonProps<T> extends Omit<TableProps<T>, "children"> {
  children: ComponentProps<typeof TableHeader>["children"];
  numRows: number;
  includePagination?: boolean;
}

const TableSkeleton = forwardRef<HTMLTableElement, TableSkeletonProps<unknown>>(
  ({ children, numRows, includePagination, bottomContent, ...props }, ref) => {
    return (
      <WrappedTable
        {...props}
        ref={ref}
        bottomContent={includePagination ? <Pagination.Skeleton variant="table" /> : bottomContent}
      >
        <TableHeader>{children}</TableHeader>
        <TableBody items={times(numRows, index => ({ index }))}>
          {item => (
            <TableRow key={item.index.toString()}>
              {columnKey => (
                <TableCell key={columnKey}>
                  <Skeleton className="h-8 w-full" />
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </WrappedTable>
    );
  }
);
TableSkeleton.displayName = "Table.Skeleton";

export const Table = WrappedTable as typeof WrappedTable & {
  displayName: "Table";
  Body: typeof TableBody;
  Column: typeof TableColumn;
  Header: typeof TableHeader;
  Row: typeof TableRow;
  Cell: typeof TableCell;
  Skeleton: typeof TableSkeleton;
};
Table.displayName = "Table";
Table.Body = TableBody;
Table.Column = TableColumn;
Table.Header = TableHeader;
Table.Row = TableRow;
Table.Cell = TableCell;
Table.Skeleton = TableSkeleton;
