import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimes,
  faTimesCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useCallback, useMemo, useState } from "react";

import { cn } from "../../utils/cn";
import { Button } from "../button/Button";
import { AlertActions } from "./AlertActions";
import { AlertContent } from "./AlertContent";
import { AlertDescription } from "./AlertDescription";
import { AlertTitle } from "./AlertTitle";
import { alertVariants } from "./alertVariants";
import { AlertProvider } from "./context";

import type { ComponentPropsWithoutRef, ReactNode } from "react";
import type { ForwardRefComponentWithSubcomponents } from "../../utils/types";
import type { AlertClassNames, AlertVariantProps } from "./alertVariants";
import type { AlertContext } from "./context";

const icons = {
  info: faInfoCircle,
  warning: faExclamationCircle,
  success: faCheckCircle,
  error: faTimesCircle,
};

export interface AlertProps extends ComponentPropsWithoutRef<"div">, AlertVariantProps {
  classNames?: AlertClassNames;
  isClosable?: boolean;
  onDismiss?: () => void;
  icon?: ReactNode;
}

/**
 * Alert is used to communicate the state or status of a page, feature or action.
 */
export const Alert = forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      status = "default",
      variant,
      size,
      layout,
      className,
      classNames,
      isClosable = false,
      onDismiss,
      icon,
      children,
      ...props
    },
    ref
  ) => {
    const [isVisible, setIsVisible] = useState(true);

    const handleDismiss = useCallback(() => {
      setIsVisible(false);
      onDismiss?.();
    }, [onDismiss]);

    const slots = useMemo(
      () =>
        alertVariants({
          status,
          variant,
          size,
          layout,
          hasCloseButton: isClosable,
          hasIcon: !!icon || status !== "default",
        }),
      [status, variant, size, layout, isClosable, icon]
    );

    const context = useMemo<AlertContext>(
      () => ({ slots, size, classNames, handleDismiss }),
      [slots, size, classNames, handleDismiss]
    );

    const iconClassName = slots.icon({ className: classNames?.icon });

    return (
      <AlertProvider value={context}>
        {isVisible && (
          <div
            // TODO(sam): animate alert exit
            data-status={status}
            role="alert"
            ref={ref}
            className={slots.base({ className: cn(className, classNames?.base) })}
            {...props}
          >
            {icon ? (
              <span className={iconClassName}>{icon}</span>
            ) : (
              status !== "default" && (
                <span className={iconClassName}>
                  <FontAwesomeIcon icon={icons[status]} />
                </span>
              )
            )}
            {children}
            {isClosable && (
              <Button
                isIconOnly
                ghost
                variant="light"
                radius="full"
                size="sm"
                aria-label="Dismiss"
                onClick={handleDismiss}
                className={slots.closeButton({ className: classNames?.closeButton })}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            )}
          </div>
        )}
      </AlertProvider>
    );
  }
) as ForwardRefComponentWithSubcomponents<
  HTMLDivElement,
  {
    Title: typeof AlertTitle;
    Description: typeof AlertDescription;
    Content: typeof AlertContent;
    Actions: typeof AlertActions;
  },
  AlertProps
>;
Alert.displayName = "Alert";
Alert.Title = AlertTitle;
Alert.Description = AlertDescription;
Alert.Content = AlertContent;
Alert.Actions = AlertActions;
