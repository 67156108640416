import { forwardRef } from "react";

import { cn } from "../../utils/cn";
import { useCardContext } from "./context";

import type { ComponentPropsWithoutRef } from "react";

export const CardFooter = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    const { slots, classNames } = useCardContext();

    return (
      <div
        ref={ref}
        className={slots.footer({ className: cn(classNames?.footer, className) })}
        {...props}
      />
    );
  }
);
CardFooter.displayName = "CardFooter";
